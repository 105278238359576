import queryString from 'query-string';
import apiV2 from '.';

export const spotApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    createSpot: builder.mutation({
      query: ({ domainId, eventId, spot }) => ({
        url: `/domains/${domainId}/events/${eventId}/spots`,
        method: 'POST',
        body: { spot },
      }),
      invalidatesTags: ['SPOTS'],
    }),
    importSpots: builder.mutation({
      query: ({ domainId, eventId, spots }) => ({
        url: `/domains/${domainId}/events/${eventId}/spots/import`,
        method: 'POST',
        body: { spots },
      }),
      invalidatesTags: ['SPOTS'],
    }),
    updateSpot: builder.mutation({
      query: ({
        domainId, eventId, spotId, spot,
      }) => ({
        url: `/domains/${domainId}/events/${eventId}/spots/${spotId}`,
        method: 'PUT',
        body: { spot },
      }),
      invalidatesTags: ['SPOTS'],
    }),
    removeSpot: builder.mutation({
      query: ({ domainId, eventId, spotId }) => ({
        url: `/domains/${domainId}/events/${eventId}/spots/${spotId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SPOTS'],
    }),
    getSpots: builder.query({
      query: ({
        domainId, eventId, all, userId, category, startAt,
      }) => `/domains/${domainId}/events/${eventId}/spots?${queryString.stringify({ all, userId, category, startAt })}`,
      transformResponse: (response) => response.data,
      providesTags: ['SPOTS'],
    }),
  }),
});

export const {
  useCreateSpotMutation,
  useUpdateSpotMutation,
  useGetSpotsQuery,
  useRemoveSpotMutation,
  useImportSpotsMutation,
} = spotApi;
