import { Button, Modal } from 'antd';
import React, { useState } from 'react';

export default function ButtonConfirm({ children, title, onOk }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <>
      {React.cloneElement(children, { onClick: () => setOpen(true) })}
      <Modal title={title} open={open} footer={null} onCancel={() => setOpen(false)}>
        <div style={{ height: 16 }} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => setOpen(false)} loading={loading}>Non</Button>
          <Button
            loading={loading}
            type="primary"
            onClick={async () => {
              setLoading(true);
              await onOk();
              setLoading(false);
              setOpen(false);
            }}
          >
            Oui
          </Button>
        </div>
      </Modal>
    </>
  );
}
