import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { useMyContext } from './useMyContext';

export default function useNav() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { myContext } = useMyContext();

  const nav = useCallback((path, { newTab, e } = {}) => {
    const domainId = search.get('domain') || myContext?.domainId;
    const eventId = search.get('event') || myContext?.eventId;

    const newUrl = eventId ? `${path}?domain=${domainId}&event=${eventId}` : `${path}?domain=${domainId}`;

    if (newTab || e?.ctrlKey || e?.metaKey) {
      window.open(newUrl, '_blank');
    } else {
      navigate(newUrl);
    }
  }, [myContext, search]);

  return nav;
}
