/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useEventsGetByDomainQuery } from '../api/v2/events';
import MySelect from '../components/MySelect';
import { useMyContext } from './useMyContext';
import useRights from './useRights';

const useSelectEvent = ({ archive } = {}) => {
  const [event, setEvent] = useState();

  const { myContext } = useMyContext();
  const { hasRoleOnEvent } = useRights();

  const { data: events, isFetching } = useEventsGetByDomainQuery({ ...myContext, archive });

  function SelectEvent(props) {
    return (
      <MySelect
        options={events?.filter((f) => hasRoleOnEvent(f.id)).map((m) => ({ label: m.label, value: m.id }))}
        onChange={(id) => setEvent(events.find((f) => f.id === id))}
        {...props}
        value={events ? (props.value || event?.id) : null}
        loading={isFetching}
      />
    );
  }

  return [SelectEvent, { event, setEvent }];
};

export default useSelectEvent;
