import { Button, Modal, Select } from 'antd';
import React, { useMemo, useState } from 'react';
import InviteUsers from './InviteUsers';
import SendCampaign from './SendCampaign';
import RemoveParticipations from './RemoveParticipations';
import { useMyContext } from '../../../hooks/useMyContext';
import BulkModerations from './BulkModerations';
import ExportUsers from './Export';
import UsersImport from './Import';

const Actions = [
  { label: 'Inviter des utilisateurs à un événement', value: 1, Component: InviteUsers },
  { label: 'Envoyer une campagne', value: 2, Component: SendCampaign, hide: true },
  { label: 'Supprimer les participations', value: 3, Component: RemoveParticipations, event: true },
  { label: 'Vérifier ces utilisateurs', value: 3, Component: BulkModerations },
  { label: 'Exporter ces utilisateurs', value: 4, Component: ExportUsers, hide: true },
  // { label: 'Importer des utilisateurs', value: 5, Component: UsersImport, event: true },
];

export default function UsersActions({ total }) {
  const { event } = useMyContext();
  const [show, setShow] = useState();

  const actions = useMemo(() => Actions.filter((f) => (!event ? !f.event : true))).filter((f) => !f.hide);
  const action = useMemo(() => Actions.find((f) => f.value === show), [show]);

  return (
    <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
      <Button type="primary" onClick={() => setShow(4)}>Exporter</Button>
      <Button type="primary" onClick={() => setShow(2)}>Envoyer une campagne</Button>
      <Select
        placeholder="Actions"
        popupMatchSelectWidth={false}
        style={{ width: 150 }}
        onChange={setShow}
        value={show}
        options={actions}
      />
      <Modal
        title={action?.label}
        open={show}
        onCancel={() => setShow()}
        footer={null}
        width={800}
      >
        {action && show && <action.Component close={() => setShow()} total={total} />}
      </Modal>
    </div>
  );
}
