import api from '.';

export const formApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getForms: builder.query({
      query: ({ domainId, eventId }) => ({
        url: eventId
          ? `/domains/${domainId}/events/${eventId}/forms`
          : `/domains/${domainId}/forms`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
      providesTags: ['FORM'],
    }),
  }),
});

export const { useGetFormsQuery } = formApi;
