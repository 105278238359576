/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Button, Input, Modal, Space } from 'antd';
import RswEditor from 'react-simple-wysiwyg';

export default function Wysiwyg({ value, onChange }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button type="primary" onClick={() => setShow(true)}>Ouvrir</Button>

      <Modal
        title="HTML"
        open={show}
        footer={<Button type="primary" onClick={() => setShow(false)}>Fermer</Button>}
        onCancel={() => setShow(false)}
      >
        <RswEditor
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </Modal>
    </>
  );
}
