import React from 'react';
import { Chart } from 'react-chartjs-2';
import { differenceInDays } from 'date-fns';
import { useStatsGetInscriptionsQuery } from '../../api/v2/stats';
import { useMyContext } from '../../hooks/useMyContext';

export default function Inscriptions({ eventB, isExhibitor, typo }) {
  const { myContext, event } = useMyContext();

  const { data } = useStatsGetInscriptionsQuery({
    ...myContext, eventIdB: eventB?.id, isExhibitor, typo,
  });

  const numberOfDays = differenceInDays(new Date(), event.start) - 1;

  const options = {
    scales: {
      x: {
        ticks: {
          font: (c) => {
            if (c.tick.label === `J${numberOfDays}`) {
              return {
                weight: '900',
              };
            }

            return null;
          },
        },
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',

      },
      title: {
        display: true,
        text: 'Inscriptions',
      },
    },
  };

  if (!data) {
    return null;
  }

  const { a, b, labels } = data;

  const datasets = [];
  if (a && event) {
    datasets.push(...[{
      type: 'line',
      label: event.name,
      data: a.total,
      backgroundColor: '#e67e22',
      borderColor: '#d35400',
    }, {
      type: 'bar',
      label: event.name,
      data: a.totalSum,
      backgroundColor: '#f1c40f',
      borderColor: '#f39c12',
    }]);
  }

  if (b && eventB) {
    datasets.push(...[{
      type: 'line',
      label: eventB.name,
      data: b.total,
      backgroundColor: '#3498db',
      borderColor: '#2980b9',
    }, {
      type: 'bar',
      label: eventB.name,
      data: b.totalSum,
      backgroundColor: '#1abc9c',
      borderColor: '#16a085',
    }]);
  }

  return (
    <div style={{ height: 350 }}>
      <Chart
        options={options}
        data={{ datasets, labels }}
      />
    </div>
  );
}
