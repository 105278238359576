import React from 'react';
import { Table } from 'antd';
import { useStatsCommonQuery } from '../../api/v2/stats';
import { useMyContext } from '../../hooks/useMyContext';

export default function StatsTypos() {
  const { myContext } = useMyContext();

  const { data } = useStatsCommonQuery(myContext);

  return (
    <div>
      <Table
        dataSource={data?.typos}
        pagination={false}
        columns={[
          {
            title: 'Typologie',
            dataIndex: 'label',
          },
          {
            title: 'Présents',
            dataIndex: 'total',
          },
          {
            title: 'IN',
            dataIndex: 'isIn',
          },
          {
            title: 'OUT',
            dataIndex: 'isOut',
          },
        ]}
      />
    </div>
  );
}
