import { Menu } from 'antd';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useNav from '../../hooks/useNav';
import useRights from '../../hooks/useRights';
import { routes } from '../../routes';
import ModalDomain from './SelectDomain';
import ModalEvent from './SelectEvent';
import useEnv from '../../hooks/useEnv';
import { useAuth } from '../../hooks/useAuth';

export default function GMenu({ close }) {
  const nav = useNav();
  const location = useLocation();
  const { hasRight } = useRights();
  const env = useEnv();
  const { auth } = useAuth();

  const go = useCallback((path) => {
    nav(path);
    if (close) {
      close();
    }
  }, [close, nav]);

  const handleRedirect = () => {
    const currentPath = window.location.pathname;
    window.location.href = `https://app.gayakoa.com${currentPath}`;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: 12,
        overflow: 'auto',
      }}
    >
      {env === 'dev' ? (
        <div
          style={{
            backgroundColor: 'red',
            width: '100%',
            textAlign: 'center',
            color: 'white',
            fontSize: 14,
            padding: '8px 4px',
            cursor: 'pointer',
          }}
          onClick={handleRedirect}
          aria-hidden
        >
          Passez sur la version de Production
        </div>
      ) : <div style={{ height: 8 }} />}

      <ModalDomain />
      <ModalEvent />

      <Menu
        style={{ width: '100%', overflow: 'auto', flexGrow: 1 }}
        selectedKeys={[location.pathname]}
        defaultOpenKeys={[`/${location.pathname.split('/')[1]}`]}
        theme="dark"
        mode="inline"
        items={[
          ...routes
            .map((m) => (m.children ? ({
              ...m,
              children: m.children.filter((f) => hasRight(f.value)).filter((f) => (f.forAdmin && auth ? auth.isAdmin : true)),
            }) : m))
            .filter((f) => (f.children ? f.children.length > 0 : true))
            .filter((f) => (f.value ? hasRight(f.value) : true))
            .filter((f) => (f.forAdmin && auth ? auth.isAdmin : true))
            .map((m) => ({
              label: m.label,
              key: m.path,
              icon: m.icon,
              onClick: () => (!m.children ? go(m.path) : null),
              children: m.children?.map((c) => ({
                label: c.label,
                key: c.path,
                onClick: () => go(c.path),
              })),
            })),
        ]}
      />
    </div>
  );
}
