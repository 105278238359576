import queryString from 'query-string';
import apiV2 from '.';

export const logsApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    getLogs: builder.query({
      query: ({ domainId, eventId, userId, current, pageSize }) => ({
        url: `/domains/${domainId}/events/${eventId}/logs?${queryString.stringify({ userId, current, pageSize })}`,
      }),
      transformResponse: (response) => response.data,
      providesTags: ['LOGS'],
    }),
  }),
});

export const {
  useGetLogsQuery,
} = logsApi;
