import { Button, Table } from 'antd';
import React from 'react';
import { useGetAccountsQuery, useRemoveAccountMutation } from '../../../api/v2/emails';
import { useMyContext } from '../../../hooks/useMyContext';
import AccountsEdition from './Edition';

export default function EmailAccountsPage() {
  const { myContext } = useMyContext();

  const { data } = useGetAccountsQuery(myContext);
  const [removeAccount] = useRemoveAccountMutation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <AccountsEdition>
        <Button type="primary">
          Ajouter un compte Mailjet
        </Button>
      </AccountsEdition>
      <Table
        rowKey="id"
        dataSource={data}
        scroll={{ x: true }}
        columns={[
          { title: 'Nom', dataIndex: 'name' },
          {
            render: (account) => (
              <div style={{ display: 'flex', gap: 8 }}>
                <AccountsEdition account={account}>
                  <Button type="primary" disabled={!account.domainId}>
                    Modifier
                  </Button>
                </AccountsEdition>
                <Button
                  onClick={() => removeAccount({ ...myContext, accountId: account.id })}
                  type="primary"
                  disabled={!account.domainId}
                  danger
                  ghost
                >
                  Supprimer
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
