import {
  Alert,
  Button,
  Form, Input,
  InputNumber,
  Modal,
  Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useCreateArticleCodeMutation, useUpdateArticleCodeMutation } from '../../../api/v2/articleCodes';
import { useMyContext } from '../../../hooks/useMyContext';
import { useGetArticlesQuery } from '../../../api/v2/articles';

export default function ArticleCodeEdition({ values }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { myContext } = useMyContext();
  const [form] = Form.useForm();

  const [create] = useCreateArticleCodeMutation();
  const [update] = useUpdateArticleCodeMutation();
  const { data: articles, isFetching: isFetchingArticles } = useGetArticlesQuery({ ...myContext });

  const isCreation = !values?.id;

  useEffect(() => {
    if (!open) {
      form.resetFields();
    } else {
      form.setFieldsValue(values || {});
    }
  }, [open]);

  return (
    <>
      <Button type="primary" onClick={() => setOpen(true)}>
        {isCreation ? 'Créer un code' : 'Modifier'}
      </Button>
      <Modal title={isCreation ? 'Créer un code' : 'Modifier un code'} open={open} onCancel={() => setOpen(false)} footer={null}>
        <Form
          form={form}
          onFinish={async (body) => {
            setLoading(true);
            setError();
            try {
              if (isCreation) {
                await create({ ...myContext, body }).unwrap();
              } else {
                await update({ ...myContext, body, id: values.id }).unwrap();
              }
              setOpen(false);
            } catch (err) {
              setError(err);
            } finally {
              setLoading(false);
            }
          }}
        >
          <Form.Item label="Code" name="code" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Choisir un article" name="articleId" rules={[{ required: true }]}>
            <Select
              loading={isFetchingArticles}
              options={articles?.map((m) => ({ label: `${m.label} - ${m.priceTTC} €`, value: m.id }))}
              popupMatchSelectWidth={false}
              filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
              showSearch
            />
          </Form.Item>
          <Form.Item label="Remise (en %)" name="remise" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item label="Nombre d'utilisations" name="totalAvailable" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
          {error && (
            <Form.Item>
              <Alert message={error} type="error" showIcon />
            </Form.Item>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => setOpen(false)}>
              Fermer
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Enregistrer
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
