import React from 'react';
import UsersTable from './Table';
import { UsersProvider } from './useUsers';
import useMe from '../../hooks/useMe';

export default function Users() {
  const me = useMe();

  if (!me.id) {
    return null;
  }

  return (
    <UsersProvider>
      <UsersTable />
    </UsersProvider>
  );
}
