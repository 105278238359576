import apiV2 from '.';

export const authenticationsApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    authMe: builder.query({
      query: () => '/auth/me',
      transformResponse: (response) => response.data,
    }),
    createToken: builder.query({
      query: ({ domainId, userId }) => `/domains/${domainId}/users/${userId}/token`,
      transformResponse: (response) => response.data.token,
    }),
  }),
});

export const {
  useLazyAuthMeQuery,
  useCreateTokenQuery,
} = authenticationsApi;
