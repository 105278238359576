import apiV2 from '.';

export const languagesApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    setTranslation: builder.mutation({
      query: ({ body }) => ({
        url: '/languages/translations',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['LANGUAGES'],
    }),
    getLanguages: builder.query({
      query: () => '/languages',
      transformResponse: (response) => response.data.languages,
      providesTags: ['LANGUAGES'],
    }),
  }),
});

export const {
  useGetLanguagesQuery,
  useSetTranslationMutation,
} = languagesApi;
