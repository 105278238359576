import { Form, Select } from 'antd';
import React from 'react';
import { useGetLanguagesQuery } from '../../../api/v2/languages';

export default function MeetingLanguages() {
  const { data: languages } = useGetLanguagesQuery();

  return (
    <>
      {/* <Form.Item label="Langue par défaut" name="FO_MEETING_LANGUAGE_DEFAULT" initialValue="FR">
        <Select options={languages?.map((m) => ({ label: m.label, value: m.key }))} />
      </Form.Item> */}
      <Form.Item label="Langues disponibles" name="FO_MEETING_LANGUAGES_SHOW" initialValue={['FR']}>
        <Select
          mode="multiple"
          options={languages?.map((m) => ({ label: m.label, value: m.key, disabled: m.key === 'FR' }))}
        />
      </Form.Item>
    </>
  );
}
