/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { Alert, Button } from 'antd';
import React, { useRef, useState } from 'react';
import { read, utils } from 'xlsx';
import CreateTemplate from './CreateTemplate';
import { useMyContext } from '../../../hooks/useMyContext';
import { useImportCompaniesMutation } from '../../../api/v2/import';

const transformedObject = (inputObject) => {
  const object = { extended: {} };

  for (const [key, value] of Object.entries(inputObject)) {
    const match = key.match(/\((.*?)\.(.*?)\)|\((.*?)\)/);

    if (match) {
      const [, extended, child, single] = match;

      if (extended !== undefined) {
        object[extended] = object[extended] || {};
        const childArray = child.split('.');
        if (childArray.length > 1) {
          object[extended][childArray[1]] = value
            .split(',')
            .reduce((prev, curr) => ({ ...prev, [curr]: true }), {});
        } else {
          object[extended][child] = value;
        }
      } else if (single !== undefined) {
        object[single] = value;
      }
    }
  }

  return object;
};

export default function ImportCompanies() {
  const [companies, setCompanies] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const { myContext } = useMyContext();
  const ref = useRef();

  const [importCompanies, { isLoading }] = useImportCompaniesMutation();

  const handleFileUpload = (event) => {
    setIsSuccess(false);
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      let excelData = utils.sheet_to_json(workbook.Sheets[sheetName], { defval: '' });
      excelData = excelData.map((m) => transformedObject(m));

      setCompanies(excelData);
    };

    reader.readAsArrayBuffer(file);
  };

  const reset = () => {
    setCompanies([]);
  };

  return (
    <div style={{ display: 'flex', gap: 16, flexDirection: 'column' }}>
      <CreateTemplate />

      {companies.length === 0 && (
        <div>
          <input
            ref={ref}
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            value={undefined}
            hidden
          />
          <Button type="primary" onClick={() => ref.current?.click()}>
            Choisir un fichier excel de sociétés
          </Button>
        </div>
      )}

      {companies.length > 0 && (
        <Alert
          description={`Voulez-vous importer ${companies.length} sociétés ?`}
          type="info"
          action={(
            <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
              <Button
                type="primary"
                onClick={async () => {
                  // const c = []
                  // for (let i = 0; i < 3000; i++) {
                  //   c.push({ name: `Company 3 ${i}`, siren: i + 3000, extended: {} })
                  // }
                  try {
                    await importCompanies({ ...myContext, companies }).unwrap();
                    setIsSuccess(true);
                    reset();
                  } catch (err) {
                    console.log(err);
                  }
                }}
                loading={isLoading}
              >
                Oui
              </Button>
              <Button onClick={() => reset()} loading={isLoading} danger ghost>
                Non
              </Button>
            </div>
          )}
        />
      )}

      {isSuccess && <Alert showIcon message="Import terminé" type="success" />}

      {companies && window.location.hostname === 'localhost' && (
        <div>
          <h2>Contenu du fichier Excel</h2>
          <pre>{JSON.stringify(companies, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}
