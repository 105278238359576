import { Button, Table } from 'antd';
import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useGetModelsQuery } from '../../../api/v2/models';
import { useMyContext } from '../../../hooks/useMyContext';
import ModelsEdition from './Edition';

export default function ModelsPage() {
  const { myContext } = useMyContext();

  const { data: models } = useGetModelsQuery(myContext);

  const expandedRowRender = (parent) => {
    const columns = [
      { title: 'Label', dataIndex: 'value' },
      { title: 'Key', dataIndex: 'key' },
      { title: 'Quota', dataIndex: 'quota' },
      { title: 'Ordre', dataIndex: 'order' },
      { title: 'Nombre de participants', dataIndex: 'totalParticipation' },
      {
        render: (value) => (
          <div>
            <ModelsEdition model={value}>
              <Button type="primary">
                Modifier
              </Button>
            </ModelsEdition>
          </div>
        ),
      },
    ];

    return <Table rowKey="id" size="small" columns={columns} dataSource={parent.values} pagination={false} />;
  };

  return (
    <div>
      <Table
        rowKey="id"
        expandable={{
          // showExpandColumn: false,
          expandedRowRender,
          rowExpandable: (parent) => parent.values,
          // expandedRowKeys: models?.map((m) => m.id),
        }}
        scroll={{ x: true }}
        dataSource={models}
        columns={[
          { title: 'Label', dataIndex: 'value' },
          { title: 'Short label', dataIndex: 'labelShort' },
          { title: 'Key', dataIndex: 'key' },
          {
            title: 'Cible',
            render: (v) => {
              if (v.model === 'U') {
                return 'Utilisateur';
              }
              if (v.model === 'C') {
                return 'Société';
              }
              if (v.model === 'UE') {
                return 'Donnée événementielle';
              }
              return '';
            },
          },
          {
            title: 'Quota',
            dataIndex: 'hasQuota',
            render: (v) => (v ? (
              <CheckCircleOutlined style={{ color: 'green' }} />
            ) : (
              <CloseCircleOutlined style={{ color: 'red' }} />
            )),
          },
        ]}
      />
    </div>
  );
}
