import { Button, Form, Select } from 'antd';
import React, { useState } from 'react';
import { utils, writeFile } from 'xlsx';
import { useGetFormsQuery } from '../../../api/v1/form.api';
import { useMyContext } from '../../../hooks/useMyContext';

export default function CreateTemplateUsers() {
  const [form, setForm] = useState();

  const { myContext } = useMyContext();

  const { data: forms } = useGetFormsQuery(myContext);

  const handleDownload = () => {
    const models = form.pages
      .map((m) => m.models)
      .flat()
      .filter(
        (f) => f.entity === 'user'
          || f.entity === 'userEvent'
          || f.field === 'password'
          || f.field === 'email',
      )
      .map((m) => {
        if (m.entity === 'user') {
          if (m.field === 'extended') {
            if (m.values) {
              return `${m.label} (user.extended.values.${m.fieldExtended})`;
            }
            return `${m.label} (user.extended.${m.fieldExtended})`;
          }
          return `${m.label} (user.${m.field})`;
        }

        if (m.entity === 'userEvent') {
          if (m.field === 'extended') {
            if (m.values) {
              return `${m.label} (userEvent.extended.values.${m.fieldExtended})`;
            }
            return `${m.label} (userEvent.extended.${m.fieldExtended})`;
          }
          return `${m.label} (userEvent.${m.field})`;
        }

        if (m.field === 'password' || m.field === 'email') {
          return `${m.label} (auth.${m.field})`;
        }

        return `${m.label} (${m.field})`;
      });

    const ws = utils.aoa_to_sheet([models]);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet1');

    writeFile(wb, 'import.xlsx');
  };

  return (
    <Form layout="vertical" style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
      <Form.Item noStyle>
        <Select
          placeholder="Choisir un formulaire"
          onChange={(id) => setForm(forms.find((f) => f.id === id))}
          value={form?.id}
          style={{ width: 200 }}
        >
          {forms?.map((f) => (
            <Select.Option key={`forms-${f.id}`} value={f.id}>
              {f.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Button type="primary" onClick={handleDownload} disabled={!form}>
        Télécharger un template de fichier Excel
      </Button>
    </Form>
  );
}
