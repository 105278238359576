import { Alert, Button } from 'antd';
import React, { useRef, useState } from 'react';
import { read, utils } from 'xlsx';
import { useMyContext } from '../../hooks/useMyContext';
import { useImportSpotsMutation } from '../../api/v2/spots';

export default function ImportSpots() {
  const [spots, setSpots] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const { myContext } = useMyContext();
  const ref = useRef();

  const [importSpots, { isLoading }] = useImportSpotsMutation();

  const handleFileUpload = (event) => {
    setIsSuccess(false);
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const excelData = utils.sheet_to_json(workbook.Sheets[sheetName], { defval: '' })
        .map((m) => ({
          userId: m.userId || null,
          forAll: m.forAll,
          label: m.label,
          extendedParent: m.extendedParent,
          extendedChild: m.extendedChild,
          startAt: m.startAt.split('!')[1],
        }));
      setSpots(excelData);
    };

    reader.readAsArrayBuffer(file);
  };

  const reset = () => {
    setSpots([]);
  };

  return (
    <div style={{ display: 'flex', gap: 16, flexDirection: 'column' }}>
      {spots.length === 0 && (
        <div>
          <input
            ref={ref}
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            value={undefined}
            hidden
          />
          <Button type="primary" onClick={() => ref.current?.click()}>
            Choisir un fichier excel de spots
          </Button>
        </div>
      )}

      {spots.length > 0 && (
        <Alert
          description={`Voulez-vous importer ${spots.length} spots ?`}
          type="info"
          action={(
            <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
              <Button
                type="primary"
                onClick={async () => {
                  try {
                    await importSpots({ ...myContext, spots }).unwrap();
                    setIsSuccess(true);
                    reset();
                  } catch (err) {
                    console.log(err);
                  }
                }}
                loading={isLoading}
              >
                Oui
              </Button>
              <Button onClick={() => reset()} loading={isLoading} danger ghost>
                Non
              </Button>
            </div>
          )}
        />
      )}

      {isSuccess && <Alert showIcon message="Import terminé" type="success" />}

      {spots && window.location.hostname === 'localhost' && (
        <div>
          <h2>Contenu du fichier Excel</h2>
          <pre>{JSON.stringify(spots, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}
