/* eslint-disable react/destructuring-assignment */
import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useCreateModelVirtualMutation, useGetModelsQuery, useUpdateModelVirtualMutation } from '../../../api/v2/models';
import { useMyContext } from '../../../hooks/useMyContext';

const items = [
  { model: 'modelA', required: true },
  { model: 'modelB', required: true },
  { model: 'modelC', required: false },
  // { model: 'modelD', required: false },
  { model: 'result', required: true, result: true },
];

export default function ModelVirtualEdition({ children, modelVirtual }) {
  const [visible, setVisible] = useState(false);

  const { myContext } = useMyContext();
  const [form] = Form.useForm();

  const [updateModelVirtual, { isLoading: isLoading1 }] = useUpdateModelVirtualMutation();
  const [createModelVirtual, { isLoading: isLoading2 }] = useCreateModelVirtualMutation();
  const { data: models } = useGetModelsQuery({ ...myContext, onlyDomain: true });

  const isLoading = useMemo(() => isLoading1 || isLoading2);

  const label = useMemo(() => (modelVirtual ? 'Modifier' : 'Créer'), [modelVirtual]);

  useEffect(() => {
    if (modelVirtual && form) {
      form.setFieldsValue(modelVirtual);
    }
  }, [form, modelVirtual]);

  return (
    <>
      {React.cloneElement(children, { onClick: () => setVisible(true) })}
      <Modal
        title={`${label} une donnée virtuelle`}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          initialValues={{ isAnd: 1 }}
          onFinish={async (values) => {
            if (modelVirtual?.id) {
              await updateModelVirtual({ ...myContext, modelVirtual: values, modelVirtualId: modelVirtual.id }).unwrap();
            } else {
              await createModelVirtual({ ...myContext, modelVirtual: values }).unwrap();
            }
            setVisible(false);
            form.resetFields();
          }}
        >
          <Form.Item label="Label" name="label" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          {items.map(({ model, required, result }, i) => (
            <>
              <div style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 8 }}>
                {`${result ? 'Donnée virtuelle' : `Donnée ${i + 1}`}`}
              </div>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const id = Number(getFieldValue(`${model}Id`));
                  const child = models?.reduce((prev, curr) => [...prev, curr, ...(curr.values || [])], []).find((f) => f.id === id);
                  if (child?.parentKey) {
                    const parent = models?.reduce((prev, curr) => [...prev, curr, ...(curr.values || [])], [])
                      .find((f) => f.key === child.parentKey);
                    if (parent) {
                      return (
                        <Form.Item label="Parent">
                          {parent?.value}
                        </Form.Item>
                      );
                    }
                  }
                  return null;
                }}
              </Form.Item>
              <Form.Item label="Model" name={`${model}Id`} rules={[{ required }]}>
                <Select
                  options={models
                    ?.map((m) => {
                      if (!m.values) {
                        return { ...m, values: [m] };
                      }
                      return m;
                    })
                    .map((m) => ({
                      label: m.value,
                      options: m.values?.map((mm) => ({ label: mm.value, value: mm.id })),
                    }))}
                  onChange={(modelId) => {
                    const m = models?.reduce((prev, curr) => [...prev, curr, ...(curr.values || [])], []).find((f) => f.id === modelId);
                    if (m?.parentKey) {
                      form.setFieldValue(`${model}Value`, 'TRUE');
                    } else {
                      form.setFieldValue(`${model}Value`, '');
                    }
                  }}
                  allowClear
                />
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    style={{ display: getFieldValue(`${model}Value`) === 'TRUE' ? 'none' : 'block' }}
                    label="Value"
                    name={`${model}Value`}
                    rules={[{ required }]}
                  >
                    <Input />
                  </Form.Item>
                )}
              </Form.Item>
              {i < items.length - 2 && (
                <Form.Item label="Opérateur" name="isAnd" rules={[{ required }]}>
                  <Select options={[{ label: 'AND', value: 1 }, { label: 'OR', value: 0 }]} />
                </Form.Item>
              )}
            </>
          ))}
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: 8 }}>
            <Button onClick={() => setVisible(false)}>
              Annuler
            </Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {label}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
