import apiV2 from '.';

export const typoApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    createTypo: builder.mutation({
      query: ({ domainId, eventId, typo }) => ({
        url: `/domains/${domainId}/events/${eventId}/typos`,
        method: 'POST',
        body: { typo },
      }),
      invalidatesTags: ['TYPO'],
    }),
    copyTypo: builder.mutation({
      query: ({ domainId, eventId, typoId }) => ({
        url: `/domains/${domainId}/events/${eventId}/typos/${typoId}/copy`,
        method: 'POST',
      }),
      invalidatesTags: ['TYPO'],
    }),
    updateTypo: builder.mutation({
      query: ({
        domainId, eventId, typoId, typo,
      }) => ({
        url: `/domains/${domainId}/events/${eventId}/typos/${typoId}`,
        method: 'PUT',
        body: { typo },
      }),
      invalidatesTags: ['TYPO'],
    }),
    removeTypo: builder.mutation({
      query: ({ domainId, eventId, typoId }) => ({
        url: `/domains/${domainId}/events/${eventId}/typos/${typoId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TYPO'],
    }),
    getTypos: builder.query({
      query: ({ domainId, eventId, byEvent }) => `/domains/${domainId}/events/${eventId}/typos?${byEvent ? 'byEvent=true' : ''}`,
      transformResponse: (response) => response.data?.typos || [],
      providesTags: ['TYPO'],
    }),
  }),
});

export const {
  useCreateTypoMutation,
  useUpdateTypoMutation,
  useGetTyposQuery,
  useRemoveTypoMutation,
  useCopyTypoMutation,
} = typoApi;
