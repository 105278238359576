import queryString from 'query-string';
import apiV2 from '.';

export const foApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    updateFoSettings: builder.mutation({
      query: ({ domainId, eventId, extended, language, languageId }) => ({
        url: `/domains/${domainId}/events/${eventId}/fo`,
        method: 'PUT',
        body: { extended, language, languageId },
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['FO'],
    }),
    getFoSettings: builder.query({
      query: ({ domainId, eventId, language, languageId }) => ({
        url: `/domains/${domainId}/events/${eventId}/fo?${queryString.stringify({ language, languageId })}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data?.settings?.extended,
      providesTags: ['FO'],
    }),
  }),
});

export const {
  useUpdateFoSettingsMutation, useGetFoSettingsQuery,
} = foApi;
