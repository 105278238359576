import { Button, Modal } from 'antd';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetParticipationQuery, useUpdateParticipationMutation } from '../../../api/v2/participations';
import { useGetTyposQuery } from '../../../api/v2/typo';
import { useMyContext } from '../../../hooks/useMyContext';

export default function UserEventTypologie() {
  const [open, setOpen] = useState(false);

  const { myContext } = useMyContext();
  const { userId } = useParams();

  const { data: participation } = useGetParticipationQuery({ ...myContext, userId });
  const [update, { isLoading }] = useUpdateParticipationMutation();
  const { data: typos } = useGetTyposQuery(myContext);

  const currTypo = useMemo(() => typos?.find((f) => f.id === participation.typoId), [participation, typos]);

  return (
    <>
      <Modal open={open} title="Choisir une typologie" footer={null} onCancel={() => setOpen(false)}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          {typos?.map((typo) => (
            <Button
              type={typo.id === currTypo?.id ? 'primary' : 'default'}
              onClick={async () => {
                await update({ ...myContext, userId, participation: { typoId: typo.id } }).unwrap();
                setOpen(false);
              }}
              loading={isLoading}
            >
              {typo.label}
            </Button>
          ))}
        </div>
      </Modal>
      <Button style={{ flexGrow: 1 }} type="primary" onClick={() => setOpen(true)}>{currTypo?.label}</Button>
    </>
  );
}
