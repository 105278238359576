import { Button, Form, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSendEmailMutation } from '../../../../api/v2/emails';
import { useGetUserQuery } from '../../../../api/v2/users';
import { useMyContext } from '../../../../hooks/useMyContext';
import useSettingsFo from '../../../../hooks/useSettingsFO';

export default function InvoicesSendEmail() {
  const [show, setShow] = useState(false);

  const { myContext } = useMyContext();
  const { userId } = useParams();
  const [form] = Form.useForm();

  const settings = useSettingsFo();

  const { data: user } = useGetUserQuery({ ...myContext, userId });
  const [send, { isLoading }] = useSendEmailMutation();

  useEffect(() => {
    if (user?.language) {
      form.setFieldValue('t', user.language);
    }
  }, [user]);

  return (
    <div>
      <Button type="primary" onClick={() => setShow(true)}>Envoyer les documents</Button>
      <Modal title="Envoyer les documents" open={show} onCancel={() => setShow(false)} width={600} footer={null}>
        <Form
          form={form}
          onFinish={async (v) => {
            const { t } = v;
            await send({ ...myContext, userId, templateId: settings?.[`CONF_EXPO_EMAIL_TEMPLATE_ID_${t}`] }).unwrap();
            setShow(false);
          }}
        >
          <Form.Item label="Langue utilisée dans l'email" name="t" rules={[{ required: true }]}>
            <Select options={[{ label: 'English', value: 'EN' }, { label: 'Français', value: 'FR' }]} />
          </Form.Item>

          <Button
            style={{ width: '100%' }}
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Envoyer
          </Button>
        </Form>
      </Modal>
    </div>
  );
}
