import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useCreateAccountMutation, useUpdateAccountMutation } from '../../../api/v2/emails';
import { useMyContext } from '../../../hooks/useMyContext';

export default function AccountsEdition({ children, account }) {
  const [visible, setVisible] = useState(false);

  const { myContext } = useMyContext();
  const [form] = Form.useForm();

  const [createAccount] = useCreateAccountMutation();
  const [updateAccount] = useUpdateAccountMutation();

  const label = useMemo(() => (account ? 'Modifier' : 'Ajouter'), [account]);

  useEffect(() => {
    if (account) {
      form.setFieldsValue(account);
    }
  }, [form, account]);

  return (
    <>
      { React.cloneElement(children, { onClick: () => setVisible(true) })}
      <Modal
        title={`${label} un compte Mailjet`}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          onFinish={async (values) => {
            if (account?.id) {
              await updateAccount({ ...myContext, account: values, accountId: account.id }).unwrap();
            } else {
              await createAccount({ ...myContext, account: values }).unwrap();
            }
            setVisible(false);
            form.resetFields();
          }}
        >
          <Form.Item label="Label" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Clé publique" name="publicKey" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Clé privée" name="privateKey" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: 8 }}>
            <Button onClick={() => setVisible(false)}>
              Annuler
            </Button>
            <Button type="primary" htmlType="submit">
              {label}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
