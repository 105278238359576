import { Button, Table } from 'antd';
import React from 'react';
import { useGetModelVirtualsQuery, useMigrateModelVirtualsMutation, useRemoveModelVirtualMutation } from '../../../api/v2/models';
import { useMyContext } from '../../../hooks/useMyContext';
import ModelVirtualEdition from './Edition';

export default function ModelVirtualsPage() {
  const { myContext } = useMyContext();

  const { data: modelVirtuals } = useGetModelVirtualsQuery(myContext);
  const [removeModelVirtual] = useRemoveModelVirtualMutation();
  const [migrate] = useMigrateModelVirtualsMutation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ModelVirtualEdition>
          <Button type="primary">
            Créer une donnée virtuelle
          </Button>
        </ModelVirtualEdition>
        <Button type="primary" onClick={() => migrate(myContext)}>Migrer</Button>
      </div>
      <Table
        rowKey="id"
        dataSource={modelVirtuals}
        scroll={{ x: true }}
        columns={[
          { title: 'Label', dataIndex: 'label' },
          {
            render: (modelVirtual) => (
              <div style={{ display: 'flex', gap: 8 }}>
                <ModelVirtualEdition modelVirtual={modelVirtual}>
                  <Button type="primary">
                    Modifier
                  </Button>
                </ModelVirtualEdition>
                <Button
                  onClick={() => removeModelVirtual({ ...myContext, modelVirtualId: modelVirtual.id })}
                  type="primary"
                  danger
                  ghost
                >
                  Supprimer
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
