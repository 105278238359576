import React, { useEffect, useRef, useState } from 'react';
import { useLayout } from '../../hooks/useLayout';
import GMenu from './GMenu';
import useSettingsBo from '../../hooks/useSettingsBO';

export default function SidebarMobile() {
  const { visible, hide, sidebarWidth, navbarHeight, isMobile } = useLayout();
  const settings = useSettingsBo();

  useEffect(() => {
    if (!isMobile) {
      hide();
    }
  }, [isMobile, hide]);

  return (
    <div
      style={{
        position: 'fixed',
        top: navbarHeight,
        right: visible ? 0 : -400,
        transition: 'all 0.3s',
        bottom: 0,
        left: 0,
        display: 'flex',
        visibility: visible ? 'visible' : 'hidden',
        zIndex: 100,
      }}
    >
      <div
        onClick={() => hide()}
        style={{ flexGrow: 1 }}
        aria-hidden
      />
      <div
        style={{
          backgroundColor: settings?.SIDEBAR_BACKGROUND_COLOR_V2 || '#001529',
          maxWidth: sidebarWidth,
          overflow: 'auto',
          flexGrow: 1,
        }}
      >
        <GMenu close={hide} />
      </div>
    </div>
  );
}
