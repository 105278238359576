import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { compareDesc } from 'date-fns';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  useAcceptInvoiceMutation,
  useGetInvoicesQuery,
  useRemoveInvoiceMutation,
} from '../../../../api/v2/invoices';
import ButtonConfirm from '../../../../components/ButtonConfirm';
import { useMyContext } from '../../../../hooks/useMyContext';
import UserInvoicesCreate from './Create';
import UserInvoicesCreateCredit from './CreateCredit';
import ShowInvoice from './ShowInvoice';

export default function UserInvoicesTable({ isCredit, onTabs }) {
  const { myContext } = useMyContext();
  const { userId } = useParams();

  const { data: invoices } = useGetInvoicesQuery({ ...myContext, userId });
  const [accept, { isLoading: isAccepting }] = useAcceptInvoiceMutation();
  const [remove, { isLoading: isRemoving }] = useRemoveInvoiceMutation();

  return (
    <Table
      rowKey="id"
      scroll={{ x: true }}
      rowHoverable={false}
      pagination={false}
      onRow={(record) => ({
        style: {
          backgroundColor: record.isDraft ? 'rgba(176, 0, 0, 0.2)' : 'rgba(18, 177, 0, 0.2)',
        },
      })}
      dataSource={invoices?.map((m) => m)
        .filter((f) => (isCredit ? f.isCredit : !f.isCredit))
        .sort((a, b) => compareDesc(a.createdAt, b.createdAt))}
      columns={[
        { title: 'Référence', dataIndex: 'label' },
        { title: 'Date', dataIndex: ['bill', 'date'] },
        { title: 'Total HT', dataIndex: 'price', render: (p) => `${p} €` },
        { title: 'Total TTC', dataIndex: 'priceTva', render: (p) => `${p} €` },
        {
          render: (m) => (
            <div style={{ display: 'flex', gap: 8 }}>
              <ShowInvoice invoice={m}>
                <Button type="primary" icon={<EyeOutlined />} />
              </ShowInvoice>
              {m.isDraft === 1 && (
                <>
                  {m.isCredit ? <UserInvoicesCreateCredit label="Reprendre" values={m} /> : <UserInvoicesCreate values={m} />}
                  <ButtonConfirm
                    title={m.isCredit ? 'Voulez-vous valider cet avoir ?' : 'Voulez-vous valider cette facture ?'}
                    onOk={() => accept({ ...myContext, userId, invoiceId: m.id })}
                  >
                    <Button type="primary" loading={isAccepting} ghost>
                      Valider
                    </Button>
                  </ButtonConfirm>
                  <Button
                    type="primary"
                    onClick={() => remove({ ...myContext, userId, invoiceId: m.id })}
                    loading={isRemoving}
                    icon={<DeleteOutlined />}
                    ghost
                    danger
                  />
                </>
              )}
              {!m.isDraft && !m.isCredit && (
                <UserInvoicesCreateCredit
                  label="Avoir"
                  values={{ bill: { invoiceId: m.id, articles: m.bill.articles } }}
                  onTabs={onTabs}
                />
              )}
            </div>
          ),
        },
      ]}
    />
  );
}
