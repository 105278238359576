import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  Table,
} from 'antd';
import React, { useState } from 'react';
import { useGetArticlesQuery, useRemoveArticleMutation } from '../../api/v2/articles';
import { useMyContext } from '../../hooks/useMyContext';
import ImportSpots from './Import';
import ArticleEdition from './Edition';
import IFrame from '../../components/IFrame';
import FlexGrow from '../../components/FlexGrow';

export default function Articles() {
  const [version, setVersion] = useState(localStorage.getItem('articles_version') || '2');
  const [edition, setEdition] = useState(null);

  const { myContext } = useMyContext();

  const [remove] = useRemoveArticleMutation();
  const { data: articles } = useGetArticlesQuery({ ...myContext });

  if (edition) {
    return <ArticleEdition edition={edition} setEdition={setEdition} />;
  }

  return (
    <FlexGrow style={{ gap: 8 }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          type="link"
          onClick={() => {
            const newVersion = version === '1' ? '2' : '1';
            setVersion(newVersion);
            localStorage.setItem('articles_version', newVersion);
          }}
        >
          {version === '1' ? 'Revenir sur la nouvelle version' : "Utiliser l'ancienne version"}
        </Button>
      </div>

      {version === '1' && <IFrame path="/articles" />}

      {version === '2' && (
        <>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 16 }}>
            <ArticleEdition />
            <ImportSpots />
          </div>
          <Table
            dataSource={articles}
            scroll={{ x: true }}
            columns={[
              { title: 'Code', dataIndex: 'code' },
              { title: 'Label', dataIndex: 'label' },
              { title: 'Label EN', dataIndex: 'labelEN' },
              { title: 'Label facture', dataIndex: 'label2' },
              { title: 'Prix H.T.', dataIndex: 'price', render: (price) => `${price} €` },
              { title: 'Prix T.T.C.', dataIndex: 'priceTTC', render: (priceTTC) => `${priceTTC} €` },
              { title: 'Taux TVA', dataIndex: 'tva', render: (tva) => `${tva || 0} %` },
              {
                render: (v) => (
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                    <ArticleEdition values={v} />
                    <Button
                      onClick={() => {
                        Modal.confirm({
                          title: 'Voulez-vous vraiment supprimer cet article ?',
                          icon: <ExclamationCircleOutlined />,
                          okText: 'Oui',
                          cancelText: 'Non',
                          async onOk() {
                            return remove({ ...myContext, articleId: v.id });
                          },
                        });
                      }}
                      disabled={!v.id}
                      danger
                    >
                      Supprimer
                    </Button>
                  </div>
                ),
              },
            ]}
          />
        </>
      )}
    </FlexGrow>
  );
}
