import { Alert, Button } from 'antd';
import React from 'react';
import { useBulkRemoveParticipationsMutation } from '../../../api/v2/participations';
import { useMyContext } from '../../../hooks/useMyContext';
import { useUsers } from '../useUsers';

export default function RemoveParticipations({ close, total }) {
  const { myContext } = useMyContext();
  const { usersHeaders, usersFilters } = useUsers();

  const [bulk, { isLoading }] = useBulkRemoveParticipationsMutation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Alert type="warning" message={`Êtes-vous sûr de vouloir supprimer la participation de ${total} utilisateur(s)`} showIcon />
      <div style={{ display: 'flex', gap: 8, justifyContent: 'space-between' }}>
        <Button
          onClick={() => {
            close();
          }}
        >
          Non
        </Button>
        <Button
          loading={isLoading}
          onClick={async () => {
            await bulk({ ...myContext, filters: usersFilters, headers: usersHeaders }).unwrap();
            close();
          }}
          type="primary"
          danger
        >
          Oui
        </Button>
      </div>
    </div>
  );
}
