import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import { useGetCompanyQuery } from '../../api/v2/companies';
import { useMyContext } from '../../hooks/useMyContext';
import CompanyEmployees from './Employees';
import IFrame from '../../components/IFrame';
import FlexGrow from '../../components/FlexGrow';

export default function CompanyPage() {
  const [version, setVersion] = useState(localStorage.getItem('user_version') || '2');

  const { myContext } = useMyContext();
  const { companyId } = useParams();

  const { data: company } = useGetCompanyQuery({ ...myContext, companyId });

  if (!company) {
    return null;
  }

  return (
    <FlexGrow>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 8 }}>
        <Button
          type="link"
          onClick={() => {
            const newVersion = version === '1' ? '2' : '1';
            setVersion(newVersion);
            localStorage.setItem('company_version', newVersion);
          }}
        >
          {version === '1' ? 'Revenir sur la nouvelle version' : "Utiliser l'ancienne version"}
        </Button>
      </div>

      {version === '2' && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8, flexGrow: 1 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <div style={{ fontSize: 18, fontWeight: '600' }}>{company.name}</div>
            {company.address1 && <div>{company.address1}</div>}
            {(company.postalCode || company.city)
              && <div>{`${company.postalCode && `${company.postalCode} `}${company.city && `${company.city}`}`}</div>}
            {company.phone && <div>{company.phone}</div>}
          </div>
          <CompanyEmployees />
        </div>
      )}

      {version === '1' && <IFrame path={`/companies/${company.id}`} />}

    </FlexGrow>
  );
}
