import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useGetSettingsQuery } from '../../api/v2/settings';
import { useMyContext } from '../../hooks/useMyContext';
import usePrefs from '../../hooks/usePrefs';

const UsersContext = createContext();

export function UsersProvider({ children }) {
  const [usersHeaders, setUsersHeaders] = useState([]);
  const [usersFilters, setUsersFilters] = useState([]);
  const [currentSettings, setCurrentSettings] = useState();
  const [newId, setNewId] = useState();

  const { myContext } = useMyContext();
  const prefs = usePrefs();

  const { data: settings } = useGetSettingsQuery(myContext);

  useEffect(() => {
    if (settings && settings.length > 0) {
      const find = settings.find((f) => f.id === newId)
      || settings.find((f) => f.id === prefs?.USERS_SETTINGS_FAV)
      || settings.find((f) => f.id === currentSettings?.id);

      setCurrentSettings(find || settings[0]);
      setNewId(null);
    }
  }, [settings]);

  useEffect(() => {
    if (currentSettings) {
      setUsersFilters(currentSettings.usersFilters);
      setUsersHeaders(currentSettings.usersHeaders);
    }
  }, [currentSettings]);

  const state = useMemo(() => ({
    usersHeaders,
    setUsersHeaders,
    usersFilters,
    setUsersFilters,
    settings,
    currentSettings,
    setCurrentSettings,
    setNewId,
  }), [usersHeaders,
    setUsersHeaders,
    usersFilters,
    setUsersFilters,
    settings,
    currentSettings,
    setCurrentSettings,
    setNewId,
  ]);

  return (
    <UsersContext.Provider value={state}>
      {children}
    </UsersContext.Provider>
  );
}

export const useUsers = () => useContext(UsersContext);
