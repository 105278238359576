import { useGetMeQuery } from '../api/v2/users';
import { useMyContext } from './useMyContext';

const usePrefs = () => {
  const { myContext } = useMyContext();
  const { data: me } = useGetMeQuery(myContext);

  const { eventId } = myContext;

  if (!me) {
    return {};
  }

  return {
    ...me.prefs,
    USERS_SETTINGS_FAV: eventId
      ? (me.prefs[`USERS_SETTINGS_FAV_${eventId}`] || me.prefs.USERS_SETTINGS_FAV_null)
      : me.prefs.USERS_SETTINGS_FAV_null,
  };
};

export default usePrefs;
