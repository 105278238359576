import React from 'react';
import { useParams } from 'react-router-dom';
import { Select } from 'antd';
import { useGetConferencesQuery, useUpdateConferenceSlotUserMutation } from '../../../api/v2/conferences';
import { useGetParticipationQuery, useUpdateParticipationMutation } from '../../../api/v2/participations';
import Flex from '../../../components/Flex';
import { useMyContext } from '../../../hooks/useMyContext';
import SendEmail, { boEmails } from '../../../components/SendEmail';

export default function UserEventConferences() {
  const { myContext, event } = useMyContext();
  const { userId } = useParams();

  const { data: participation } = useGetParticipationQuery({ ...myContext, userId });
  const { data } = useGetConferencesQuery({ ...myContext, userId });
  const [updateSlot] = useUpdateConferenceSlotUserMutation();

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <SendEmail email={boEmails.EMAILS_SEND_CONFERENCES} />
      {data?.conferences.map((m) => (
        <Flex style={{ alignItems: 'center' }}>
          <div>{`${m.title} :`}</div>
          <Select
            style={{ flexGrow: 1 }}
            options={m.slots.map((s) => ({ label: s.date, value: s.id, disabled: s.quota && s.total >= s.quota }))}
            value={data?.participations[m.id]}
            onChange={(slotId) => updateSlot({ ...myContext, userId, slot: { slotId, conferenceId: m.id, registered: true } })}
            allowClear
          />
        </Flex>
      ))}
    </Flex>
  );
}
