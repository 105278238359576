import React from 'react';
import { ConfigProvider } from 'antd';
import { LayoutProvider } from '../../hooks/useLayout';
import Main from './Main';
import Navbar from './Navbar';
import SidebarDesktop from './SidebarDesktop';
import SidebarMobile from './SidebarMobile';
import FlexGrow from '../FlexGrow';
import useSettingsBo from '../../hooks/useSettingsBO';

export default function Layout() {
  const settings = useSettingsBo();

  return (
    <LayoutProvider>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: settings.primaryColor,
          },
          components: {
            Menu: {
              darkItemBg: settings.SIDEBAR_BACKGROUND_COLOR_V2 || '#001529',
              darkSubMenuItemBg: settings.SIDEBAR_BACKGROUND_COLOR_V2 || '#001529',
              darkItemColor: settings.SIDEBAR_TEXT_COLOR_V2 || 'rgba(255, 255, 255, 0.65)',
              darkItemSelectedBg: settings?.primaryColor,
              darkItemSelectedColor: settings.SIDEBAR_ITEM_SELECTED_TEXT_COLOR_V2 || '#fff',
            },
          },
        }}
      >
        <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
          <Navbar />
          <FlexGrow>
            <SidebarDesktop />
            <SidebarMobile />
            <Main />
          </FlexGrow>
        </div>
      </ConfigProvider>
    </LayoutProvider>
  );
}
