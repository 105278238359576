import apiV2 from '.';

export const logsApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    getCompanions: builder.query({
      query: ({ domainId, eventId, userId }) => `/domains/${domainId}/events/${eventId}/users/${userId}/companions`,
      transformResponse: (response) => response.data.companions,
      providesTags: ['USERS', 'PARTICIPATION'],
    }),
  }),
});

export const {
  useGetCompanionsQuery,
} = logsApi;
