import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import React from 'react';
import { useFiles } from '../hooks/useFiles';

function MyUploadImage({ value, onChange }) {
  const { uploadFile } = useFiles();

  return (
    <Upload
      beforeUpload={(file) => {
        uploadFile(file, true).then(({ uuid }) => onChange(`https://gayakoa-files-eu-west-1-public.s3.eu-west-1.amazonaws.com/${uuid}`));
        return false;
      }}
      maxCount={1}
      showUploadList={false}

    >
      <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
        {value && <img style={{ maxHeight: 50 }} src={value} alt="" />}

        <Button type="primary" icon={<UploadOutlined />}>
          {value ? 'Modifier le fichier' : 'Choisir un fichier'}
        </Button>
      </div>

    </Upload>
  );
}

export default MyUploadImage;
