/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/no-unstable-nested-components */
import { Alert, Button, Checkbox, Form, Select, Spin } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { read, utils } from 'xlsx';
import { useUsersImportV2Mutation } from '../../../api/v2/users';
import { useMyContext } from '../../../hooks/useMyContext';

function excelDateToJSDate(excelDate) {
  const excelEpoch = new Date(1899, 11, 30);

  const jsDate = new Date(excelEpoch.getTime() + excelDate * 24 * 60 * 60 * 1000);

  const month = jsDate.getMonth() + 1;
  const day = jsDate.getDate();
  const year = jsDate.getFullYear();

  return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
}

const groupBy = (data, property) => data.reduce((prev, user) => {
  const findEmail = prev.find((item) => item[property] === user[property]);

  if (findEmail) {
    const findInvoice = findEmail.invoices.find((f) => f.orderId === user.orderId);
    if (findInvoice) {
      findInvoice.articles.push({ quantity: user?.quantity, code: user?.code });
    } else {
      findEmail.invoices.push({
        orderId: user?.orderId,
        invoiceId: user?.invoiceId,
        intracom: user?.intracom,
        companyName: user?.companyName,
        address1: user?.address1,
        address2: user?.address2,
        address3: user?.address3,
        postalCode: user?.postalCode,
        city: user?.city,
        country: user?.country,
        date: user?.date,
        articles: [{ quantity: user?.quantity, code: user?.code }],
      });
    }
  } else {
    prev.push({
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      language: user?.language,
      invoices: [{
        orderId: user?.orderId,
        invoiceId: user?.invoiceId,
        intracom: user?.intracom,
        companyName: user?.companyName,
        address1: user?.address1,
        address2: user?.address2,
        address3: user?.address3,
        postalCode: user?.postalCode,
        city: user?.city,
        country: user?.country,
        date: user?.date,
        articles: [{ quantity: user?.quantity, code: user?.code }],
      }],
    });
  }

  return prev;
}, []);

const columns1 = [
  {
    title: 'Email',
    dataIndex: 'email',
    label: "Choisir la colonne de l'email de l'utilsateur",
    search: 'Participant > Email',
  },
  {
    title: 'Prénom',
    dataIndex: 'firstName',
    label: "Choisir la colonne du prénom de l'utilsateur",
    search: 'Participant > Prénom',
  },
  {
    title: 'Nom',
    dataIndex: 'lastName',
    label: "Choisir la colonne du nom de l'utilisateur",
    search: 'Participant > Nom',
  },
];

const columns2 = [
  {
    title: 'Code',
    dataIndex: 'code',
    label: "Choisir la colonne du code de l'article",
    search: 'Produit > Code',
  },
  {
    title: 'Quantité',
    dataIndex: 'quantity',
    label: "Choisir la colonne de la quantité de l'article",
    search: 'Quantité',
  },
];

const columns3 = [
  {
    title: 'Numéro de facture',
    dataIndex: 'invoiceId',
    label: 'Choisir la colonne du numéro de facture',
    search: 'Référence commande',
  },
  {
    title: 'Numéro de commande',
    dataIndex: 'orderId',
    label: 'Choisir la colonne du numéro de commande',
    search: 'N° de commande',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    label: 'Choisir la date de la commande',
    render: (v) => excelDateToJSDate(v),
    search: 'Date de commande',
  },
  {
    title: 'TVA intracom',
    dataIndex: 'intracom',
    label: 'Choisir la colonne du numéro de TVA intracom',
    search: 'N° TVA INTRACOM',
  },
  {
    title: 'Société',
    dataIndex: 'companyName',
    label: 'Choisir la colonne du nom de la société',
    search: 'Participant > Société',
  },
  {
    title: 'Adresse 1',
    dataIndex: 'address1',
    label: 'Choisir la colonne de l\'adresse 1',
    search: 'Participant > Adresse ligne 1',
  },
  {
    title: 'Adresse 2',
    dataIndex: 'address2',
    label: 'Choisir la colonne de l\'adresse 2',
    search: 'Participant > Adresse ligne 2',
  },
  {
    title: 'Adresse 3',
    dataIndex: 'address3',
    label: 'Choisir la colonne de l\'adresse 3',
    search: 'Participant > Adresse ligne 3',
  },
  {
    title: 'Code postal',
    dataIndex: 'postalCode',
    label: 'Choisir la colonne du code postal',
    search: 'Participant > Code postal',
  },
  {
    title: 'Ville',
    dataIndex: 'city',
    label: 'Choisir la colonne de la ville',
    search: 'Participant > Ville',
  },
  {
    title: 'Pays',
    dataIndex: 'country',
    label: 'Choisir la colonne du pays',
    search: 'Participant > Pays',
  },
  {
    title: 'Langage',
    dataIndex: 'language',
    label: 'Choisir la colonne du langage',
    search: 'Commande > Particpant facturé > Langue (Code)',
  },
];

function splitArrayIntoChunks(array, chunkSize = 5000) {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

export default function ImportComexpo() {
  const [headers, setHeaders] = useState([]);
  const [raw, setRaw] = useState([]);
  const [values, setValues] = useState();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { myContext, wait } = useMyContext();
  const ref = useRef();
  const [form] = Form.useForm();

  const [importUsers] = useUsersImportV2Mutation();

  const reset = () => {
    setHeaders([]);
    setRaw([]);
    ref.current.value = '';
  };

  const handleFileUpload = async (event) => {
    setLoading(true);
    setSuccess(false);
    await new Promise((r) => setTimeout(() => r(), 500));
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: 'buffer', codepage: '65001' });
      const sheetName = workbook.SheetNames[0];
      const array = utils.sheet_to_json(workbook.Sheets[sheetName], { defval: '', header: 1 });
      setHeaders(array[0]);
      setRaw(array);
      setLoading(false);
    };

    reader.readAsArrayBuffer(file);
  };

  const headerOptions = useMemo(() => headers.map((m, i) => ({ label: m, value: i })), [headers]);

  useEffect(() => {
    const v = { skipHeader: true };
    [...columns1, ...columns2, ...columns3].forEach((element) => {
      const find = headerOptions.find((f) => f.label.includes(element.search));
      v[element.dataIndex] = find?.value;
    });
    setValues(v);
    form.setFieldsValue(v);
  }, [headerOptions]);

  const getData = () => {
    const properties = (values
      ? Object.entries(values).filter(([k, vv]) => vv !== undefined && k !== 'skipHeader')
      : []);

    if (properties.length > 0) {
      const array = raw
        .filter((_, i) => (values.skipHeader ? i !== 0 : true))
        .map((a, i) => properties.reduce((prev, [k, v]) => ({ key: i, ...prev, [k]: a[v] }), {}));

      const hasUniqueIndex = properties.find(([k]) => k === 'email');
      if (hasUniqueIndex) {
        return groupBy(array, 'email');
      }

      return array;
    }
    return [];
  };

  return (
    <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
      <div>
        <input
          ref={ref}
          type="file"
          accept=".xlsx, .xls, .csv"
          onChange={handleFileUpload}
          value={undefined}
          hidden
        />
        <Button type="primary" onClick={() => ref.current?.click()} style={{ width: '100%' }}>
          Choisir un fichier excel ou csv
        </Button>
      </div>

      {success && <Alert message="Import effectué avec succès !" type="success" showIcon />}
      <Spin spinning={loading} fullscreen />

      {raw.length > 0 && (
        <>
          <Form form={form} onValuesChange={(__, v) => setValues(v)}>
            <Form.Item label="Ne pas garder la première ligne (header)" name="skipHeader" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            {[...columns1, ...columns2, ...columns3].map((c) => (
              <Form.Item label={c.label} name={c.dataIndex} rules={[{ required: true }]}>
                <Select options={headerOptions} popupMatchSelectWidth={false} allowClear />
              </Form.Item>
            ))}
          </Form>

          <Alert
            description="Voulez-vous importer les utilisateurs ?"
            type="info"
            action={(
              <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
                <Button
                  type="primary"
                  onClick={async () => {
                    setLoading(true);
                    try {
                      await wait(500);
                      const data = getData();
                      const split = splitArrayIntoChunks(data, 10000);
                      for (let i = 0; i < split.length; i += 1) {
                        const curr = split[i];
                        await importUsers({ ...myContext, users: curr }).unwrap();
                      }
                      setSuccess(true);
                      setLoading(false);
                      reset();
                    } catch (err) {
                      console.log(err);
                    } finally {
                      setLoading(false);
                    }
                  }}
                  loading={loading}
                >
                  Oui
                </Button>
                <Button onClick={() => reset()} loading={loading} danger ghost>
                  Non
                </Button>
              </div>
            )}
          />
        </>
      )}
    </div>
  );
}
