import apiV2 from '.';

export const articleCodesApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    createArticleCode: builder.mutation({
      query: ({ domainId, eventId, body }) => ({
        url: `/domains/${domainId}/events/${eventId}/article-codes`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ARTICLE_CODES'],
      transformErrorResponse: (response) => response.data.message,
    }),
    updateArticleCode: builder.mutation({
      query: ({ domainId, eventId, id, body }) => ({
        url: `/domains/${domainId}/events/${eventId}/article-codes/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ARTICLE_CODES'],
      transformErrorResponse: (response) => response.data.message,
    }),
    removeArticleCode: builder.mutation({
      query: ({ domainId, eventId, id }) => ({
        url: `/domains/${domainId}/events/${eventId}/article-codes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ARTICLE_CODES'],
    }),
    getArticleCodes: builder.query({
      query: ({ domainId, eventId }) => `/domains/${domainId}/events/${eventId}/article-codes`,
      transformResponse: (response) => response.data.articleCodes,
      providesTags: ['ARTICLE_CODES'],
    }),
  }),
});

export const {
  useCreateArticleCodeMutation,
  useUpdateArticleCodeMutation,
  useRemoveArticleCodeMutation,
  useGetArticleCodesQuery,
} = articleCodesApi;
