import { Button, Form, Input, Modal, Table } from 'antd';
import React, { useMemo, useState } from 'react';
import { useGetLanguagesQuery, useSetTranslationMutation } from '../../api/v2/languages';

export default function LanguagesPage() {
  const [open, setOpen] = useState(false);

  const [form] = Form.useForm();

  const { data: languages } = useGetLanguagesQuery();
  const [updateTranslation] = useSetTranslationMutation();

  const translationsDefault = useMemo(() => languages?.find((f) => f.key === 'FR')?.translations || {}, [languages]);
  const translations = useMemo(() => Object.entries(translationsDefault).map(([k, v]) => ({ key: k })), [translationsDefault]);
  const t = useMemo(() => languages?.reduce((prev, curr) => ({ ...prev, [curr.key]: curr.translations }), {}), [languages]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Button type="primary" onClick={() => setOpen(true)}>Ajouter une nouvelle clé</Button>
      <Modal open={open} title="Ajouter une nouvelle clé" footer={null} onCancel={() => setOpen(false)}>
        <Form
          form={form}
          onFinish={async (v) => {
            await updateTranslation({ body: v }).unwrap();
            setOpen(false);
            form.resetFields();
          }}
        >
          <Form.Item label="Clé" name="key">
            <Input />
          </Form.Item>
          {languages?.map((m) => (
            <Form.Item label={m.label} name={['languages', m.key]}>
              <Input />
            </Form.Item>
          ))}
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: 8 }}>
            <Button onClick={() => setOpen(false)}>Annuler</Button>
            <Button style={{ flexGrow: 1 }} type="primary" htmlType="submit">Enregistrer</Button>
          </div>
        </Form>
      </Modal>
      <Table
        dataSource={translations}
        scroll={{ x: true }}
        columns={[
          { title: 'Clé', dataIndex: 'key' },
          ...(languages ? languages.map((m) => ({ title: m.label, render: (v) => t[m.key][v.key] })) : []),
          {
            render: (v) => (
              <div style={{ display: 'flex', gap: 8 }}>
                <Button
                  type="primary"
                  onClick={() => {
                    setOpen(true);
                    const values = languages?.reduce((prev, curr) => ({ ...prev, [curr.key]: t[curr.key][v.key] }), {});
                    form.setFieldsValue({ key: v.key, languages: values });
                  }}
                >
                  Modifier
                </Button>
                <Button
                  type="primary"
                  onClick={async () => {
                    const values = languages?.reduce((prev, curr) => ({ ...prev, [curr.key]: null }), {});
                    await updateTranslation({ body: { key: v.key, languages: values } });
                  }}
                  danger
                >
                  Supprimer
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
