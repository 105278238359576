import queryString from 'query-string';
import apiV2 from '.';

export const statsApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    statsGetInscriptions: builder.query({
      query: ({
        domainId, eventId, eventIdB, isExhibitor, typo,
      }) => `/domains/${domainId}/events/${eventId}/stats/inscriptions?${queryString.stringify({ eventIdB, isExhibitor, typo })}`,
      transformResponse: (response) => response.data,
    }),
    statsGetPresences: builder.query({
      query: ({
        domainId, eventId, eventIdB, isExhibitor, typo, pat,
      }) => `/domains/${domainId}/events/${eventId}/stats/presences?${queryString.stringify({
        eventIdB, isExhibitor, typo, pat,
      })}`,
      transformResponse: (response) => response.data,
    }),
    statsCommon: builder.query({
      query: ({ domainId, eventId }) => `/domains/${domainId}/events/${eventId}/stats/common`,
      transformResponse: (response) => response.data,
      providesTags: ['PARTICIPATION'],
    }),
  }),
});

export const {
  useStatsGetInscriptionsQuery,
  useStatsGetPresencesQuery,
  useStatsCommonQuery,
} = statsApi;
