import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { compareDesc } from 'date-fns';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  useAcceptInvoiceMutation,
  useGetInvoicesQuery,
  useRemoveInvoiceMutation,
} from '../../../../api/v2/invoices';
import BooleanIcon from '../../../../components/BooleanIcon';
import ButtonConfirm from '../../../../components/ButtonConfirm';
import { useMyContext } from '../../../../hooks/useMyContext';
import useSettingsBo from '../../../../hooks/useSettingsBO';
import ShowInvoiceGayakoa from './Show';

export default function UserInvoicesGayakoaTable() {
  const { myContext } = useMyContext();
  const { userId } = useParams();
  const settings = useSettingsBo();

  const { data: invoices } = useGetInvoicesQuery({ ...myContext, userId });
  const [accept, { isLoading: isAccepting }] = useAcceptInvoiceMutation();
  const [remove, { isLoading: isRemoving }] = useRemoveInvoiceMutation();

  return (
    <Table
      rowKey="id"
      scroll={{ x: true }}
      rowHoverable={false}
      pagination={false}
      onRow={(record) => ({
        style: {
          backgroundColor: record.isDraft ? 'rgba(176, 0, 0, 0.2)' : 'rgba(18, 177, 0, 0.2)',
        },
      })}
      dataSource={invoices?.map((m) => m)
        .filter((f) => !f.isCredit)
        .sort((a, b) => compareDesc(a.createdAt, b.createdAt))}
      columns={[
        { title: 'Référence', dataIndex: 'label' },
        { title: 'Date', dataIndex: ['bill', 'date'] },
        ...(settings?.withoutTva ? [{ title: 'Avec TVA', dataIndex: 'withTva', render: (v) => <BooleanIcon value={v} /> }] : []),
        { title: 'Total HT', dataIndex: 'price', render: (p) => `${p} €` },
        { title: 'Total TTC', dataIndex: 'priceTva', render: (p) => `${p} €` },
        {
          render: (m) => (
            <div style={{ display: 'flex', gap: 8 }}>
              <ShowInvoiceGayakoa invoice={m}>
                <Button type="primary" icon={<EyeOutlined />} />
              </ShowInvoiceGayakoa>
              {m.isDraft === 1 && (
                <>
                  <ButtonConfirm
                    title={m.isCredit ? 'Voulez-vous valider cet avoir ?' : 'Voulez-vous valider cette facture ?'}
                    onOk={() => accept({ ...myContext, userId, invoiceId: m.id })}
                  >
                    <Button type="primary" loading={isAccepting} ghost>
                      Valider
                    </Button>
                  </ButtonConfirm>
                  <Button
                    type="primary"
                    onClick={() => remove({ ...myContext, userId, invoiceId: m.id })}
                    loading={isRemoving}
                    icon={<DeleteOutlined />}
                    ghost
                    danger
                  />
                </>
              )}
            </div>
          ),
        },
      ]}
    />
  );
}
