/* eslint-disable import/no-cycle */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Table } from 'antd';
import React, { useState } from 'react';
import { useGetRolesQuery, useGetRoleUsersQuery, useRemoveRoleMutation, useSetUserRoleMutation } from '../../api/v2/roles';
import { useMyContext } from '../../hooks/useMyContext';
import RightsEdition from './Edition';

export default function RightsPage() {
  const [edition, setEdition] = useState(null);

  const { myContext } = useMyContext();

  const { data, isFetching: isFetchingRoles } = useGetRolesQuery(myContext);
  const { data: users, isFetching: isFetchingRoleUsers } = useGetRoleUsersQuery(myContext);
  const [remove] = useRemoveRoleMutation();
  const [setUserRole, { isLoading: isLoadingSetUserRole }] = useSetUserRoleMutation();

  if (edition) {
    return <RightsEdition edition={edition} setEdition={setEdition} />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Button type="primary" onClick={() => setEdition({})}>
        Créer un nouveau rôle
      </Button>
      <Table
        loading={isFetchingRoles}
        columns={[
          { title: 'Rôle', dataIndex: 'label' },
          {
            render: (v) => (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                <Button type="primary" onClick={() => setEdition(v)}>
                  Modifier
                </Button>
                <Button
                  onClick={() => {
                    Modal.confirm({
                      title: 'Voulez-vous vraiment supprimer ce rôle ?',
                      icon: <ExclamationCircleOutlined />,
                      okText: 'Oui',
                      cancelText: 'Non',
                      async onOk() {
                        return remove({ ...myContext, roleId: v.id });
                      },
                    });
                  }}
                  danger
                >
                  Supprimer
                </Button>
              </div>
            ),
          },
        ]}
        dataSource={data?.roles}
        scroll={{ x: true }}
      />
      <Table
        isLoading={isFetchingRoleUsers}
        columns={[
          { title: 'Prénom', dataIndex: 'firstName' },
          { title: 'Nom', dataIndex: 'lastName' },
          { title: 'Email', dataIndex: 'email' },
          { title: 'Rôle', dataIndex: 'label' },
          {
            render: (v) => {
              if (v.canUpdate) {
                return (
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                    <Button
                      loading={isLoadingSetUserRole}
                      onClick={() => {
                        Modal.confirm({
                          title: `Voulez-vous vraiment supprimer les droits de ${v.firstName}  ${v.lastName} ?`,
                          icon: <ExclamationCircleOutlined />,
                          okText: 'Oui',
                          cancelText: 'Non',
                          async onOk() {
                            return setUserRole({ ...myContext, userId: v.id, roleId: 0 });
                          },
                        });
                      }}
                      danger
                    >
                      Supprimer
                    </Button>
                  </div>
                );
              }

              return <div>Cet utilisateur a un rôle sur ce domaine</div>;
            },
          },
        ]}
        dataSource={users}
        scroll={{ x: true }}
      />
    </div>
  );
}
