import { Button, Select, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetTemplatesQuery, useRemoveTemplateMutation, useSendEmailMutation } from '../../../api/v2/emails';
import { useGetFoSettingsQuery } from '../../../api/v2/fo';
import { useGetLanguagesQuery } from '../../../api/v2/languages';
import BottomBar from '../../../components/BottomBar';
import useMe from '../../../hooks/useMe';
import { useMyContext } from '../../../hooks/useMyContext';
import { foEmails } from '../../settings/meeting/Emails';
import TemplatesEdition from './Edition';
import { boEmails, boEmailsArr } from '../../../components/SendEmail';
import useSettingsBo from '../../../hooks/useSettingsBO';

export default function EmailTemplatesPage() {
  const [languageId, setLanguageId] = useState();

  const { myContext } = useMyContext();
  const settingsBo = useSettingsBo();
  const me = useMe();

  const { data, isFetching } = useGetTemplatesQuery(myContext);
  const [removeTemplate] = useRemoveTemplateMutation();
  const { data: languages } = useGetLanguagesQuery();
  const { data: settings } = useGetFoSettingsQuery({ ...myContext, languageId });
  const [send, { isLoading }] = useSendEmailMutation();

  const emails = useMemo(() => data?.filter((f) => f.languageId === languageId), [languageId, data]);

  useEffect(() => {
    if (languages) {
      setLanguageId(languages[0]?.id);
    }
  }, [languages]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div style={{ textAlign: 'center', fontWeight: 600, fontSize: 25 }}>
        Gabarits des emails (déclenchements automatiques et manuels)
      </div>
      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <div>Langue :</div>
        <Select options={languages?.map((m) => ({ label: m.label, value: m.id }))} value={languageId} onChange={setLanguageId} />
      </div>
      <Table
        rowKey="id"
        dataSource={emails}
        scroll={{ x: true }}
        loading={isFetching}
        columns={[
          { title: 'Nom', dataIndex: 'name' },
          { title: 'Langue', dataIndex: 'language' },
          { title: 'Compte Mailjet', dataIndex: 'accountName' },
          { title: 'Template', dataIndex: 'templateId' },
          {
            title: 'FO',
            dataIndex: 'id',
            render: (id) => foEmails.filter((f) => settings?.[f.value] === id).map((m) => m.label).join(', '),
          },
          {
            title: 'BO',
            dataIndex: 'id',
            render: (id) => boEmailsArr.filter((f) => settingsBo?.[`${f.value}_${languageId}`] === id).map((m) => m.label).join(', '),
          },
          {
            title: '',
            render: (template) => (
              <div style={{ display: 'flex', gap: 8 }}>
                <TemplatesEdition template={template} languageId={languageId}>
                  <Button type="primary">
                    Modifier
                  </Button>
                </TemplatesEdition>

                {me?.email && (
                  <Button
                    onClick={() => send({ ...myContext, userId: me.id, templateId: template.id }).unwrap()}
                    type="primary"
                    loading={isLoading}
                    ghost
                  >
                    {`TEST (${me.email})`}
                  </Button>
                )}

                {!template.actionId ? (
                  <Button
                    onClick={() => removeTemplate({ ...myContext, templateId: template.id })}
                    type="primary"
                    danger
                    ghost
                  >
                    Supprimer
                  </Button>
                ) : null}
              </div>
            ),
          },
        ]}
      />
      <BottomBar>
        <TemplatesEdition languageId={languageId}>
          <Button type="primary" style={{ width: '100%' }}>
            Créer un template personnalisé
          </Button>
        </TemplatesEdition>
      </BottomBar>
    </div>
  );
}
