/* eslint-disable react/jsx-props-no-spreading */
import { Select } from 'antd';
import React from 'react';
import useMobile from '../hooks/useMobile';

export default function MySelect(props) {
  const { options, value, onChange, style, placeholder, allowClear } = props;

  const { isMobile } = useMobile();

  if (isMobile) {
    return (
      <div className="my-select" style={style}>
        <select
          value={value}
          onChange={(e) => {
            console.log(e.target.value);
            onChange(Number(e.target.value) || undefined);
          }}
        >
          {allowClear && <option value="">{placeholder}</option>}
          {options?.map((m) => <option value={m.value}>{m.label}</option>)}
        </select>
      </div>
    );
  }

  return (
    <Select {...props} popupMatchSelectWidth={false} />
  );
}
