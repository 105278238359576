import {
  Button, DatePicker, Form, Input,
  Select,
  Switch,
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useForm } from 'antd/es/form/Form';
import { useGetParentsQuery, useLazyGetParentChildsQuery } from '../../api/v2/forms';
import { useCreateSpotMutation, useUpdateSpotMutation } from '../../api/v2/spots';
import { useMyContext } from '../../hooks/useMyContext';

export default function SpotsEdition({ edition, setEdition }) {
  const { myContext } = useMyContext();
  const [form] = useForm();

  const [create] = useCreateSpotMutation();
  const [update] = useUpdateSpotMutation();
  const { data: parents } = useGetParentsQuery({ ...myContext });
  const [getChilds, { data: childs }] = useLazyGetParentChildsQuery();

  const isCreation = !edition?.id;

  useEffect(() => {
    if (edition?.extendedParent) {
      getChilds({ ...myContext, parentKey: edition.extendedParent });
    }
  }, [edition, myContext]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div>
        <Button type="primary" onClick={() => setEdition(null)}>
          Retour
        </Button>
      </div>
      <Form
        form={form}
        initialValues={{ ...edition, startAt: dayjs(edition.startAt) }}
        onFinish={async (spot) => {
          const spotFinal = { ...spot, startAt: spot.startAt.toDate(), userId: edition.userId };

          if (isCreation) {
            await create({ ...myContext, spot: spotFinal }).unwrap();
          } else {
            await update({ ...myContext, spot: spotFinal, spotId: edition.id }).unwrap();
          }
          setEdition(null);
        }}
      >
        <Form.Item label="Label" name="label" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        {!edition?.userId && (
          <>
            <Form.Item label="Pour tout le monde" name="forAll">
              <Switch />
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue, setFieldValue }) => !getFieldValue('forAll') && (
                <>
                  <Form.Item label="Sujet" name="extendedParent" rules={[{ required: true }]} preserve={false}>
                    <Select
                      options={parents?.map((m) => ({ label: m.value, value: m.key }))}
                      onChange={(v) => {
                        getChilds({ ...myContext, parentKey: v });
                        setFieldValue('extendedChild', null);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Option" name="extendedChild" rules={[{ required: true }]} preserve={false}>
                    <Select options={childs?.map((m) => ({ label: m.value, value: m.key }))} />
                  </Form.Item>
                </>
              )}
            </Form.Item>
          </>
        )}
        <Form.Item label="Date" name="startAt" rules={[{ required: true }]}>
          <DatePicker format="DD/MM/YYYY HH:mm" style={{ width: 200 }} showTime />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button type="primary" htmlType="submit">
            {isCreation ? 'Créer' : 'Modifier'}
          </Button>
        </div>
      </Form>
    </div>
  );
}
