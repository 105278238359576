import { Button, Form, Input, Modal } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useUpdateModelMutation } from '../../../api/v2/models';
import { useMyContext } from '../../../hooks/useMyContext';

export default function ModelsEdition({ children, model }) {
  const [visible, setVisible] = useState(false);

  const { myContext } = useMyContext();
  const [form] = Form.useForm();

  const [updateModel, { isLoading }] = useUpdateModelMutation();

  const label = useMemo(() => (model ? 'Modifier' : 'Ajouter'), [model]);

  useEffect(() => {
    if (model) {
      form.setFieldsValue(model);
    }
  }, [form, model]);

  return (
    <>
      { React.cloneElement(children, { onClick: () => setVisible(true) })}
      <Modal
        title={`${label} une donnée étendue`}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          onFinish={async (values) => {
            if (model?.id) {
              await updateModel({ ...myContext, model: values, modelId: model.id }).unwrap();
            }
            setVisible(false);
            form.resetFields();
          }}
        >
          <Form.Item label="Label" name="value" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
          <Form.Item label="Key" name="key" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
          <Form.Item label="Quota" name="quota" rules={[{ required: true }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Ordre" name="order">
            <Input type="number" />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: 8 }}>
            <Button onClick={() => setVisible(false)}>
              Annuler
            </Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {label}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
