import { CloseCircleOutlined, MenuOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useLazySearchQuery } from '../../api/v2/search';
import React, { useLayout } from '../../hooks/useLayout';
import { useMyContext } from '../../hooks/useMyContext';
import useNav from '../../hooks/useNav';
import useSettingsBo from '../../hooks/useSettingsBO';
import Burger from './Burger';
import Profile from './Profile';
import ScanUsers from './ScanUsers';
import CreateUser from './CreateUser';

export default function Navbar() {
  const [search, setSearch] = useState('');

  const requestRef = useRef(null);

  const { isMobile, show, navbarHeight, sidebarWidth, visible, hide } = useLayout();
  const { myContext } = useMyContext();
  const nav = useNav();
  const settings = useSettingsBo();

  const [getSearch, { data, isFetching }] = useLazySearchQuery();

  useEffect(() => {
    if (requestRef.current) {
      requestRef.current.abort();
    }

    if (search) {
      requestRef.current = getSearch({ ...myContext, search });
    }
  }, [myContext, search]);

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      height: navbarHeight,
      backgroundColor: settings?.navbarColor,
      zIndex: 1000,
      gap: 16,
      paddingRight: 8,
      paddingLeft: 8,
    }}
    >
      <div
        style={{
          width: sidebarWidth,
          marginLeft: !isMobile ? -8 : -sidebarWidth - 8,
          transition: 'all 0.3s',
          backgroundColor: 'white',
          height: 60,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img style={{ maxHeight: 60, maxWidth: '100%' }} src={settings.SIDEBAR_LOGO} alt="" />
      </div>

      <div style={{ display: 'flex', alignItems: 'center', gap: 16, flexGrow: 1, maxWidth: 600, marginLeft: -8 }}>
        <AutoComplete
          style={{ flexGrow: 1 }}
          options={data?.options}
          onSearch={setSearch}
          value={search}
          onSelect={(_, v) => {
            if (v.type === 'user') {
              nav(`/users/${v.value}`);
            } else if (v.type === 'company') {
              nav(`/companies/${v.value}`);
            }
          }}
          popupMatchSelectWidth={false}
        >
          <Input.Search loading={isFetching} placeholder="Chercher ..." />
        </AutoComplete>
        <ScanUsers />
        <CreateUser />
      </div>

      {isMobile && (
        <Burger onClick={() => (visible ? hide() : show())}>
          {visible
            ? <CloseCircleOutlined style={{ fontSize: 36, color: 'white' }} />
            : <MenuOutlined style={{ fontSize: 36, color: 'white' }} />}
        </Burger>
      )}

      {!isMobile && <Profile />}
    </div>
  );
}
