import { Form, Select } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetEmployeesQuery } from '../../../api/v2/employees';
import { useGetParticipationQuery, useUpdateParticipationMutation } from '../../../api/v2/participations';
import { useMyContext } from '../../../hooks/useMyContext';

export default function UserEventCompany() {
  const { myContext } = useMyContext();
  const { userId } = useParams();

  const [update, { isLoading }] = useUpdateParticipationMutation();
  const { data: participation } = useGetParticipationQuery({ ...myContext, userId });
  const { data: employees } = useGetEmployeesQuery({ ...myContext, userId });

  return (
    <Form.Item label="Organisation" style={{ marginBottom: 0 }}>
      <Select
        loading={isLoading}
        options={employees?.map((m) => ({ label: m.name, value: m.employeeId }))}
        value={participation?.employeeId}
        onChange={(employeeId) => update({ ...myContext, userId, participation: { employeeId: employeeId || null } })}
        allowClear
      />
    </Form.Item>
  );
}
