import apiV1 from '.';

export const authenticationsApi = apiV1.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password }) => ({
        url: '/authentications/login',
        method: 'POST',
        body: { email, password },
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
      invalidatesTags: [],
    }),
  }),
});

export const { useLoginMutation } = authenticationsApi;
