import { Button, Modal, Table } from 'antd';
import React, { useMemo, useState } from 'react';
import { compareDesc } from 'date-fns';
import { useMyContext } from '../../hooks/useMyContext';
import { useLayout } from '../../hooks/useLayout';

const sorts = [{ label: "Date de l'événement", value: 'lastEvent' }, { label: 'Alphabétique', value: 'alpha' }];

export default function ModalDomain() {
  const [open, setOpen] = useState(false);
  const [sort, setSort] = useState(sorts[0].value);

  const { selectDomain, selectEvent, domain, domains } = useMyContext();
  const { sidebarWidth } = useLayout();

  const domainsSorted = useMemo(() => {
    if (domains) {
      if (sort === 'lastEvent') {
        return [...domains].sort((a, b) => compareDesc(a.lastEvent, b.lastEvent));
      }
      if (sort === 'alpha') {
        return [...domains].sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      }
    }
    return [];
  }, [domains, sort]);

  return (
    <div>
      <Modal
        title="Choisir un domaine"
        onCancel={() => setOpen(false)}
        open={open}
        footer={<Button onClick={() => setOpen(false)} type="primary" ghost>Fermer</Button>}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8, alignItems: 'center' }}>
          <div>Trier par : </div>
          {sorts?.map((s) => (
            <Button
              onClick={() => setSort(s.value)}
              type="primary"
              ghost={s.value !== sort}
            >
              {s.label}
            </Button>
          ))}
        </div>
        <Table
          rowKey="id"
          dataSource={domainsSorted}
          showHeader={false}
          size="small"
          pagination={false}
          columns={[
            { dataIndex: 'name' },
            {
              render: (e) => (
                <Button
                  onClick={() => {
                    selectDomain(e.id);
                    selectEvent(undefined);
                    setOpen(false);
                  }}
                  type="primary"
                  style={{ width: '100%' }}
                >
                  Choisir
                </Button>
              ),
            },
          ]}
        />
      </Modal>

      {domains.length > 1 ? (
        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => setOpen(true)}
          aria-hidden
        >
          <Button>
            <div
              style={{
                maxWidth: sidebarWidth - 40,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                fontSize: 12,
                textAlign: 'center',
              }}
            >
              {domain?.name || ''}
            </div>
          </Button>
        </div>
      ) : (
        <div
          style={{
            maxWidth: sidebarWidth - 40,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize: 14,
            color: 'white',
            textAlign: 'center',
          }}
        >
          {domain?.name || ''}
        </div>
      )}
    </div>
  );
}
