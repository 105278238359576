import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Select } from 'antd';
import React from 'react';
import { useUpdateFoSettingsMutation } from '../../../../api/v2/fo';
import { useMyContext } from '../../../../hooks/useMyContext';
import useTemplates from '../../../../hooks/useTemplates';
import TemplatesEdition from '../../../emails/templates/Edition';
import { useFoContext } from '../context/useFoContext';

function createNestedObject(keys, value) {
  if (Array.isArray(keys)) {
    return keys.reduceRight((acc, key) => ({ [key]: acc }), value);
  }
  return { [keys]: value };
}

function SelectEmail(props) {
  const { name } = props;

  const { myContext } = useMyContext();
  const { language } = useFoContext();
  const { templates } = useTemplates(language);

  const [update] = useUpdateFoSettingsMutation();

  return (
    <div style={{ display: 'flex', gap: 8 }}>
      <Select {...props} options={templates?.map((m) => ({ label: m.name, value: m.id }))} popupMatchSelectWidth={false} allowClear />
      <TemplatesEdition
        onSave={(id) => update({ ...myContext, extended: createNestedObject(name, id), language })}
        language={language}
      >
        <Button type="primary" icon={<PlusCircleOutlined />} />
      </TemplatesEdition>
    </div>
  );
}

export default function FormItemEmail(props) {
  const { name, label, noStyle } = props;

  return (
    <Form.Item label={label} name={name} normalize={(value) => value ?? null} noStyle={noStyle}>
      <SelectEmail name={name} />
    </Form.Item>
  );
}
