import apiV2 from '.';

export const employeesApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    getEmployees: builder.query({
      query: ({ domainId, userId }) => ({
        url: `/domains/${domainId}/users/${userId}/companies`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data?.companies,
      providesTags: ['EMPLOYEES', 'COMPANIES'],
    }),
    getEmployeesByCompany: builder.query({
      query: ({ domainId, companyId }) => ({
        url: `/domains/${domainId}/companies/${companyId}/employees`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data?.users,
      providesTags: ['EMPLOYEES', 'COMPANIES', 'USERS'],
    }),
  }),
});

export const { useGetEmployeesQuery, useGetEmployeesByCompanyQuery } = employeesApi;
