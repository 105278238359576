import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

const FoContext = createContext();

export function FoContextProvider({ children }) {
  const [language, setLanguage] = useState('FR');

  const state = useMemo(() => ({
    language,
    setLanguage,
  }), [language]);

  return (
    <FoContext.Provider value={state}>
      {children}
    </FoContext.Provider>
  );
}

export const useFoContext = () => useContext(FoContext);
