import { Button, Select } from 'antd';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateTokenQuery } from '../../../api/v2/authentications';
import { useMyContext } from '../../../hooks/useMyContext';
import useRights from '../../../hooks/useRights';

const isLocal = window.location.hostname === 'localhost';

export default function UserToken() {
  const { userId } = useParams();
  const { myContext, event } = useMyContext();
  const { hasRight } = useRights();

  const { data: token, isFetching } = useCreateTokenQuery({ ...myContext, userId });

  console.log(token);

  const onChange = (v) => {
    if (v === 'EXPO') {
      const baseUrl = isLocal ? 'http://localhost:3000' : 'https://invoice.sialparis.fr';
      window.open(`${baseUrl}?token=${token}&domainId=${myContext?.domainId}&eventId=${myContext?.eventId}`, '_blank');
    } else if (v === 'MEETING') {
      const baseUrl = isLocal ? 'http://localhost:4001' : 'https://meeting.gayakoa.com';
      window.open(`${baseUrl}/${event?.alias}?token=${token}`, '_blank');
    }
  };

  const options = useMemo(() => {
    const arr = [];
    if (hasRight('SETTINGS_FO_EXPOSIUM')) {
      arr.push({ label: 'FO Comexposium', value: 'EXPO' });
    }
    if (hasRight('CUSTOMIZATIONS_FO') && event?.alias) {
      arr.push({ label: 'FO Meeting', value: 'MEETING' });
    }
    return arr;
  }, [myContext, event, hasRight]);

  if (!event || options.length === 0) {
    return null;
  }

  if (options.length === 1) {
    return <Button type="primary" onClick={() => onChange(options[0].value)}>Se connecter</Button>;
  }

  return (
    <Select
      value={null}
      placeholder="Se connecter ..."
      popupMatchSelectWidth={false}
      onSelect={onChange}
      options={options}
      loading={isFetching}
      disabled={!token}
    />
  );
}
