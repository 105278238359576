import { BarcodeOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import QReader from '../QReader';
import { useLazyGetUserByCodeQuery } from '../../api/v2/users';
import { useMyContext } from '../../hooks/useMyContext';
import useNav from '../../hooks/useNav';

export default function ScanUsers() {
  const [modal, setModal] = useState(false);

  const { myContext } = useMyContext();
  const nav = useNav();

  const [getUserByCode, { isFetching: isFetchingUser }] = useLazyGetUserByCodeQuery();

  const sendCode = useCallback(async (code) => {
    const user = await getUserByCode({ ...myContext, code }).unwrap();
    if (user) {
      nav(`/users/${user.id}`);
    }
    setModal(false);
  }, [myContext]);

  return (
    <>
      <Button onClick={() => setModal(true)} icon={<BarcodeOutlined style={{ fontSize: 22 }} />} />
      {modal && (
        <Modal
          title="Scanner un badge"
          footer={<Button type="primary" onClick={() => setModal(false)}>Fermer</Button>}
          open={modal}
          onCancel={() => setModal(false)}
        >
          {modal && <QReader onChange={sendCode} loading={isFetchingUser} />}
        </Modal>
      )}
    </>
  );
}
