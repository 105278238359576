import 'chart.js/auto';
import React, { useState } from 'react';
import { Button } from 'antd';
import useSelectEvent from '../../hooks/useSelectEvent';
import Inscriptions from './Inscriptions';
import Presences from './Presences';
import useSelectIsExhibitor from '../../hooks/useSelectIsExhibitor';
import useSelectTypo from '../../hooks/useSelectTypo';
import IFrame from '../../components/IFrame';
import FlexGrow from '../../components/FlexGrow';
import StatsTypos from './Typos';

export const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Inscriptions',
    },
  },
};

export default function Stats() {
  const [version, setVersion] = useState(localStorage.getItem('stats_version') || '2');

  const [SelectEvent, { event: eventB }] = useSelectEvent();
  const [SelectIsExhibitor, { isExhibitor }] = useSelectIsExhibitor();
  const [SelectTypo, { typo }] = useSelectTypo();

  return (
    <FlexGrow>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 8 }}>
        <Button
          type="link"
          onClick={() => {
            const newVersion = version === '1' ? '2' : '1';
            setVersion(newVersion);
            localStorage.setItem('stats_version', newVersion);
          }}
        >
          {version === '1' ? 'Revenir sur la nouvelle version' : "Utiliser l'ancienne version"}
        </Button>
      </div>

      {version === '2' && (
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: 16 }}>
          <StatsTypos />
          <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
            <SelectEvent placeholder="Comparer avec" style={{ width: 250 }} allowClear />
            <SelectIsExhibitor placeholder="Type de l'utilisateur" style={{ width: 250 }} />
            <SelectTypo placeholder="Typologie" style={{ width: 250 }} />
          </div>
          <Inscriptions eventB={eventB} isExhibitor={isExhibitor} typo={typo} />
          <Presences eventB={eventB} isExhibitor={isExhibitor} typo={typo} />
        </div>
      )}

      {version === '1' && <IFrame path="/stats" />}
    </FlexGrow>
  );
}
