import { Button } from 'antd';
import React, { useState } from 'react';
import FlexGrow from '../../components/FlexGrow';
import IFrame from '../../components/IFrame';
import ArticleCodesPage from './v2';
import { useMyContext } from '../../hooks/useMyContext';

export default function CodesPage() {
  const { myContext } = useMyContext();
  const [version, setVersion] = useState(myContext?.domainId === 192 ? localStorage.getItem('codes_version') || '2' : '2');

  return (
    <>
      {myContext?.domainId === 192 && (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 8 }}>
          <Button
            type="link"
            onClick={() => {
              const newVersion = version === '1' ? '2' : '1';
              setVersion(newVersion);
              localStorage.setItem('codes_version', newVersion);
            }}
          >
            {version === '1' ? 'Revenir sur la nouvelle version' : "Utiliser l'ancienne version"}
          </Button>
        </div>
      )}

      {version === '1' && <FlexGrow><IFrame path="/promo-codes" /></FlexGrow>}
      {version === '2' && (
        <ArticleCodesPage />
      )}
    </>
  );
}
