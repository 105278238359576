import apiV2 from '.';

export const freeArticlesApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    createFreeArticles: builder.mutation({
      query: ({ domainId, eventId, userId, body }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/free-articles`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FREE_ARTICLES', 'ARTICLES'],
    }),
    updateFreeArticles: builder.mutation({
      query: ({ domainId, eventId, userId, id, body }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/free-articles/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['FREE_ARTICLES', 'ARTICLES'],
    }),
    removeFreeArticles: builder.mutation({
      query: ({ domainId, eventId, userId, id, body }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/free-articles/${id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['FREE_ARTICLES', 'ARTICLES'],
    }),
    getFreeArticles: builder.query({
      query: ({ domainId, eventId, userId }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/free-articles`,
      }),
      transformResponse: (response) => response.data.freeArticles,
      providesTags: ['FREE_ARTICLES', 'ARTICLES'],
    }),
  }),
});

export const {
  useCreateFreeArticlesMutation,
  useUpdateFreeArticlesMutation,
  useRemoveFreeArticlesMutation,
  useGetFreeArticlesQuery,
} = freeArticlesApi;
