import { Button, Form, Modal, Switch } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMyContext } from '../../../hooks/useMyContext';
import { useRemoveParticipationMutation } from '../../../api/v2/participations';
import useRights from '../../../hooks/useRights';
import UserEventTypologie from './Typologie';

export default function UserEventRemove() {
  const [open, setOpen] = useState(false);

  const { myContext } = useMyContext();
  const { userId } = useParams();
  const { hasRight } = useRights();

  const [remove, { isLoading }] = useRemoveParticipationMutation();

  return (
    <>
      <Modal open={open} footer={null} title="Voulez vous vraiment supprimer cette participation ?" onCancel={() => setOpen(false)}>
        <Form onFinish={(body) => remove({ ...myContext, userId, body })}>
          <Form.Item
            name="removeCompanions"
            label="Supprimer la participation de ses accompagnants"
            valuePropName="checked"
            initialValue={false}
          >
            <Switch />
          </Form.Item>
          <div style={{ display: 'flex', gap: 8 }}>
            <Button onClick={() => setOpen(false)} loading={isLoading}>Non</Button>
            <Button style={{ flexGrow: 1 }} type="primary" htmlType="submit" loading={isLoading}>Oui</Button>
          </div>
        </Form>
      </Modal>
      <div style={{ display: 'flex', gap: 8 }}>
        {hasRight('TYPO') && <UserEventTypologie />}
        <Button onClick={() => setOpen(true)} danger>Désactiver</Button>
      </div>
    </>
  );
}
