import { Checkbox } from 'antd';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetParticipationQuery, useUpdateParticipationMutation } from '../../../api/v2/participations';
import Flex from '../../../components/Flex';
import SearchUsers from '../../../components/SearchUsers';
import SendEmail, { boEmails } from '../../../components/SendEmail';
import { useMyContext } from '../../../hooks/useMyContext';

const formatDateForMySQL = (date) => {
  if (!date) return null;
  return date.toISOString().slice(0, 19).replace('T', ' ');
};

export default function UserEventParticipation() {
  const { myContext, event } = useMyContext();
  const { userId } = useParams();

  const { data: participation } = useGetParticipationQuery({ ...myContext, userId });
  const [update] = useUpdateParticipationMutation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Flex>
        {event?.days.map((m) => (
          <Checkbox
            checked={participation?.days?.[m.id]?.registered}
            onChange={(e) => update({
              ...myContext,
              userId,
              participation: {},
              days: [{
                eventDayId: m.id,
                registered: e.target.checked,
                registeredAt: e.target.checked ? formatDateForMySQL(new Date()) : null,
              }],
            })}
          >
            {format(new Date(m.day), 'dd/MM/yyyy', { locale: fr })}
          </Checkbox>
        ))}
      </Flex>
      <Flex style={{ alignItems: 'center' }}>
        <div>Inscrit par : </div>
        <SearchUsers
          defaultSearch={`${participation?.registeredBy?.firstName || ''} ${participation?.registeredBy?.lastName || ''}`}
          onSelect={(registeredBy2) => update({ ...myContext, userId, participation: { registeredBy2 } })}
        />
      </Flex>
      <div style={{ display: 'flex', gap: 8 }}>
        <SendEmail email={boEmails.EMAILS_SEND_PARTICIPATION} />
      </div>
    </div>
  );
}
