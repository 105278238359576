import { Button, Modal, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useShowInvoiceMutation } from '../../../../api/v2/invoices';
import { useLazyGetUserQuery } from '../../../../api/v2/users';
import { useMyContext } from '../../../../hooks/useMyContext';

export default function ShowInvoice({ invoice, children }) {
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState('FR');

  const { myContext } = useMyContext();

  const [show, { isLoading: isShowing, data }] = useShowInvoiceMutation();
  const [fetchUser, { data: user }] = useLazyGetUserQuery();

  useEffect(() => {
    if (user?.language) {
      setLanguage(user.language);
    }
  }, [user]);

  useEffect(() => {
    if (open && language) {
      show({ ...myContext, userId: invoice.userId, invoiceId: invoice.id, language });
    }
  }, [open, language]);

  useEffect(() => {
    if (open) {
      fetchUser({ ...myContext, userId: invoice.userId });
    }
  }, [open]);

  return (
    <>
      {React.cloneElement(children, { onClick: () => setOpen(true) })}
      <Modal
        title={invoice.label}
        open={open}
        onCancel={() => setOpen(false)}
        width={2000}
        footer={null}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <Select
            value={language}
            onChange={setLanguage}
            options={[{ label: 'English', value: 'EN' }, { label: 'Français', value: 'FR' }]}
          />
          {data && (
            <iframe
              src={data}
              title="PDF"
              width="100%"
              style={{ height: 'calc(100vh - 300px)', border: 'none' }}
            />
          )}
          {isShowing && <Spin />}
          <Button style={{ width: '100%' }} type="primary" onClick={() => setOpen(false)}>Fermer</Button>
        </div>
      </Modal>
    </>
  );
}
