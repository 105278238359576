import { Form, Table } from 'antd';
import React from 'react';
import { useGetTyposQuery } from '../../../api/v2/typo';
import { useMyContext } from '../../../hooks/useMyContext';
import FormItemEmail from './components/FormItemEmail';

export default function MeetingTypos() {
  const { myContext } = useMyContext();

  const { data: typos } = useGetTyposQuery(myContext);

  return (
    <Form.Item>
      <Table
        size="small"
        scroll={{ x: true }}
        columns={[
          { title: '', dataIndex: 'label' },
          ...(typos ? typos.map((m) => ({ title: m.label, render: (v) => v.render(m.id) })) : []),
        ]}
        dataSource={[
          {
            label: 'Email de confirmation',
            render: (typoId) => (
              <FormItemEmail
                name={typoId
                  ? ['typosV2', `${typoId}`, 'FO_MEETING_EMAIL_CONFIRMATION']
                  : 'FO_MEETING_EMAIL_CONFIRMATION'}
                noStyle
              />
            ),
          },
        ]}
        pagination={false}
        bordered
      />
    </Form.Item>
  );
}
