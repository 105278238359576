import { useMyContext } from './useMyContext';

const useSettingsBo = () => {
  const { domain, event } = useMyContext();

  const settings = domain?.extended || {};

  return {
    ...settings,
    primaryColor: (event && settings?.[`EVENT_${event.id}_COLOR`]) || settings.SIDEBAR_ITEM_SELECTED_BACKGROUND_COLOR_V2 || '#1677ff',
    navbarColor: (event && settings?.[`EVENT_${event.id}_COLOR`]) || settings?.SIDEBAR_BACKGROUND_COLOR_V2 || '#001529',
    isMultiLanguage: event && settings?.[`EVENT_${event.id}_IS_MULTI_LANGUAGE`],
    hasFreeArticles: event && settings?.[`EVENT_${event.id}_HAS_FREE_ARTICLES`],
    withoutTva: event && settings?.[`EVENT_${event.id}_WITHOUT_TVA`],
    facturationGayakoa: event && settings?.[`EVENT_${event.id}_FACTURATION_GAYAKOA`],
    facturationOthers: event && settings?.[`EVENT_${event.id}_FACTURATION_OTHERS`],
    companions: event && settings?.[`EVENT_${event.id}_COMPANIONS`],
  };
};

export default useSettingsBo;
