import { useMemo } from 'react';
import { useGetTemplatesQuery } from '../api/v2/emails';
import { useMyContext } from './useMyContext';

export default function useTemplates(language) {
  const { myContext } = useMyContext();

  const { data } = useGetTemplatesQuery(myContext);

  const templates = useMemo(() => data?.filter((f) => f.key === language));

  return { templates };
}
