import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  Select,
  Table,
} from 'antd';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import {
  useGetSpotsQuery, useRemoveSpotMutation,
} from '../../api/v2/spots';
import { useMyContext } from '../../hooks/useMyContext';
import SpotsEdition from './Edition';
import ImportSpots from './Import';

export default function Spots() {
  const [edition, setEdition] = useState(null);
  const [startAt, setStartAt] = useState();
  const [category, setCategory] = useState();

  const { myContext, event } = useMyContext();

  const [remove] = useRemoveSpotMutation();
  const { data } = useGetSpotsQuery({ ...myContext, all: true, startAt, category });

  if (edition) {
    return <SpotsEdition edition={edition} setEdition={setEdition} />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <ImportSpots />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 16 }}>
        <Button type="primary" onClick={() => setEdition({ startAt: dayjs(event.start) })}>
          Ajouter un spot
        </Button>
        <div style={{ display: 'flex', gap: 8 }}>
          <Select placeholder="Date" options={data?.dates} style={{ width: 120 }} onChange={setStartAt} allowClear />
          <Select placeholder="Catégorie" options={data?.categories} style={{ width: 120 }} onChange={setCategory} allowClear />
        </div>
      </div>
      <Table
        dataSource={data?.spots}
        scroll={{ x: true }}
        columns={[
          { title: 'Label', dataIndex: 'label' },
          {
            title: 'Date',
            dataIndex: 'startAt',
            render: (v) => format(new Date(v), 'dd/MM/yyyy HH:mm'),
          },
          {
            title: 'Pour tout le monde',
            dataIndex: 'forAll',
            render: (v) => (v ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CheckCircleOutlined style={{ color: 'green' }} />
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CloseCircleOutlined style={{ color: 'red' }} />
              </div>
            )),
          },
          {
            title: 'Utilisateur',
            render: (v) => `${v.firstName || ''} ${v.lastName || ''}`,
          },
          { title: 'Sujet', dataIndex: 'parent' },
          { title: 'Option', dataIndex: 'child' },
          {
            render: (v) => (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                <Button type="primary" onClick={() => setEdition(v)} disabled={!v.id}>
                  Modifier
                </Button>
                <Button
                  onClick={() => {
                    Modal.confirm({
                      title: 'Voulez-vous vraiment supprimer ce spot ?',
                      icon: <ExclamationCircleOutlined />,
                      okText: 'Oui',
                      cancelText: 'Non',
                      async onOk() {
                        return remove({ ...myContext, spotId: v.id });
                      },
                    });
                  }}
                  disabled={!v.id}
                  danger
                >
                  Supprimer
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
