import React, { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { Select } from 'antd';
import { useStatsGetPresencesQuery } from '../../api/v2/stats';
import { useMyContext } from '../../hooks/useMyContext';

export const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Présences',
    },
  },
};

export default function Presences({ eventB, isExhibitor, typo }) {
  const [pat, setPat] = useState(15);

  const { myContext, event } = useMyContext();

  const { data } = useStatsGetPresencesQuery({
    ...myContext, eventIdB: eventB?.id, isExhibitor, typo, pat,
  });

  if (!data) {
    return null;
  }

  const { a, b, labels } = data;

  const datasets = [];
  if (a && event) {
    datasets.push(...[{
      type: 'line',
      label: event.name,
      data: a.total,
      backgroundColor: '#e67e22',
      borderColor: '#d35400',
    }, {
      type: 'bar',
      label: event.name,
      data: a.totalSum,
      backgroundColor: '#f1c40f',
      borderColor: '#f39c12',
    }]);
  }

  if (b && eventB) {
    datasets.push(...[{
      type: 'line',
      label: eventB.name,
      data: b.total,
      backgroundColor: '#3498db',
      borderColor: '#2980b9',
    }, {
      type: 'bar',
      label: eventB.name,
      data: b.totalSum,
      backgroundColor: '#1abc9c',
      borderColor: '#16a085',
    }]);
  }

  return (
    <>
      <div style={{ height: 350 }}>
        <Chart
          options={options}
          data={{ datasets, labels }}
        />
      </div>
      <Select
        style={{ width: 100 }}
        options={[
          { label: '15min', value: 15 },
          { label: '30min', value: 30 },
          { label: '45min', value: 45 },
          { label: '1h', value: 60 },
        ]}
        value={pat}
        onChange={setPat}
      />
    </>
  );
}
