import queryString from 'query-string';
import apiV2 from '.';

export const eventsApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    eventsGetByDomain: builder.query({
      query: ({ domainId, archive }) => `/domains/${domainId}/events?${queryString.stringify({ archive })}`,
      transformResponse: (response) => response.data.events,
      providesTags: ['EVENTS'],
    }),
    createEvent: builder.mutation({
      query: ({ domainId, body }) => ({
        url: `/domains/${domainId}/events`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['EVENTS'],
    }),
    updateEvent: builder.mutation({
      query: ({ domainId, eventId, body }) => ({
        url: `/domains/${domainId}/events/${eventId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['EVENTS'],
    }),
    removeEvent: builder.mutation({
      query: ({ domainId, eventId }) => ({
        url: `/domains/${domainId}/events/${eventId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EVENTS'],
    }),
  }),
});

export const {
  useLazyEventsGetByDomainQuery,
  useEventsGetByDomainQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useRemoveEventMutation,
} = eventsApi;
