import { Button } from 'antd';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetParticipationQuery, useUpdateParticipationMutation } from '../../../api/v2/participations';
import { useMyContext } from '../../../hooks/useMyContext';
import SendEmail, { boEmails } from '../../../components/SendEmail';

export default function UserEventInvitation() {
  const { myContext } = useMyContext();
  const { userId } = useParams();

  const { data: participation } = useGetParticipationQuery({ ...myContext, userId });
  const [update, { isLoading }] = useUpdateParticipationMutation();

  if (!participation?.invited) {
    return (
      <Button
        type="primary"
        onClick={() => update({ ...myContext, userId, participation: { invited: true, invitedAt: new Date() } })}
        loading={isLoading}
      >
        Marquer comme invité
      </Button>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div>
        {`Invité le ${format(new Date(participation.invitedAt), 'dd/MM/yyyy à HH:mm', { locale: fr })}`}
      </div>
      <div style={{ display: 'flex', gap: 8 }}>
        <SendEmail email={boEmails.EMAILS_SEND_INVITATION} />
        <Button
          type="primary"
          onClick={() => update({ ...myContext, userId, participation: { invited: false, invitedAt: null } })}
          loading={isLoading}
          danger
        >
          Supprimer cette invitation
        </Button>
      </div>
    </div>
  );
}
