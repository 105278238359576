import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useUsers } from '../useUsers';
import FilterEmpty from './Empty';

const filterById = (id, array) => array.reduce((prev, curr) => {
  if (Array.isArray(curr)) {
    return [...prev, filterById(id, curr)];
  }
  if (curr.id !== id) {
    return [...prev, curr];
  }
  return prev;
}, []);

export default function FilterSelect({ m, setFilters }) {
  const [selectValue, setSelectValue] = useState(null);

  const { currentSettings } = useUsers();

  const id = `select-values-${m.model}.${m.property}-${m.extended}`;

  useEffect(() => {
    if (currentSettings) {
      const findSelect = currentSettings.usersFilters.flat().find((f) => f.id === id);
      setSelectValue(findSelect?.v || null);
    }
  }, [currentSettings]);

  return (
    <>
      <Select
        value={selectValue}
        allowClear
        popupMatchSelectWidth={false}
        size="small"
        options={m.values}
        mode="multiple"
        onChange={(values) => setFilters((prev) => {
          setSelectValue(values);

          const filters = values.map((value) => ({
            id,
            operator: '=',
            value: 'TRUE',
            v: value,
            property: `${m.model}.${m.property}`,
            extended: `$."${m.extended}"."${value}"`,
            condition: 'OR',
          }));

          return [...filterById(id, prev), filters];
        })}
      />
      <FilterEmpty m={m} setFilters={setFilters} />
    </>
  );
}
