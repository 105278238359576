import { Table } from 'antd';
import { format } from 'date-fns';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetEmailLogsQuery } from '../../../../api/v2/emails';
import { useMyContext } from '../../../../hooks/useMyContext';

export default function UserEmailLogs() {
  const { myContext } = useMyContext();
  const { userId } = useParams();

  const { data: emailLogs, isFetching } = useGetEmailLogsQuery({ ...myContext, userId });

  return (
    <div>
      <Table
        scroll={{ x: true }}
        loading={isFetching}
        dataSource={emailLogs}
        columns={[
          {
            title: 'Email',
            render: (v) => v.label || v.name,
          },
          {
            title: 'Date',
            dataIndex: 'createdAt',
            render: (v) => `${format(new Date(v), 'dd/MM/yyyy HH:mm')}`,
          },
        ]}
      />
    </div>
  );
}
