import { Alert, Button, Checkbox, Form, Modal, Select, Table } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { read, utils } from 'xlsx';
import { useImportArticlesMutation } from '../../api/v2/articles';
import { useMyContext } from '../../hooks/useMyContext';

export default function ArticlesImport() {
  const [headers, setHeaders] = useState([]);
  const [raw, setRaw] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [values, setValues] = useState();

  const { myContext } = useMyContext();
  const ref = useRef();

  const [importArticles, { isLoading }] = useImportArticlesMutation();

  const handleFileUpload = (event) => {
    setIsSuccess(false);
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: 'buffer', codepage: '65001' });
      const sheetName = workbook.SheetNames[0];
      const array = utils.sheet_to_json(workbook.Sheets[sheetName], { defval: '', header: 1 });
      setHeaders(array[0]);
      setRaw(array);
    };

    reader.readAsArrayBuffer(file);
  };

  const reset = () => {
    setHeaders([]);
    setRaw([]);
    ref.current.value = '';
  };

  const headerOptions = useMemo(() => headers.map((m, i) => ({ label: m, value: i })), [headers]);

  const properties = useMemo(() => (values
    ? Object.entries(values).filter(([k, vv]) => vv !== undefined && k !== 'skipHeader')
    : []), [values]);

  const columns = useMemo(() => properties.map(([k]) => ({ title: k, dataIndex: k })), [properties]);

  const articles = useMemo(() => {
    if (properties.length > 0) {
      return raw
        .filter((_, i) => (values.skipHeader ? i !== 0 : true))
        .map((a) => properties.reduce((prev, [k, v]) => ({ ...prev, [k]: a[v] }), {}));
    }
    return [];
  }, [properties]);

  return (
    <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
      <div>
        <input
          ref={ref}
          type="file"
          accept=".xlsx, .xls, .csv"
          onChange={handleFileUpload}
          value={undefined}
          hidden
        />
        <Button type="primary" onClick={() => ref.current?.click()}>
          Importer des articles
        </Button>
      </div>

      <Modal
        title="Importer des articles"
        open={headers?.length > 0}
        footer={null}
        onCancel={() => reset()}
      >
        <Form onValuesChange={(__, v) => setValues(v)}>
          <Form.Item label="Ne pas garder la première ligne (header)" name="skipHeader" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Form.Item label="Choisir la colonne du nom de l'article" name="label" rules={[{ required: true }]}>
            <Select options={headerOptions} />
          </Form.Item>
          <Form.Item label="Choisir la colonne du nom de l'article en Anglais" name="labelEN" rules={[{ required: true }]}>
            <Select options={headerOptions} />
          </Form.Item>
          <Form.Item label="Choisir la colonne du nom de l'article sur la facture" name="label2">
            <Select options={headerOptions} allowClear />
          </Form.Item>
          <Form.Item label="Choisir la colonne du code de l'article" name="code">
            <Select options={headerOptions} allowClear />
          </Form.Item>
          <Form.Item label="Choisir la colonne du prix H.T. de l'article" name="price">
            <Select options={headerOptions} allowClear />
          </Form.Item>
          <Form.Item label="Choisir la colonne du prix T.T.C de l'article" name="priceTTC">
            <Select options={headerOptions} allowClear />
          </Form.Item>
          <Form.Item label="Choisir la colonne de la TVA de l'article" name="tva">
            <Select options={headerOptions} allowClear />
          </Form.Item>
        </Form>

        {articles.length > 0 && (
          <>
            <Table
              scroll={{ x: true }}
              columns={columns}
              dataSource={articles}
              pagination={{ pageSize: 5 }}
            />
            <Alert
              description={`Voulez-vous importer ${articles.length} articles ?`}
              type="info"
              action={(
                <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
                  <Button
                    type="primary"
                    onClick={async () => {
                      try {
                        await importArticles({ ...myContext, articles }).unwrap();
                        setIsSuccess(true);
                        reset();
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                    loading={isLoading}
                  >
                    Oui
                  </Button>
                  <Button onClick={() => reset()} loading={isLoading} danger ghost>
                    Non
                  </Button>
                </div>
              )}
            />
          </>
        )}
      </Modal>

      {/* {isSuccess && <Alert showIcon message="Import terminé" type="success" />} */}

      {/* {headers && window.location.hostname === 'localhost' && (
        <div>
          <h2>Contenu du fichier Excel</h2>
          <pre>{JSON.stringify(headers, null, 2)}</pre>
        </div>
      )} */}
    </div>
  );
}
