import apiV2 from '.';

export const filesApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    filesCreateUrl: builder.mutation({
      query: ({ contentType, isPublic }) => ({
        url: '/files',
        method: 'POST',
        body: { contentType, isPublic },
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const { useFilesCreateUrlMutation } = filesApi;
