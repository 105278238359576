import apiV2 from '.';

export const searchApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query({
      query: ({ domainId, eventId, search, onlyUsers, onlyCompanies }) => ({
        url: eventId ? `/domains/${domainId}/events/${eventId}/search` : `/domains/${domainId}/search`,
        method: 'POST',
        body: {
          search,
          onlyUsers,
          onlyCompanies,
        },
      }),
      transformResponse: (response) => response.data,
      providesTags: ['USERS', 'COMPANIES'],
    }),
  }),
});

export const {
  useLazySearchQuery,
} = searchApi;
