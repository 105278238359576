import React from 'react';

export default function Burger({ children, onClick }) {
  return (
    <div
      style={{
        height: 60,
        width: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={onClick}
      aria-hidden
    >
      {children}
    </div>
  );
}
