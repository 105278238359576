import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLazyDownloadInvoicesQuery } from '../../api/v2/invoices';
import { useMyContext } from '../../hooks/useMyContext';

export default function ExportInvoices({ isImport, isCredit, isGayakoa, disabled }) {
  const [open, setOpen] = useState(false);

  const { myContext } = useMyContext();

  const [download, { data: files, isFetching }] = useLazyDownloadInvoicesQuery();

  useEffect(() => {
    if (open) {
      download({ ...myContext, isImport, isCredit, isGayakoa });
    }
  }, [open]);

  return (
    <>
      <Button type="primary" onClick={() => setOpen(true)} disabled={disabled}>
        {isCredit ? 'Exporter tous les avoirs' : 'Exporter toutes les factures'}
      </Button>
      <Modal
        open={open}
        title={isCredit ? 'Exporter tous les avoirs' : 'Exporter toutes les factures'}
        footer={null}
        onCancel={() => setOpen(false)}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          {!files && (
            <Button
              loading={isFetching}
              onClick={() => setOpen(false)}
              type="primary"
            >
              {isFetching ? 'Préparation du fichier ...' : 'Télécharger le fichier'}
            </Button>
          )}
          {files && files.map((fileUrl, i) => (
            <Button
              loading={isFetching}
              href={fileUrl}
              target="_blank"
              type="primary"
            >
              {isFetching ? 'Préparation du fichier ...' : `Télécharger la partie n°${i + 1}`}
            </Button>
          ))}
        </div>
      </Modal>
    </>
  );
}
