/* eslint-disable react/no-unstable-nested-components */
import { Button, ColorPicker } from 'antd';
import React from 'react';

export default function MyColorPicker({ onChange, value, defaultValue }) {
  return (
    <ColorPicker
      defaultValue={defaultValue}
      panelRender={(panel) => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Button type="primary" onClick={() => onChange(defaultValue || null)}>
            Valeur par défaut
          </Button>
          {panel}
        </div>
      )}
      value={value}
      onChange={(v) => {
        onChange(v.toHexString());
      }}
      showText
    />
  );
}
