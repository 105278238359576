import { Button, Form, InputNumber, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useGetArticlesQuery } from '../../../../api/v2/articles';
import { useCreateInvoiceMutation, useGetInvoicesQuery } from '../../../../api/v2/invoices';
import { useMyContext } from '../../../../hooks/useMyContext';

export default function UserInvoicesCreateCredit({ values, label, onTabs }) {
  const [show, setShow] = useState(false);

  const { myContext } = useMyContext();
  const { userId } = useParams();
  const [form] = Form.useForm();

  const { data } = useGetArticlesQuery({ ...myContext });
  const { data: invoices } = useGetInvoicesQuery({ ...myContext, userId });
  const [create, { isLoading }] = useCreateInvoiceMutation();

  const reset = () => {
    setShow(false);
    form.resetFields();
  };

  useEffect(() => {
    if (!show) {
      return;
    }

    if (values) {
      form.setFieldsValue(values);
    }
  }, [values, show]);

  return (
    <div>
      <Button type="primary" onClick={() => setShow(true)} ghost={!!values}>{label}</Button>
      <Modal title="Créer un avoir" open={show} onCancel={() => reset()} width={1000} footer={null}>
        <Form
          form={form}
          layout="vertical"
          onFinish={async (invoice) => {
            const invoiceId = form.getFieldValue(['bill', 'invoiceId']);
            const invoiceNew = structuredClone(invoices?.find((f) => f.id === invoiceId));

            invoiceNew.isCredit = true;
            invoiceNew.id = values?.id;
            invoiceNew.bill.articles = invoice.bill.articles;
            invoiceNew.bill.invoiceId = invoice.bill.invoiceId;
            invoiceNew.bill.date = format(new Date(), 'dd/MM/yyyy');

            await create({ ...myContext, userId, invoice: { withTva: 1, ...invoiceNew } }).unwrap();
            reset();
            if (onTabs) {
              onTabs('credits');
            }
          }}
          onValuesChange={(prevValues) => {
            if (prevValues?.bill?.invoiceId) {
              const { invoiceId } = prevValues.bill;
              const invoice = invoices?.find((f) => f.id === invoiceId);
              form.setFieldsValue({ bill: { articles: invoice.bill.articles } });
            }
          }}
        >
          <Form.Item label="Choisir la facture" name={['bill', 'invoiceId']} rules={[{ required: true }]}>
            <Select
              options={invoices?.filter((f) => !f.isDraft && !f.isCredit).map((m) => ({ label: m.label, value: m.id }))}
              disabled={values}
            />
          </Form.Item>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) => {
              const invoiceId = getFieldValue(['bill', 'invoiceId']);
              const invoice = invoices?.find((f) => f.id === invoiceId);

              return invoice?.bill?.articles?.map((a, i) => (
                <div style={{ display: 'flex', gap: 16, alignItems: 'flex-end' }}>
                  <Form.Item
                    label="Choisir un article"
                    name={['bill', 'articles', i, 'code']}
                    style={{ flexGrow: 1 }}
                    rules={[{ required: true }]}
                    value={a.code}
                  >
                    <Select
                      options={data?.map((m) => ({ label: `[${m.code}] - ${m.label}`, value: m.code }))}
                      popupMatchSelectWidth={false}
                      filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                      showSearch
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    label="Quantité"
                    name={['bill', 'articles', i, 'quantity']}
                    rules={[{ required: true }]}
                    initialValue={a.quantity}
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                </div>
              ));
            }}
          </Form.Item>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 8, alignItems: 'flex-end' }}>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const articles = getFieldValue(['bill', 'articles']);
                const total = articles
                  ?.map((m) => ({ ...m, ...data.find((f) => f.code === m.code) }))
                  .reduce((prev, curr) => prev + curr.priceTTC * curr.quantity, 0) || 0;
                return <div style={{ fontWeight: 600, fontSize: 20 }}>{`Total TTC : ${total.toFixed(2)} €`}</div>;
              }}
            </Form.Item>
            <Button style={{ width: '100%' }} type="primary" htmlType="submit" loading={isLoading}>
              Enregistrer
            </Button>
            <Button style={{ width: '100%' }} type="primary" loading={isLoading} onClick={() => reset()} ghost>
              Annuler
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
