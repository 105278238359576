/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {
  Pagination,
  Table,
  Tag,
} from 'antd';
import { format } from 'date-fns';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import ReactDragListView from 'react-drag-listview';
import { useLazyGetUsersQuery } from '../../api/v2/users';
import { useLayout } from '../../hooks/useLayout';
import { useMyContext } from '../../hooks/useMyContext';
import useNav from '../../hooks/useNav';
import FilterBoolean from './filters/Boolean';
import FilterSelect from './filters/Select';
import FilterString from './filters/String';
import UsersSettings from './settings';
import { useUsers } from './useUsers';
import UsersSettingsV2 from './settingsV2';

export default function UsersTable() {
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(1);

  const { myContext } = useMyContext();
  const { navbarHeight, padding } = useLayout();
  const { usersHeaders, usersFilters, setUsersFilters, setUsersHeaders } = useUsers();
  const requestRef = useRef(null);
  const nav = useNav();

  const [fetchUsers, { data, isFetching }] = useLazyGetUsersQuery();

  useEffect(() => {
    if (requestRef.current) {
      requestRef.current.abort();
    }

    requestRef.current = fetchUsers({
      ...myContext,
      page,
      pageSize,
      filters: usersFilters,
      headers: usersHeaders,
    });
  }, [page, pageSize, usersFilters, usersHeaders]);

  const getColumns = (array) => array
    .map((m) => ({
      title: () => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <div>{m.label}</div>
          {m.filter === 'string' && (
            <FilterString setFilters={setUsersFilters} m={m} />
          )}
          {(m.filter === 'select' || m.filter === 'radio' || m.filter === 'checkbox') && (
            <FilterSelect setFilters={setUsersFilters} m={m} />
          )}
          {m.filter === 'boolean' && (
            <FilterBoolean setFilters={setUsersFilters} m={m} />
          )}
        </div>
      ),
      dataIndex: m.id,
      ellipsis: true,
      render: (text) => {
        if (m.filter === 'boolean' && !text) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CloseCircleOutlined style={{ color: 'red' }} />
            </div>
          );
        }

        if (m.filter === 'boolean' && text) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CheckCircleOutlined style={{ color: 'green' }} />
            </div>
          );
        }

        if (/^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])T([01]\d|2[0-3]):[0-5]\d:[0-5]\d.\d{3}Z$/.test(text)) {
          return format(new Date(text), 'dd/MM/yyyy HH:mm');
        }

        return text;
      },
    }));

  const columns = useMemo(() => (data ? getColumns(data.columns) : []), [data]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${navbarHeight}px - ${padding * 2}px)`,
        alignItems: 'center',
        gap: 8,
      }}
    >
      <UsersSettingsV2 data={data} />
      {/* <UsersSettings data={data} /> */}
      <Tag style={{ alignSelf: 'flex-start' }} color="processing">{`Nombre d'utilisateurs : ${data?.count || 0}`}</Tag>
      <div style={{ overflow: 'auto', width: '100%' }}>
        <ReactDragListView.DragColumn
          nodeSelector="thead tr:nth-child(1) th"
          onDragEnd={(from, to) => {
            const copy = [...usersHeaders];
            const elementDeplace = copy.splice(from, 1)[0];
            copy.splice(to, 0, elementDeplace);
            setUsersHeaders(copy);
          }}
        >
          <Table
            rowKey="U.id"
            scroll={{ y: true, x: true }}
            columns={columns}
            loading={isFetching}
            dataSource={data?.users}
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                nav(`/users/${record.id}`, { newTab: true });
              },
              style: { cursor: 'pointer' },
            })}
            sticky
            bordered
          />
        </ReactDragListView.DragColumn>
      </div>
      <br />
      <Pagination
        current={page}
        total={data?.count}
        pageSize={pageSize}
        onChange={(p, pz) => {
          setPage(p);
          setPageSize(pz);
        }}
      />
    </div>
  );
}
