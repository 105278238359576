import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import useMobile from './useMobile';
import { useMyContext } from './useMyContext';

const navbarHeight = 60;
const sidebarWidth = 280;
const padding = 8;

const LayoutContext = createContext();

export function LayoutProvider({ children }) {
  const [visible, setVisible] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const { isMobile } = useMobile();
  const { myContext, isSuccess } = useMyContext();
  const [, setSearch] = useSearchParams();

  useEffect(() => {
    if (isSuccess && myContext?.eventId) {
      setSearch({ domain: myContext.domainId, event: myContext.eventId });
    } else if (isSuccess && myContext?.domainId) {
      setSearch({ domain: myContext.domainId });
    }
  }, [myContext, isSuccess]);

  // useEffect(() => {
  //   const updateWidth = () => {
  //     setWidth(window.innerWidth);
  //   };

  //   updateWidth();

  //   window.addEventListener('resize', updateWidth);
  //   return () => {
  //     window.removeEventListener('resize', updateWidth);
  //   };
  // }, []);

  const show = useCallback(() => {
    setVisible(true);
  }, []);

  const hide = useCallback(() => {
    setVisible(false);
  }, []);

  const state = useMemo(() => ({
    visible,
    isMobile,
    show,
    hide,
    navbarHeight,
    sidebarWidth,
    padding,
    width,
  }), [visible, isMobile, show, hide, width]);

  return (
    <LayoutContext.Provider value={state}>
      {children}
    </LayoutContext.Provider>
  );
}

export const useLayout = () => useContext(LayoutContext);
