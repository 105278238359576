import React, { useState } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Button, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useMyContext } from '../../../hooks/useMyContext';
import { useGetSpotsQuery, useRemoveSpotMutation } from '../../../api/v2/spots';
import SpotsEdition from '../../spots/Edition';

export default function UserEventSpots() {
  const [edition, setEdition] = useState(null);

  const { myContext, event } = useMyContext();
  const { userId } = useParams();

  const { data } = useGetSpotsQuery({ ...myContext, userId });
  const [remove] = useRemoveSpotMutation();

  if (edition) {
    return <SpotsEdition edition={edition} setEdition={setEdition} />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      {data?.spots.map(({ label, values }) => (
        <div>
          <div
            style={{
              fontSize: 16,
              textTransform: 'capitalize',
              fontWeight: 600,
            }}
          >
            {format(new Date(label), 'EEEE dd MMMM', { locale: fr })}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            {values.map((v) => (
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <Button
                  onClick={() => {
                    Modal.confirm({
                      title: 'Voulez-vous vraiment supprimer ce spot ?',
                      icon: <ExclamationCircleOutlined />,
                      okText: 'Oui',
                      cancelText: 'Non',
                      async onOk() {
                        return remove({ ...myContext, spotId: v.id });
                      },
                    });
                  }}
                  size="small"
                  icon={<DeleteOutlined />}
                  type="primary"
                  disabled={!v.userId}
                  danger
                />
                <div>{v.label}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <Button type="primary" onClick={() => setEdition({ startAt: dayjs(event.start), userId })}>
        Ajouter un spot
      </Button>
    </div>
  );
}
