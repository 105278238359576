import { UserAddOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import { useCreateUserMutation } from '../../api/v2/users';
import { useMyContext } from '../../hooks/useMyContext';
import useNav from '../../hooks/useNav';

export default function CreateUser() {
  const [modal, setModal] = useState(false);

  const { myContext } = useMyContext();
  const nav = useNav();
  const [form] = Form.useForm();

  const [create, { isFetching }] = useCreateUserMutation();

  const createUser = useCallback(async (values) => {
    const { data } = await create({ ...myContext, body: values }).unwrap();
    if (data?.user) {
      nav(`/users/${data?.user.id}`);
    }
    form.resetFields();
    setModal(false);
  }, [myContext]);

  return (
    <>
      <Button onClick={() => setModal(true)} icon={<UserAddOutlined style={{ fontSize: 22 }} />} />
      {modal && (
        <Modal
          title="Créer un utilisateur"
          footer={(
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={() => setModal(false)}>Annuler</Button>
              <Button type="primary" onClick={() => form.submit()} loading={isFetching}>Créer</Button>
            </div>
          )}
          open={modal}
          onCancel={() => setModal(false)}
        >
          <Form onFinish={createUser} form={form}>
            <Form.Item label="Prénom" name={['user', 'firstName']} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Nom" name={['user', 'lastName']} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Email" name={['auth', 'email']}>
              <Input type="email" />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
}
