import React from 'react';
import {
  BrowserRouter, Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import Layout from './components/Layout';
import { useAuth } from './hooks/useAuth';
import { MyContextProvider } from './hooks/useMyContext';
import Login from './pages/login';
import { routes, routesHidden } from './routes';

function AppRouter() {
  const { isAuth, auth } = useAuth();

  if (!isAuth) {
    return <Login />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MyContextProvider><Layout /></MyContextProvider>}>
          <Route path="*" element={<Navigate to="/stats" />} />
          {[...routes, ...routesHidden]
            .reduce((prev, curr) => {
              if (curr.children) {
                return [...prev, ...curr.children];
              }
              return [...prev, curr];
            }, [])
            .filter((f) => (f.forAdmin && auth ? auth.isAdmin : true))
            .map((m) => <Route path={m.path} element={m.component} />)}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
