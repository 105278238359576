/* eslint-disable import/no-cycle */
import {
  Button,
  Form,
  Input,
  Tree,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { useCreateRoleMutation, useUpdateRoleMutation } from '../../api/v2/roles';
import { useMyContext } from '../../hooks/useMyContext';
import { routes } from '../../routes';

const rights = [
  {
    title: 'Configuration utilisateurs',
    key: 'USERS_SETTINGS',
  },
];

export default function RightsEdition({ edition, setEdition }) {
  const [roles, setRoles] = useState(edition?.rights?.filter((f) => f !== 'PAGES') || []);

  const { myContext } = useMyContext();
  const [form] = useForm();

  const [create] = useCreateRoleMutation();
  const [update] = useUpdateRoleMutation();

  const isCreation = !edition?.id;

  useEffect(() => {
    if (edition?.rights) {
      form.setFieldsValue({ ...edition, rights: edition.rights.filter((f) => f !== 'PAGES') });
    }
  }, [edition, form]);

  const routesFlat = routes
    .map((m) => [m, ...(m.children?.map((mm) => mm) || [])])
    .flat()
    .filter((f) => f.value)
    .map((m) => ({ title: m.label2 || m.label, key: m.value }));

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div>
        <Button type="primary" onClick={() => setEdition(null)} style={{ width: '100%' }}>
          Retour
        </Button>
      </div>
      <Form
        layout="vertical"
        form={form}
        onFinish={async (values) => {
          const role = { ...values, rights: roles.filter((f) => !['PAGES', 'FEATURES'].includes(f)) };
          if (isCreation) {
            await create({ ...myContext, role }).unwrap();
          } else {
            await update({ ...myContext, role, roleId: edition.id }).unwrap();
          }
          setEdition(null);
        }}
      >
        <Form.Item label="Label" name="label" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Tree
          checkable
          defaultExpandedKeys={['PAGES', 'FEATURES']}
          checkedKeys={roles}
          onCheck={setRoles}
          treeData={[
            {
              title: 'Pages',
              key: 'PAGES',
              children: routesFlat,
            },
            {
              title: 'Fonctionnalités',
              key: 'FEATURES',
              children: rights,
            },
          ]}
        />

        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            {isCreation ? 'Créer' : 'Modifier'}
          </Button>
        </div>
      </Form>
    </div>
  );
}
