import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Table,
} from 'antd';
import React, { useState } from 'react';
import {
  useCopyTypoMutation,
  useCreateTypoMutation,
  useGetTyposQuery,
  useRemoveTypoMutation,
  useUpdateTypoMutation,
} from '../../api/v2/typo';
import { useMyContext } from '../../hooks/useMyContext';

function Typos() {
  const [edition, setEdition] = useState(null);

  const { myContext } = useMyContext();

  const [create] = useCreateTypoMutation();
  const [update] = useUpdateTypoMutation();
  const [remove] = useRemoveTypoMutation();
  const [copy] = useCopyTypoMutation();
  const { data: typos } = useGetTyposQuery(myContext);
  const { data: typosByEvent } = useGetTyposQuery({ ...myContext, byEvent: true });

  if (edition) {
    const isCreation = !edition?.id;

    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <div>
          <Button type="primary" onClick={() => setEdition(null)}>
            Retour
          </Button>
        </div>
        <Form
          initialValues={edition}
          onFinish={async (typo) => {
            if (isCreation) {
              await create({ ...myContext, typo }).unwrap();
            } else {
              await update({ ...myContext, typo, typoId: edition.id }).unwrap();
            }
            setEdition(null);
          }}
        >
          <Form.Item label="Label" name="label" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="primary" htmlType="submit">
              {isCreation ? 'Créer' : 'Modifier'}
            </Button>
          </div>
        </Form>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div style={{
        display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 8,
      }}
      >
        <Button type="primary" onClick={() => setEdition({})}>
          Ajouter une typologie
        </Button>
        <Select
          mode="multiple"
          style={{ width: 230, maxWidth: '100%' }}
          placeholder="Importer une typo existante"
          options={typosByEvent}
          value={[]}
          onChange={(v) => copy({ ...myContext, typoId: v })}
        />
      </div>
      <Table
        dataSource={typos}
        scroll={{ x: true }}
        columns={[
          { title: 'Label', dataIndex: 'label' },
          {
            render: (v) => (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                <Button type="primary" onClick={() => setEdition(v)} disabled={!v.id}>
                  Modifier
                </Button>
                <Button
                  onClick={() => {
                    if (v.users > 0) {
                      Modal.confirm({
                        title: `Voulez-vous vraiment supprimer cette typologie alors quelle est utilisée par ${v.users} utilisateur(s) ?`,
                        icon: <ExclamationCircleOutlined />,
                        okText: 'Oui',
                        cancelText: 'Non',
                        async onOk() {
                          return remove({ ...myContext, typoId: v.id });
                        },
                      });
                    } else {
                      remove({ ...myContext, typoId: v.id });
                    }
                  }}
                  disabled={!v.id}
                  danger
                >
                  Supprimer
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

export default Typos;
