import { Alert, Button, Divider, Form, Input, Select } from 'antd';
import React, { useEffect } from 'react';
import { useGetFoSettingsQuery, useUpdateFoSettingsMutation } from '../../../api/v2/fo';
import MyUploadImage from '../../../components/MyUploadImage';
import { useMyContext } from '../../../hooks/useMyContext';
import MyColorPicker from '../../../components/MyColorPicker';
import Wysiwyg from '../../../components/Wysiwyg';
import { useGetTemplatesQuery } from '../../../api/v2/emails';

function FormItemMulti({ children, label, name, rules }) {
  return (
    <>
      <Form.Item label={`[EN 🇺🇸] - ${label}`} name={`${name}_EN`} rules={rules || []}>
        {children}
      </Form.Item>
      <Form.Item label={`[FR 🇫🇷] - ${label}`} name={`${name}_FR`} rules={rules || []}>
        {children}
      </Form.Item>
    </>
  );
}

export default function SettingsExposium() {
  const { myContext } = useMyContext();
  const [form] = Form.useForm();

  const { data } = useGetFoSettingsQuery(myContext);
  const [update, { isLoading, isSuccess }] = useUpdateFoSettingsMutation();
  const { data: templates } = useGetTemplatesQuery(myContext);

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    } else {
      form.resetFields();
    }
  }, [data]);

  return (
    <div>

      <Form form={form} onFinish={(values) => update({ ...myContext, extended: values })}>
        <h1>Globales</h1>
        <FormItemMulti label="Image de l'en-tête" name="CONF_EXPO_HEADER_IMG" rules={[{ required: true }]}>
          <MyUploadImage />
        </FormItemMulti>
        <Divider />
        <FormItemMulti label="Nom de l'événement" name="CONF_EXPO_EVENT_LABEL" rules={[{ required: true }]}>
          <Input />
        </FormItemMulti>
        <Divider />
        <FormItemMulti label="Date de l'événement" name="CONF_EXPO_EVENT_DATE" rules={[{ required: true }]}>
          <Input />
        </FormItemMulti>
        <Divider />
        <FormItemMulti label="Email [Envoi des documents]" name="CONF_EXPO_EMAIL_TEMPLATE_ID">
          <Select options={templates?.map((m) => ({ label: m.name, value: m.id }))} />
        </FormItemMulti>

        <h1>Front Office</h1>
        <Form.Item label="Couleur primaire" name="CONF_EXPO_FO_PRIMARY_COLOR" rules={[{ required: true }]}>
          <MyColorPicker />
        </Form.Item>
        <Form.Item label="Couleur du fond (côté)" name="CONF_EXPO_FO_BACK_COLOR">
          <MyColorPicker />
        </Form.Item>
        <Divider />
        <FormItemMulti label="Image en pied de page" name="CONF_EXPO_FO_FOOTER_IMG">
          <MyUploadImage />
        </FormItemMulti>
        <Divider />
        <FormItemMulti label="Texte au dessus du contenu" name="CONF_EXPO_FO_TEXT_TOP">
          <Wysiwyg />
        </FormItemMulti>
        <Divider />
        <FormItemMulti label="Texte en dessous du contenu" name="CONF_EXPO_FO_TEXT_BOTTOM">
          <Wysiwyg />
        </FormItemMulti>
        <Divider />
        <FormItemMulti label="Texte pour les non connectés" name="CONF_EXPO_FO_TEXT_NOT_CONNECTED">
          <Wysiwyg />
        </FormItemMulti>

        <h1>Facture</h1>
        <Form.Item label="Préfixe du numéro de facture EXTERIEUR" name="CONF_EXPO_INVOICE_PREFIX_EXT" rules={[{ required: true }]}>
          <Input placeholder="2024/***123" />
        </Form.Item>
        <Form.Item label="Préfixe du numéro de facture GAYAKOA" name="CONF_EXPO_INVOICE_PREFIX" rules={[{ required: true }]}>
          <Input placeholder="2024/***123" />
        </Form.Item>
        <Divider />
        <FormItemMulti label="Mention bas de page n°1" name="CONF_EXPO_INVOICE_MENTION_A" rules={[{ required: true }]}>
          <Input.TextArea />
        </FormItemMulti>
        <Divider />
        <FormItemMulti label="Mention bas de page n°2" name="CONF_EXPO_INVOICE_MENTION_B" rules={[{ required: true }]}>
          <Input.TextArea />
        </FormItemMulti>
        <Divider />
        <FormItemMulti label="Texte en bas de page" name="CONF_EXPO_INVOICE_FOOTER_TEXT" rules={[{ required: true }]}>
          <Input.TextArea />
        </FormItemMulti>

        <h1>Avoir</h1>
        <Form.Item label="Préfixe du numéro de l'avoir" name="CONF_EXPO_CREDIT_PREFIX" rules={[{ required: true }]}>
          <Input placeholder="2024/***123" />
        </Form.Item>
        <Divider />
        <FormItemMulti label="Mention bas de page n°1" name="CONF_EXPO_CREDIT_MENTION_A" rules={[{ required: true }]}>
          <Input.TextArea />
        </FormItemMulti>
        <Divider />
        <FormItemMulti label="Mention bas de page n°2" name="CONF_EXPO_CREDIT_MENTION_B" rules={[{ required: true }]}>
          <Input.TextArea />
        </FormItemMulti>

        {isSuccess && (
          <Form.Item>
            <Alert message="La configuration a été enregistrée avec succès" type="success" showIcon />
          </Form.Item>
        )}
        <Form.Item>
          <Button style={{ width: '100%' }} type="primary" loading={isLoading} htmlType="submit">Enregistrer</Button>
        </Form.Item>
      </Form>
    </div>
  );
}
