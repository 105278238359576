import React from 'react';
import { Alert } from 'antd';
import { useMyContext } from '../hooks/useMyContext';

export default function OnlyEvent({ children }) {
  const { event } = useMyContext();

  if (!event) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Alert message="Veuillez choisir un événement" type="warning" showIcon />
      </div>
    );
  }

  return children;
}
