import { useCallback } from 'react';
import { useGetMyRolesQuery } from '../api/v2/roles';
import { useMyContext } from './useMyContext';

const useRights = () => {
  const { myContext, event } = useMyContext();

  const { data: roles } = useGetMyRolesQuery(myContext);

  const hasRight = useCallback((right) => {
    const role = roles?.find((f) => f.eventId === null || f.eventId === event?.id);
    return role?.rights.find((f) => f === right) || false;
  }, [roles, event]);

  const hasRoleOnEvent = useCallback((eventId) => {
    const role = roles?.find((f) => f.eventId === null || f.eventId === eventId);
    return role;
  }, [roles]);

  return { hasRight, hasRoleOnEvent };
};

export default useRights;
