import { configureStore } from '@reduxjs/toolkit';
import apiV1 from '../api/v1';
import apiV2 from '../api/v2';

const store = configureStore({
  reducer: { [apiV1.reducerPath]: apiV1.reducer, [apiV2.reducerPath]: apiV2.reducer },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(apiV1.middleware, apiV2.middleware),
});

export default store;
