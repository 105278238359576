const { useFilesCreateUrlMutation } = require('../api/v2/files');

const uploadToS3 = (signedUrl, file) => fetch(signedUrl, { method: 'PUT', body: file, headers: { 'Content-Type': file.type } });

const useFiles = () => {
  const [createUrl] = useFilesCreateUrlMutation();

  const uploadFile = async (file, isPublic = false) => {
    const { signedUrl, uuid, url } = await createUrl({ contentType: file.type, isPublic }).unwrap();

    await uploadToS3(signedUrl, file);
    return { uuid, url };
  };

  return { uploadFile };
};

module.exports = { useFiles };
