import { Collapse } from 'antd';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetParticipationQuery } from '../../../api/v2/participations';
import { useGetSpotsQuery } from '../../../api/v2/spots';
import { useGetUserQuery } from '../../../api/v2/users';
import { useMyContext } from '../../../hooks/useMyContext';
import useRights from '../../../hooks/useRights';
import useSettingsFo from '../../../hooks/useSettingsFO';
import UserEventActivation from './Activation';
import UserEventCompany from './Company';
import UserEventInvitation from './Invitation';
import UserEventRemove from './Remove';
import UserEventResponse from './Response';
import UserEventSpots from './Spots';
import UserEventParticipation from './Participation';
import UserEventConferences from './Conferences';
import { useGetConferencesQuery } from '../../../api/v2/conferences';
import UserEventPresences from './Presences';

function Header() {
  const { myContext } = useMyContext();
  const { userId } = useParams();
  const settings = useSettingsFo();

  const { data: user } = useGetUserQuery({ ...myContext, userId });

  if (settings?.headerImg) {
    return (
      <img
        style={{ width: '100%' }}
        alt="header"
        src={`https://gayakoa-files-eu-west-1-public.s3.eu-west-1.amazonaws.com/${settings.headerImg}`}
      />
    );
  } if (settings?.[`CONF_EXPO_HEADER_IMG_${user?.language || 'EN'}`]) {
    return <img style={{ width: '100%' }} src={settings[`CONF_EXPO_HEADER_IMG_${user?.language || 'EN'}`]} alt="" />;
  }

  return null;
}

export default function UserEvent() {
  const { event, myContext } = useMyContext();
  const { userId } = useParams();
  const { hasRight } = useRights();

  const { data: participation } = useGetParticipationQuery({ ...myContext, userId });
  const { data } = useGetSpotsQuery({ ...myContext, userId });
  const { data: c } = useGetConferencesQuery({ ...myContext, userId });

  const items = useMemo(() => {
    const arr = [
      {
        key: 'invite',
        label: `${participation?.invited ? '✅' : '🔴'} Invitation`,
        children: <UserEventInvitation />,
      },
      {
        key: 'response',
        label: `${participation?.responded ? '✅' : '🔴'} Réponse`,
        children: <UserEventResponse />,
      },
      {
        key: 'participation',
        label: `${participation?.isRegistered ? '✅' : '🔴'} Participation`,
        children: <UserEventParticipation />,
      },
      {
        key: 'presences',
        label: `${participation?.isPresent ? '✅' : '🔴'} Présences`,
        children: <UserEventPresences />,
      },
      {
        key: 'conferences',
        label: `${c?.hasParticipation ? '✅' : '🔴'} Conférences`,
        children: <UserEventConferences />,
      },
      {
        key: 'agenda',
        label: `${data?.spots?.length ? '✅' : '🔴'} Agenda`,
        children: <UserEventSpots />,
      },
    ];

    return arr;
  }, [hasRight, participation, data, c]);

  if (!event) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Header />
      {!participation && <UserEventActivation />}
      {event && participation && (
        <>
          <UserEventRemove />
          <UserEventCompany />
          {items.length > 0 && (
            <Collapse
              size="small"
              items={items}
              defaultActiveKey={[]}
            />
          )}
        </>
      )}
    </div>
  );
}
