import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useGetLogsQuery } from '../../../../api/v2/logs';
import { useMyContext } from '../../../../hooks/useMyContext';

export default function UserLogs() {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

  const { myContext } = useMyContext();
  const { userId } = useParams();

  const { data, isFetching } = useGetLogsQuery({ ...myContext, userId, ...pagination });

  useEffect(() => {
    if (data) {
      setPagination((p) => ({ ...p, total: data.total }));
    }
  }, [data]);

  return (
    <div>
      <Table
        rowKey={(record) => record.id}
        scroll={{ x: true }}
        loading={isFetching}
        dataSource={data?.logs}
        pagination={pagination}
        onChange={setPagination}
        columns={[
          {
            title: 'Log',
            dataIndex: 'label',
          },
          {
            title: 'Date',
            dataIndex: 'createdAt',
            render: (v) => `${format(new Date(v), 'dd/MM/yyyy HH:mm')}`,
          },
        ]}
      />
    </div>
  );
}
