import { Button, Checkbox, Input } from 'antd';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetParticipationQuery, useUpdateParticipationMutation } from '../../../api/v2/participations';
import { useGetTyposQuery } from '../../../api/v2/typo';
import { useMyContext } from '../../../hooks/useMyContext';

export default function UserEventResponse() {
  const [message, setMessage] = useState('');

  const { myContext } = useMyContext();
  const { userId } = useParams();

  const { data: participation } = useGetParticipationQuery({ ...myContext, userId });
  const [update, { isLoading }] = useUpdateParticipationMutation();

  useEffect(() => {
    if (participation?.message) {
      setMessage(participation?.message);
    }
  }, [participation]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <Checkbox
          loading={isLoading}
          checked={participation?.responded}
          onChange={(e) => {
            update({
              ...myContext,
              userId,
              participation: { responded: e.target.checked, message: e.target.checked ? message : null },
            });
            if (!e.target.checked) {
              setMessage('');
            }
          }}
        />
        <div>a répondu</div>
      </div>
      {participation?.responded ? (
        <>
          <Input.TextArea
            placeholder="Message"
            rows={3}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button type="primary" onClick={() => update({ ...myContext, userId, participation: { message } })} loading={isLoading}>
            Enregistrer
          </Button>
        </>
      ) : null}
    </div>
  );
}
