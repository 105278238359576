import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useUsers } from '../useUsers';

const filterById = (id, array) => array.reduce((prev, curr) => {
  if (Array.isArray(curr)) {
    return [...prev, filterById(id, curr)];
  }
  if (curr.id !== id) {
    return [...prev, curr];
  }
  return prev;
}, []);

export default function FilterEmpty({ m, setFilters }) {
  const [selectValue, setSelectValue] = useState(null);

  const { currentSettings } = useUsers();

  const selectId = `string-select-${m.model}.${m.property}${m.extended ? `.${m.extended}` : ''}`;

  useEffect(() => {
    if (currentSettings) {
      const findSelect = currentSettings.usersFilters.flat().find((f) => f.id === selectId);
      setSelectValue(findSelect?.v || null);
    }
  }, [currentSettings]);

  return (
    <Select
      allowClear
      popupMatchSelectWidth={false}
      size="small"
      options={[
        { label: 'Vide', value: 'EMPTY' },
        { label: 'Non vide', value: 'NOT EMPTY' },
      ]}
      value={selectValue}
      onChange={(value) => setFilters((prev) => {
        setSelectValue(value);
        let newFilters = [];

        if (value === 'EMPTY' && m.extended) {
          newFilters = [{
            id: selectId,
            operator: 'PATH',
            value: 0,
            v: value,
            property: `${m.model}.${m.property}`,
            extended: `$."${m.extended}"`,
          }, {
            id: selectId,
            operator: '=',
            value: '',
            v: value,
            property: `${m.model}.${m.property}`,
            condition: 'OR',
            extended: `$."${m.extended}"`,
          }];
        } else if (value === 'NOT EMPTY' && m.extended) {
          newFilters = [{
            id: selectId,
            operator: 'PATH',
            value: 1,
            v: value,
            property: `${m.model}.${m.property}`,
            extended: `$."${m.extended}"`,
          }, {
            id: selectId,
            operator: '!=',
            value: '',
            v: value,
            property: `${m.model}.${m.property}`,
            extended: `$."${m.extended}"`,
          }];
        } else if (value === 'EMPTY') {
          newFilters = [{
            id: selectId,
            operator: '=',
            value: '',
            v: value,
            property: `${m.model}.${m.property}`,
          }, {
            id: selectId,
            operator: 'IS',
            value: 'NULL',
            v: value,
            property: `${m.model}.${m.property}`,
            condition: 'OR',
          }];
        } else if (value === 'NOT EMPTY') {
          newFilters = [{
            id: selectId,
            operator: '!=',
            value: '',
            v: value,
            property: `${m.model}.${m.property}`,
          }, {
            id: selectId,
            operator: 'IS NOT',
            value: 'NULL',
            v: value,
            property: `${m.model}.${m.property}`,
          }];
        }

        return [...filterById(selectId, prev), newFilters];
      })}
    />
  );
}
