import { Checkbox, TimePicker } from 'antd';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import React from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useGetParticipationQuery, useUpdateParticipationMutation } from '../../../api/v2/participations';
import Flex from '../../../components/Flex';
import SearchUsers from '../../../components/SearchUsers';
import SendEmail, { boEmails } from '../../../components/SendEmail';
import { useMyContext } from '../../../hooks/useMyContext';

const formatDateForMySQL = (date) => {
  if (!date) return null;
  return date.toISOString().slice(0, 19).replace('T', ' ');
};

export default function UserEventPresences() {
  const { myContext, event } = useMyContext();
  const { userId } = useParams();

  const { data: participation } = useGetParticipationQuery({ ...myContext, userId });
  const [update] = useUpdateParticipationMutation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Flex style={{ flexDirection: 'column' }}>
        {event?.days.map((m) => (
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <Checkbox
              checked={participation?.days?.[m.id]?.present}
              onChange={(e) => update({
                ...myContext,
                userId,
                participation: {},
                days: [{
                  eventDayId: m.id,
                  present: e.target.checked,
                  presentAt: e.target.checked ? formatDateForMySQL(new Date()) : null,
                }],
              })}
            >
              {format(new Date(m.day), 'dd/MM/yyyy', { locale: fr })}
            </Checkbox>
            <TimePicker
              value={participation?.days?.[m.id]?.presentAt ? dayjs(participation?.days?.[m.id]?.presentAt, 'HH:mm') : null}
              format="HH:mm"
              disabled={!participation?.days?.[m.id]?.present}
              allowClear={false}
              onChange={(_, presentAt) => update({
                ...myContext,
                userId,
                participation: {},
                days: [{
                  eventDayId: m.id,
                  presentAt,
                }],
              })}
            />
          </div>
        ))}
      </Flex>

      <div style={{ display: 'flex', gap: 8 }}>
        <SendEmail email={boEmails.EMAILS_SEND_WELCOME} />
      </div>
    </div>
  );
}
