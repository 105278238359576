/* eslint-disable react/jsx-props-no-spreading */
import { Select } from 'antd';
import React, { useState } from 'react';
import { useMyContext } from './useMyContext';
import { useGetTyposQuery } from '../api/v2/typo';

const useSelectTypo = () => {
  const [typoId, setTypoId] = useState();

  const { myContext, settings } = useMyContext();
  const { data: typos } = useGetTyposQuery(myContext);

  function SelectTypo(props) {
    if (!Array.isArray(settings?.typos) || settings?.typos.length < 1) {
      return null;
    }

    return (
      <Select
        options={typos}
        value={typoId}
        onChange={(v) => {
          if (props?.onChange) {
            props?.onChange(v);
          }
          setTypoId(v);
        }}
        allowClear
        {...props}
      />
    );
  }

  return [SelectTypo, { typoId, setTypoId }];
};

export default useSelectTypo;
