import React from 'react';
import FormItemEmail from './components/FormItemEmail';

export const foEmails = [
  {
    label: 'Email de confirmation',
    value: 'FO_MEETING_EMAIL_CONFIRMATION',
  },
  {
    label: 'Email de confirmation (distanciel)',
    value: 'FO_MEETING_EMAIL_CONFIRMATION_REMOTE',
  },
  {
    label: 'Email du mot de passe oublié',
    value: 'FO_MEETING_EMAIL_FORGOT_PASSWORD',
  },
  {
    label: 'Envoyer le badge',
    value: 'FO_MEETING_EMAIL_SEND_BADGE',
  },
  {
    label: 'Email pour le délégué',
    value: 'FO_MEETING_EMAIL_REPRESENTED',
  },
  {
    label: 'Email d\'une réponse négative',
    value: 'FO_MEETING_EMAIL_NO',
  },
  {
    label: 'Email après achat',
    value: 'FO_MEETING_EMAIL_SHOP_SUCCESS',
  },
];

export default function MeetingEmails() {
  return (
    <>
      {foEmails.map((m) => <FormItemEmail label={m.label} name={m.value} />)}
    </>
  );
}
