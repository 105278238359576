import { Button, Checkbox, Form, Input, Select, Switch } from 'antd';
import React, { useState } from 'react';
import { useGetTemplatesQuery, useSendCampaignMutation } from '../../../api/v2/emails';
import { useMyContext } from '../../../hooks/useMyContext';
import { useUsers } from '../useUsers';

export default function SendCampaign({ close }) {
  const [templateId, setTemplateId] = useState();
  const [label, setLabel] = useState();
  const [excludeUsers, setExcludeUsers] = useState(false);

  const { myContext } = useMyContext();
  const { usersHeaders, usersFilters } = useUsers();

  const { data: templates } = useGetTemplatesQuery(myContext);
  const [sendCampaign, { isLoading }] = useSendCampaignMutation();

  return (
    <Form
      layout="vertical"
      onFinish={async (values) => {
        await sendCampaign({
          ...myContext,
          ...values,
          filters: usersFilters,
          headers: usersHeaders,
        }).unwrap();
        close();
      }}
    >
      <Form.Item label="Label" name="label" rules={[{ required: true }]}>
        <Input placeholder="Label" value={label} onChange={(e) => setLabel(e.target.value)} />
      </Form.Item>
      <Form.Item label="Template" name="templateId" rules={[{ required: true }]}>
        <Select
          placeholder="Choisir le template"
          options={templates?.map((m) => ({ label: m.name, value: m.id }))}
        />
      </Form.Item>
      <Form.Item label="Exclure les utilisateurs ayant déjà reçu cette email sur cet événement" name="excludeUsers" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Button
        loading={isLoading}
        type="primary"
        htmlType="submit"
      >
        Envoyer la campagne
      </Button>
    </Form>
  );
}
