import { Alert, Button, DatePicker, Form, Input, Select, Switch } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useCreateEventMutation, useEventsGetByDomainQuery, useUpdateEventMutation } from '../../api/v2/events';
import { useMyContext } from '../../hooks/useMyContext';

export default function EventsEdition({ edition, setEdition }) {
  const [error, setError] = useState();

  const { myContext } = useMyContext();
  const [form] = Form.useForm();

  const [create] = useCreateEventMutation();
  const [update] = useUpdateEventMutation();
  const { data } = useEventsGetByDomainQuery({ ...myContext });

  const isCreation = !edition?.id;

  useEffect(() => {
    if (edition) {
      form.setFieldsValue({ event: { ...edition, start: dayjs(edition.start) } });
    }
  }, [edition]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div>
        <Button type="primary" onClick={() => setEdition(null)}>
          Retour
        </Button>
      </div>
      <Form
        form={form}
        onFinish={async (values) => {
          setError();

          try {
            const event = { ...values.event, start: values.event.start.format('YYYY-MM-DD') };

            if (isCreation) {
              await create({ ...myContext, body: { ...values, event } }).unwrap();
            } else {
              await update({ ...myContext, body: { ...values, event }, eventId: edition.id }).unwrap();
            }
            setEdition(null);
          } catch (err) {
            setError(err?.data?.message || 'Erreur inconnue');
          }
        }}
      >
        <Form.Item label="Nom" name={['event', 'name']} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Alias" name={['event', 'alias']}>
          <Input />
        </Form.Item>
        <Form.Item label="Lieu" name={['event', 'location']} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Date" name={['event', 'start']} rules={[{ required: true }]}>
          <DatePicker format="DD/MM/YYYY" style={{ width: 200 }} disabled={!isCreation} />
        </Form.Item>
        {isCreation && (
          <Form.Item label="Nombre de jours" name={['event', 'days']} rules={[{ required: true }]} initialValue={1}>
            <Input type="number" />
          </Form.Item>
        )}
        <Form.Item label="Nombre de participants max" name={['event', 'gauge']}>
          <Input type="number" />
        </Form.Item>
        <Form.Item label="Événement partenaire" name={['event', 'eventId']}>
          <Select options={data?.map((m) => ({ label: m.name, value: m.id }))} allowClear />
        </Form.Item>
        {isCreation && (
          <>
            <Form.Item label="Copier les données d'un autre événement" name={['duplicate', 'eventId']}>
              <Select options={data?.map((m) => ({ label: m.name, value: m.id }))} allowClear />
            </Form.Item>
            <Form.Item shouldUpdate>
              {({ getFieldValue }) => getFieldValue('duplicate', 'eventId') && (
                <>
                  <Form.Item
                    label="Copier les templates email"
                    name={['duplicate', 'copyEmails']}
                    valuePropName="checked"
                    preserve={false}
                    initialValue
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    label="Copier les configurations FO"
                    name={['duplicate', 'copySettings']}
                    valuePropName="checked"
                    preserve={false}
                    initialValue
                  >
                    <Switch />
                  </Form.Item>
                </>
              )}
            </Form.Item>
          </>
        )}
        {error && (
          <Form.Item>
            <Alert type="error" message={error} showIcon />
          </Form.Item>
        )}
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button type="primary" htmlType="submit">
            {isCreation ? 'Créer' : 'Modifier'}
          </Button>
        </div>
      </Form>
    </div>
  );
}
