import apiV2 from '.';

export const attendancesApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    attendancesScan: builder.mutation({
      query: ({ domainId, eventId, code, eventIdB, type }) => ({
        url: `/domains/${domainId}/events/${eventId}/attendances/scan`,
        method: 'PUT',
        body: { code, eventIdB, type },
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['PARTICIPATION'],
    }),
  }),
});

export const { useAttendancesScanMutation } = attendancesApi;
