import { Form, InputNumber, Switch } from 'antd';
import React from 'react';

export default function MeetingLive() {
  return (
    <>
      <Form.Item
        label="Activer la popup d'inactivité"
        name="FO_MEETING_INACTIVE_POPUP"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Temps avant d'afficher la popup d'inactivité (secondes)"
        name="FO_MEETING_INACTIVE_POPUP_TIMER"
      >
        <InputNumber placeholder="60" />
      </Form.Item>
    </>
  );
}
