/* eslint-disable no-promise-executor-return */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDomainsMeQuery } from '../api/v2/domains';
import { useLazyEventsGetByDomainQuery } from '../api/v2/events';
import { useAuth } from './useAuth';

const MyContext = createContext();

export function MyContextProvider({ children }) {
  const [domain, setDomain] = useState();
  const [event, setEvent] = useState();
  const [rights, setRights] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const [search] = useSearchParams();
  const { defaultDomainId } = useAuth();

  const { data: domains } = useDomainsMeQuery();
  const [fetchEvents, { data: events }] = useLazyEventsGetByDomainQuery();

  useEffect(() => {
    if (domain) {
      setRights(domain.rights || []);
    }
  }, [domain]);

  useEffect(() => {
    if (domain) {
      fetchEvents({ domainId: domain.id }).unwrap().finally(() => setIsSuccess(true));
    }
  }, [domain]);

  const selectDomain = useCallback((domainId) => {
    if (domains) {
      const currentDomainId = domainId || search.get('domain') || defaultDomainId;
      const findDomain = domains.find((f) => f.id === Number(currentDomainId))
    || domains.find((f) => f.id === Number(defaultDomainId));

      if (findDomain) {
        setDomain(findDomain);
      }
    }
  }, [domains, defaultDomainId]);

  const selectEvent = useCallback((eventId) => {
    if (events) {
      if (eventId === undefined) {
        setEvent(null);
        return;
      }

      // const currentEventId = eventId || search.get('event') || events[0]?.id;
      const currentEventId = eventId || search.get('event');
      const findEvent = events?.find((f) => f.id === Number(currentEventId));

      if (findEvent) {
        setEvent(findEvent);
      }
    }
  }, [events]);

  useEffect(() => {
    selectDomain();
  }, [selectDomain]);

  useEffect(() => {
    selectEvent(null);
  }, [selectEvent]);

  const wait = async (ms) => new Promise((resolve) => setTimeout(() => resolve(), ms));

  const state = useMemo(() => ({
    domain,
    myContext: { domainId: domain?.id, eventId: event?.id },
    settings: event?.settings,
    selectDomain,
    selectEvent,
    domains,
    events,
    event,
    isSuccess,
    rights,
    wait,
  }), [domain, selectDomain, selectEvent, domains, events, event, rights]);

  return (
    <MyContext.Provider value={state}>
      {isSuccess && children}
    </MyContext.Provider>
  );
}

export const useMyContext = () => useContext(MyContext);
