import { Button, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useShowInvoiceMutation } from '../../../../api/v2/invoices';
import { useMyContext } from '../../../../hooks/useMyContext';

export default function ShowInvoiceGayakoa({ invoice, children }) {
  const [open, setOpen] = useState(false);

  const { myContext } = useMyContext();

  const [show, { isLoading: isShowing, data }] = useShowInvoiceMutation();

  useEffect(() => {
    if (open) {
      show({ ...myContext, userId: invoice.userId, invoiceId: invoice.id, gayakoa: true });
    }
  }, [open]);

  return (
    <>
      {React.cloneElement(children, { onClick: () => setOpen(true) })}
      <Modal
        title={invoice.label}
        open={open}
        onCancel={() => setOpen(false)}
        width={2000}
        footer={null}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          {data && (
            <iframe
              src={data}
              title="PDF"
              width="100%"
              style={{ height: 'calc(100vh - 300px)', border: 'none' }}
            />
          )}
          {isShowing && <Spin />}
          <Button style={{ width: '100%' }} type="primary" onClick={() => setOpen(false)}>Fermer</Button>
        </div>
      </Modal>
    </>
  );
}
