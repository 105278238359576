import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

let baseUrl = 'https://api.gayakoa.com';
const host = window.location.hostname;
if (host === 'localhost') baseUrl = 'http://localhost:3001';
if (host.includes('dev') || host.includes('d1cwsl2gmmbvf6.cloudfront.net')) baseUrl = 'https://api.dev.gayakoa.com';

const apiV1 = createApi({
  reducerPath: 'api-v1',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) headers.set('authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ['FORM'],
  endpoints: (builder) => ({ }),
});

export default apiV1;
