import queryString from 'query-string';
import apiV2 from '.';

export const emailsApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    getTemplates: builder.query({
      query: ({ domainId, eventId, languageId }) => ({
        url: `/domains/${domainId}/emails/templates?${queryString.stringify({ eventId, languageId })}`,
      }),
      transformResponse: (response) => response.data.templates,
      providesTags: ['EMAIL_TEMPLATES'],
    }),
    getAccounts: builder.query({
      query: ({ domainId }) => `/domains/${domainId}/emails/accounts`,
      transformResponse: (response) => response.data.accounts,
      providesTags: ['EMAIL_ACCOUNTS'],
    }),
    createTemplate: builder.mutation({
      query: ({ domainId, eventId, template }) => ({
        url: eventId ? `/domains/${domainId}/events/${eventId}/emails/templates` : `/domains/${domainId}/emails/templates`,
        method: 'POST',
        body: { template },
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['EMAIL_TEMPLATES'],
    }),
    updateTemplate: builder.mutation({
      query: ({ domainId, templateId, template }) => ({
        url: `/domains/${domainId}/emails/templates/${templateId}`,
        method: 'PUT',
        body: { template },
      }),
      invalidatesTags: ['EMAIL_TEMPLATES'],
    }),
    removeTemplate: builder.mutation({
      query: ({ domainId, templateId }) => ({
        url: `/domains/${domainId}/emails/templates/${templateId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EMAIL_TEMPLATES'],
    }),
    createAccount: builder.mutation({
      query: ({ domainId, account }) => ({
        url: `/domains/${domainId}/emails/accounts`,
        method: 'POST',
        body: { account },
      }),
      invalidatesTags: ['EMAIL_ACCOUNTS'],
    }),
    updateAccount: builder.mutation({
      query: ({ domainId, accountId, account }) => ({
        url: `/domains/${domainId}/emails/accounts/${accountId}`,
        method: 'PUT',
        body: { account },
      }),
      invalidatesTags: ['EMAIL_ACCOUNTS'],
    }),
    removeAccount: builder.mutation({
      query: ({ domainId, accountId }) => ({
        url: `/domains/${domainId}/emails/accounts/${accountId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EMAIL_ACCOUNTS'],
    }),
    sendCampaign: builder.mutation({
      query: ({ domainId, eventId, filters, headers, templateId, label, excludeUsers }) => ({
        url: `/domains/${domainId}/events/${eventId}/emails/templates/${templateId}/campaigns`,
        method: 'POST',
        body: { filters, headers, label, excludeUsers },
      }),
      transformResponse: (response) => response.data,
    }),
    sendEmail: builder.mutation({
      query: ({ domainId, eventId, userId, templateId }) => ({
        url: `/domains/${domainId}/emails/send`,
        method: 'POST',
        body: { templateId, userId, eventId },
      }),
      transformResponse: (response) => response.data,
    }),
    getEmailLogs: builder.query({
      query: ({ domainId, eventId, userId }) => `/domains/${domainId}/events/${eventId}/users/${userId}/emails/logs`,
      transformResponse: (response) => response.data.emailLogs,
      providesTags: ['EMAIL_ACCOUNTS', 'EMAIL_TEMPLATES'],
    }),
  }),
});

export const {
  useGetTemplatesQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useRemoveTemplateMutation,
  useGetAccountsQuery,
  useCreateAccountMutation,
  useUpdateAccountMutation,
  useRemoveAccountMutation,
  useSendCampaignMutation,
  useSendEmailMutation,
  useGetEmailLogsQuery,
} = emailsApi;
