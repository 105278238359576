import queryString from 'query-string';
import apiV2 from '.';

export const rolesApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    createRole: builder.mutation({
      query: ({ domainId, role }) => ({
        url: `/domains/${domainId}/roles`,
        method: 'POST',
        body: { role },
      }),
      invalidatesTags: ['ROLES'],
    }),
    updateRole: builder.mutation({
      query: ({ domainId, role, roleId }) => ({
        url: `/domains/${domainId}/roles/${roleId}`,
        method: 'PUT',
        body: { role },
      }),
      invalidatesTags: ['ROLES'],
    }),
    removeRole: builder.mutation({
      query: ({ domainId, roleId }) => ({
        url: `/domains/${domainId}/roles/${roleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ROLES'],
    }),
    setUserRole: builder.mutation({
      query: ({ domainId, eventId, userId, roleId }) => ({
        url: eventId
          ? `/domains/${domainId}/events/${eventId}/users/${userId}/roles/${roleId}`
          : `/domains/${domainId}/users/${userId}/roles/${roleId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['ROLES'],
    }),
    getRoles: builder.query({
      query: ({ domainId, eventId, userId }) => (eventId
        ? `/domains/${domainId}/events/${eventId}/roles?${queryString.stringify({ userId })}`
        : `/domains/${domainId}/roles?${queryString.stringify({ userId })}`),
      transformResponse: (response) => response.data,
      providesTags: ['ROLES'],
    }),
    getMyRoles: builder.query({
      query: ({ domainId }) => `/domains/${domainId}/roles/me`,
      transformResponse: (response) => response.data.roles,
      providesTags: ['ROLES'],
    }),
    getRoleUsers: builder.query({
      query: ({ domainId, eventId }) => (eventId
        ? `/domains/${domainId}/events/${eventId}/roles/users` : `/domains/${domainId}/roles/users`),
      transformResponse: (response) => response.data?.users,
      providesTags: ['ROLES'],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useRemoveRoleMutation,
  useSetUserRoleMutation,
  useGetRoleUsersQuery,
  useGetMyRolesQuery,
} = rolesApi;
