import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Table } from 'antd';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useEventsGetByDomainQuery, useRemoveEventMutation } from '../../api/v2/events';
import { useMyContext } from '../../hooks/useMyContext';
import EventsEdition from './Edition';

export default function Events() {
  const [edition, setEdition] = useState(null);

  const { myContext } = useMyContext();

  const [remove] = useRemoveEventMutation();
  const { data } = useEventsGetByDomainQuery({ ...myContext });

  if (edition) {
    return <EventsEdition edition={edition} setEdition={setEdition} />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Button type="primary" onClick={() => setEdition({ start: dayjs() })}>
        Créer un événement
      </Button>

      <Table
        dataSource={data}
        scroll={{ x: true }}
        columns={[
          { title: 'Nom', dataIndex: 'name' },
          { title: 'Alias', dataIndex: 'alias' },
          { title: 'Nombre de jours', dataIndex: 'daysTotal' },
          { title: 'Lieu', dataIndex: 'location' },
          { title: 'Date de début', dataIndex: 'start', render: (v) => format(new Date(v), 'dd/MM/yyyy') },
          { title: 'Gauge', dataIndex: 'gauge' },
          {
            render: (v) => (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                <Button type="primary" onClick={() => setEdition(v)} disabled={!v.id}>
                  Modifier
                </Button>
                <Button
                  onClick={() => {
                    Modal.confirm({
                      title: 'Voulez-vous vraiment supprimer cet événement ?',
                      icon: <ExclamationCircleOutlined />,
                      okText: 'Oui',
                      cancelText: 'Non',
                      async onOk() {
                        return remove({ ...myContext, eventId: v.id });
                      },
                    });
                  }}
                  disabled={!v.id}
                  danger
                >
                  Supprimer
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
