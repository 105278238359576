import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import { compareDesc, format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetArticlesQuery } from '../../../../api/v2/articles';
import { useCreateInvoiceMutation, useGetInvoicesQuery } from '../../../../api/v2/invoices';
import { useGetUserQuery } from '../../../../api/v2/users';
import { useMyContext } from '../../../../hooks/useMyContext';

const properties = [
  {
    label: 'N° de commande',
    value: ['bill', 'orderId'],
    required: true,
  },
  {
    label: 'Date de la commande',
    value: ['bill', 'date'],
    required: true,
  },
  {
    label: 'Prénom',
    value: ['bill', 'firstName'],
    required: true,
  },
  {
    label: 'Nom',
    value: ['bill', 'lastName'],
    required: true,
  },
  {
    label: 'Nom de la société',
    value: ['bill', 'companyName'],
    required: true,
  },
  {
    label: 'Adresse ligne n°1',
    value: ['bill', 'address1'],
    width: '100%',
    required: true,
  },
  {
    label: 'Adresse ligne n°2',
    value: ['bill', 'address2'],
    width: '100%',
  },
  {
    label: 'Adresse ligne n°3',
    value: ['bill', 'address3'],
    width: '100%',
  },
  {
    label: 'Code postal',
    value: ['bill', 'postalCode'],
    required: true,
  },
  {
    label: 'Ville',
    value: ['bill', 'city'],
    required: true,
  },
  {
    label: 'Pays (Code)',
    value: ['bill', 'country'],
    required: true,
  },
  {
    label: 'N° de TVA Intracom',
    value: ['bill', 'intracom'],
  },
];

export default function UserInvoicesCreate({ values }) {
  const [show, setShow] = useState(false);
  const [total, setTotal] = useState(0);

  const { myContext } = useMyContext();
  const { userId } = useParams();
  const [form] = Form.useForm();

  const { data: user } = useGetUserQuery({ ...myContext, userId });
  const { data } = useGetArticlesQuery({ ...myContext });
  const { data: invoices } = useGetInvoicesQuery({ ...myContext, userId });
  const [create, { isLoading }] = useCreateInvoiceMutation();

  const lastInvoice = useMemo(() => (invoices?.length > 0
    ? [...invoices].sort((a, b) => compareDesc(a.createdAt, b.createdAt))[0]
    : null), [invoices]);

  const updateTotal = (v) => {
    const t = v.bill.articles
      .map((m) => ({ ...data?.find((f) => f.code === m.code), ...m }))
      .filter((f) => f.priceTTC && f.quantity)
      .reduce((prev, curr) => prev + curr.priceTTC * curr.quantity, 0);
    setTotal(t);
  };

  useEffect(() => {
    if (!show) {
      return;
    }

    if (values) {
      form.setFieldsValue({ withTva: 1, ...values });
      updateTotal(values);
    } else if (lastInvoice) {
      form.setFieldsValue({
        withTva: 1,
        ...lastInvoice,
        bill: { ...lastInvoice.bill, date: format(new Date(), 'dd/MM/yyyy'), articles: [{ quantity: 1 }] },
      });
    } else {
      form.setFieldsValue({
        bill: {
          date: format(new Date(), 'dd/MM/yyyy'),
          articles: [{ quantity: 1 }],
          firstName: user?.firstName,
          lastName: user?.lastName,
        },
        withTva: 1,
      });
    }
  }, [lastInvoice, values, show, user]);

  const reset = () => {
    setShow(false);
    setTotal(0);
    form.resetFields();
  };

  return (
    <div>
      {values
        ? <Button type="primary" onClick={() => setShow(true)} ghost>Reprendre</Button>
        : <Button type="primary" onClick={() => setShow(true)}>Créer une facture</Button>}
      <Modal title="Créer une facture" open={show} onCancel={() => reset()} width={1000} footer={null}>
        <Form
          initialValues={{ bill: { articles: [{ quantity: 1 }], firstName: user?.firstName, lastName: user?.lastName }, withTva: 1 }}
          form={form}
          layout="vertical"
          onFinish={async (invoice) => {
            await create({ ...myContext, userId, invoice: { withTva: 1, ...invoice, id: values?.id } }).unwrap();
            reset();
          }}
          onValuesChange={(_, v) => updateTotal(v)}
        >
          <Form.Item shouldUpdate>
            {(fo) => {
              const articles = fo.getFieldValue(['bill', 'articles']);

              return articles.map((a, i) => (
                <div style={{ display: 'flex', gap: 16, alignItems: 'flex-end' }}>
                  <Form.Item
                    label="Choisir un article"
                    name={['bill', 'articles', i, 'code']}
                    style={{ flexGrow: 1 }}
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={data?.map((m) => ({ label: `[${m.code}] - ${m.label} - ${m.priceTTC} €`, value: m.code }))}
                      popupMatchSelectWidth={false}
                      filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                      showSearch
                    />
                  </Form.Item>
                  <Form.Item label="Quantité" name={['bill', 'articles', i, 'quantity']} rules={[{ required: true }]}>
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item label="Prix TTC" shouldUpdate>
                    {() => {
                      const article = data?.find((f) => f.code === a.code);
                      const price = (article ? article.priceTTC * a.quantity : 0).toFixed(2);
                      return <Input value={`${price} €`} disabled />;
                    }}
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={() => {
                        const v = form.getFieldsValue();
                        v.bill.articles.splice(i, 1);
                        form.setFieldsValue(v);
                        updateTotal(v);
                      }}
                      danger
                      disabled={articles?.length <= 1}
                    >
                      Supprimer
                    </Button>
                  </Form.Item>
                </div>
              ));
            }}
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: '100%' }}
              type="primary"
              onClick={() => {
                const v = form.getFieldsValue();
                v.bill.articles.push({ quantity: 1 });
                form.setFieldsValue(v);
              }}
              ghost
            >
              Ajouter un article
            </Button>
          </Form.Item>

          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
            <div style={{ fontWeight: 600, fontSize: 20 }}>{`Total TTC : ${total.toFixed(2)} €`}</div>
          </div>

          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
            {properties.map((m) => (
              <Form.Item
                label={m.label}
                name={m.value}
                rules={m.required ? [{ required: true }] : []}
                style={{ width: m.width, minWidth: 200, flexGrow: 1, marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            ))}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 8, alignItems: 'flex-end', marginTop: 16 }}>
            <div style={{ fontWeight: 600, fontSize: 20 }}>{`Total TTC : ${total.toFixed(2)} €`}</div>
            <Button style={{ width: '100%' }} type="primary" htmlType="submit" loading={isLoading}>
              Enregistrer
            </Button>
            <Button style={{ width: '100%' }} type="primary" loading={isLoading} onClick={() => reset()} ghost>
              Annuler
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
