import { DeleteOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import React from 'react';
import { useRemoveSettingsMutation } from '../../../api/v2/settings';
import { useMyContext } from '../../../hooks/useMyContext';
import UsersActions from '../actions';
import { useUsers } from '../useUsers';
import UserSettingsEdition from './Edition';
import useRights from '../../../hooks/useRights';

export default function UsersSettingsV2({ data }) {
  const { myContext } = useMyContext();
  const { settings, currentSettings, setCurrentSettings, usersHeaders, setUsersHeaders } = useUsers();
  const { hasRight } = useRights();

  const [remove, { isLoading: isRemoving }] = useRemoveSettingsMutation();

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '8px 32px', flexWrap: 'wrap' }}>
      <div style={{ display: 'flex', gap: 8, flexGrow: 1, flexWrap: 'wrap' }}>
        <Select
          style={{ flexGrow: 1 }}
          options={settings?.map((m) => ({ label: m.label, value: m.id }))}
          value={currentSettings?.id}
          onChange={(id) => setCurrentSettings(settings?.find((f) => f.id === Number(id)))}
        />
        {hasRight('USERS_SETTINGS') && (
          <>
            <UserSettingsEdition data={data} />
            <UserSettingsEdition data={data} values={currentSettings} />
            <Button
              type="primary"
              icon={<DeleteOutlined />}
              onClick={() => remove({ ...myContext, settingsId: currentSettings?.id })}
              disabled={!currentSettings?.id}
              loading={isRemoving}
              danger
            />
          </>
        )}
      </div>
      <div>
        <UsersActions total={data?.count} />
      </div>
    </div>
  );
}
