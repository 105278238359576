import { Button, Table } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetCoworkersQuery } from '../../../../api/v2/companies';
import { useMyContext } from '../../../../hooks/useMyContext';
import useNav from '../../../../hooks/useNav';

export default function UserCoworkers() {
  const { myContext } = useMyContext();
  const { userId } = useParams();
  const nav = useNav();

  const { data: coworkers, isFetching } = useGetCoworkersQuery({ ...myContext, userId });

  return (
    <div>
      <Table
        scroll={{ x: true }}
        loading={isFetching}
        dataSource={coworkers}
        columns={[
          {
            title: 'Prénom',
            dataIndex: 'firstName',
          },
          {
            title: 'Nom',
            dataIndex: 'lastName',
          },
          {
            render: (v) => (
              <div style={{ display: 'flex', gap: 8 }}>
                <Button type="primary" onClick={(e) => nav(`/users/${v.id}`, { e })}>Voir son profil</Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
