/* eslint-disable max-len */
import {
  Alert, Button, Form, Input,
} from 'antd';
import React from 'react';
import { useAuth } from '../../hooks/useAuth';

// Photo de <a href="https://unsplash.com/fr/@fakurian?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Milad Fakurian</a> sur <a href="https://unsplash.com/fr/photos/ballons-jaunes-et-blancs-sur-surface-orange-PGdW_bHDbpI?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>

export default function Login() {
  const { login, isLogging, error } = useAuth();

  console.log(isLogging);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: 'url(/assets/images/login.jpg) center/cover no-repeat',
      }}
    >
      <Form
        style={{
          width: 400,
          maxWidth: '90%',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          padding: '16px 32px 32px 32px',
          borderRadius: 16,
        }}
        onFinish={login}
        layout="vertical"
      >
        <Form.Item>
          <div style={{ fontSize: 40, fontWeight: 'bold', textAlign: 'center' }}>GAYAKOA</div>
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input placeholder="abc@def.com" type="email" />
        </Form.Item>
        <Form.Item label="Mot de passe" name="password">
          <Input.Password placeholder="********" />
        </Form.Item>
        {error && (
          <Form.Item>
            <Alert message={error} type="error" showIcon />
          </Form.Item>
        )}
        <Button style={{ width: '100%' }} type="primary" htmlType="submit" loading={isLogging}>Connexion</Button>
      </Form>
    </div>
  );
}
