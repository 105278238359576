import { Button, Table } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetEmployeesByCompanyQuery } from '../../api/v2/employees';
import { useMyContext } from '../../hooks/useMyContext';
import useNav from '../../hooks/useNav';

export default function CompanyEmployees() {
  const { myContext } = useMyContext();
  const { companyId } = useParams();
  const nav = useNav();

  const { data, isFetching } = useGetEmployeesByCompanyQuery({ ...myContext, companyId });

  return (
    <div>
      <Table
        scroll={{ x: true }}
        loading={isFetching}
        pagination={false}
        dataSource={data}
        columns={[
          { title: 'Prénom', dataIndex: 'firstName' },
          { title: 'Nom', dataIndex: 'lastName' },
          { title: 'Email', dataIndex: 'email' },
          { title: 'Mobile', dataIndex: 'mobile' },
          { title: 'Fixe', dataIndex: 'phone' },
          {
            render: (c) => (
              <div style={{ display: 'flex', gap: 8 }}>
                <Button type="primary" onClick={() => nav(`/users/${c.id}`, { newTab: true })}>Voir</Button>
                <Button type="primary" danger>Retirer</Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
