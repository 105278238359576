import { Button, Form, Input, Radio } from 'antd';
import React from 'react';
import { useDomainsUpdateMutation } from '../../api/v2/domains';
import { useMyContext } from '../../hooks/useMyContext';
import MyColorPicker from '../../components/MyColorPicker';
import MyUploadImage from '../../components/MyUploadImage';

export default function DomainPage() {
  const { domain, myContext, event } = useMyContext();

  const [update, { isLoading }] = useDomainsUpdateMutation();

  return (
    <div>
      <div style={{ textAlign: 'center', fontWeight: 600, fontSize: 25, marginBottom: 8 }}>Configuration BO</div>
      <Form
        initialValues={domain}
        onFinish={(v) => {
          update({ ...myContext, domain: v });
        }}
      >
        <Form.Item name="name" label="Label">
          <Input />
        </Form.Item>

        <Form.Item
          name={['extended', 'SIDEBAR_BACKGROUND_COLOR_V2']}
          label="Couleur de fond du menu"
        >
          <MyColorPicker defaultValue="#001529" />
        </Form.Item>

        <Form.Item
          name={['extended', 'SIDEBAR_TEXT_COLOR_V2']}
          label="Couleur du texte du menu"
        >
          <MyColorPicker defaultValue="rgba(255, 255, 255, 0.65)" />
        </Form.Item>

        <Form.Item
          name={['extended', 'SIDEBAR_ITEM_SELECTED_BACKGROUND_COLOR_V2']}
          label="Couleur de fond du menu sélectionné"
        >
          <MyColorPicker defaultValue="#1677ff" />
        </Form.Item>

        <Form.Item
          name={['extended', 'SIDEBAR_ITEM_SELECTED_TEXT_COLOR_V2']}
          label="Couleur du texte du menu sélectionné"
        >
          <MyColorPicker defaultValue="#fff" />
        </Form.Item>

        <Form.Item
          name={['extended', 'SIDEBAR_LOGO']}
          label="Logo"
        >
          <MyUploadImage />
        </Form.Item>

        {event && (
          <>
            <div style={{ textAlign: 'center', fontWeight: 600, fontSize: 25, marginBottom: 8 }}>
              {`Configuration BO de l'événement ${event.name}`}
            </div>
            <Form.Item
              name={['extended', `EVENT_${event.id}_COLOR`]}
              label={'Couleur de l\'événement'}
            >
              <MyColorPicker defaultValue="#001529" />
            </Form.Item>
            <Form.Item label="Multi-language" name={['extended', `EVENT_${event.id}_IS_MULTI_LANGUAGE`]} initialValue={0}>
              <Radio.Group
                options={[
                  { label: 'Oui', value: 1 },
                  { label: 'Non', value: 0 },
                ]}
                optionType="button"
                buttonStyle="solid"
                block
              />
            </Form.Item>
            <Form.Item label="Articles gratuits" name={['extended', `EVENT_${event.id}_HAS_FREE_ARTICLES`]} initialValue={0}>
              <Radio.Group
                options={[
                  { label: 'Oui', value: 1 },
                  { label: 'Non', value: 0 },
                ]}
                optionType="button"
                buttonStyle="solid"
                block
              />
            </Form.Item>
            <Form.Item label="Factures sans TVA" name={['extended', `EVENT_${event.id}_WITHOUT_TVA`]} initialValue={0}>
              <Radio.Group
                options={[
                  { label: 'Oui', value: 1 },
                  { label: 'Non', value: 0 },
                ]}
                optionType="button"
                buttonStyle="solid"
                block
              />
            </Form.Item>
            <Form.Item label="Facturation Gayakoa" name={['extended', `EVENT_${event.id}_FACTURATION_GAYAKOA`]} initialValue={0}>
              <Radio.Group
                options={[
                  { label: 'Oui', value: 1 },
                  { label: 'Non', value: 0 },
                ]}
                optionType="button"
                buttonStyle="solid"
                block
              />
            </Form.Item>
            <Form.Item label="Facturation Autres" name={['extended', `EVENT_${event.id}_FACTURATION_OTHERS`]} initialValue={0}>
              <Radio.Group
                options={[
                  { label: 'Oui', value: 1 },
                  { label: 'Non', value: 0 },
                ]}
                optionType="button"
                buttonStyle="solid"
                block
              />
            </Form.Item>
            <Form.Item label="Accompagnants" name={['extended', `EVENT_${event.id}_COMPANIONS`]} initialValue={0}>
              <Radio.Group
                options={[
                  { label: 'Oui', value: 1 },
                  { label: 'Non', value: 0 },
                ]}
                optionType="button"
                buttonStyle="solid"
                block
              />
            </Form.Item>
          </>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Enregistrer
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
