/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { Alert, Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { read, utils } from 'xlsx';
import { useSearchParams } from 'react-router-dom';
import { useImportUsersMutation } from '../../../api/v2/import';
import CreateTemplateUsers from './CreateTemplate';
import { useMyContext } from '../../../hooks/useMyContext';

const transformedObject = (inputObject) => Object.entries(inputObject)
  .reduce((prev, [key, value]) => {
    if (!key.includes('(') || !key.includes(')') || !key.includes('.')) {
      return prev;
    }

    const [model, property, extended, values] = key
      .split('(')[1]
      .split(')')[0]
      .split('.');

    if (extended === 'values') {
      return {
        ...prev,
        [model]: {
          ...(prev[model] || {}),
          [property]: {
            ...(prev[model]?.[property] || {}),
            [values]: value.split(',').reduce((p, curr) => ({ ...p, [curr]: true }), {}),
          },
        },
      };
    }

    if (extended) {
      return {
        ...prev,
        [model]: {
          ...(prev[model] || {}),
          [property]: {
            ...(prev[model]?.[property] || {}),
            [extended]: value,
          },
        },
      };
    }

    if (property) {
      return {
        ...prev,
        [model]: {
          ...(prev[model] || {}),
          [property]: value,
        },
      };
    }

    return prev;
  }, {});

export default function ImportUsersV2() {
  const [data, setData] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const { myContext } = useMyContext();
  const ref = useRef();

  const [importUsers, { isLoading }] = useImportUsersMutation();

  const handleFileUpload = (event) => {
    setIsSuccess(false);
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = (e) => {
      const _data = new Uint8Array(e.target.result);
      const workbook = read(_data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const excelData = utils.sheet_to_json(workbook.Sheets[sheetName], { defval: '' });
      setData(excelData.map((m) => transformedObject(m)));
    };

    reader.readAsArrayBuffer(file);
  };

  const reset = () => {
    setData([]);
  };

  return (
    <div style={{ display: 'flex', gap: 16, flexDirection: 'column' }}>
      <CreateTemplateUsers />

      {data.length === 0 && (
        <div>
          <input
            ref={ref}
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            value={undefined}
            hidden
          />
          <Button type="primary" onClick={() => ref.current?.click()}>
            {'Choisir un fichier excel d\'utilisateurs'}
          </Button>
        </div>
      )}

      {data.length > 0 && (
        <Alert
          description={`Voulez-vous importer ${data.length} users ?`}
          type="info"
          action={(
            <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
              <Button
                type="primary"
                onClick={async () => {
                  const c = [];
                  // for (let i = 0; i < 100; i++) {
                  //   c.push({
                  //     user: {
                  //       firstName: `firstName ${i}`,
                  //       lastName: `lastName ${i}`
                  //     }
                  //   })
                  // }

                  try {
                    await importUsers({ ...myContext, data }).unwrap();
                    setIsSuccess(true);
                    reset();
                  } catch (err) {
                    console.log(err);
                  }
                }}
                loading={isLoading}
              >
                Oui
              </Button>
              <Button onClick={() => reset()} loading={isLoading} danger ghost>
                Non
              </Button>
            </div>
          )}
        />
      )}

      {isSuccess && <Alert showIcon message="Import terminé" type="success" />}

      {data && window.location.hostname === 'localhost' && (
        <div>
          <h2>Contenu du fichier Excel</h2>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}
