import queryString from 'query-string';
import { saveAs } from 'file-saver';
import apiV2 from '.';

export const invoicesApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    downloadInvoice: builder.mutation({
      query: ({ domainId, eventId, userId, invoiceId, language, label }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/invoices/${invoiceId}?${queryString.stringify({ language })}`,
        method: 'POST',
        responseHandler: async (response) => {
          const blob = await response.blob();
          saveAs(blob, `${language === 'FR' ? 'Facture' : 'Invoice'}-${label}.pdf`);
        },
      }),
    }),
    showInvoice: builder.mutation({
      query: ({ domainId, eventId, userId, invoiceId, language, gayakoa }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/invoices/${invoiceId}?${queryString.stringify({ language, gayakoa })}`,
        method: 'POST',
        responseHandler: async (response) => {
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        },
      }),
    }),
    createInvoice: builder.mutation({
      query: ({ domainId, eventId, userId, invoice }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/invoices`,
        method: 'POST',
        body: { invoice },
      }),
      invalidatesTags: ['INVOICES'],
    }),
    acceptInvoice: builder.mutation({
      query: ({ domainId, eventId, userId, invoiceId }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/invoices/${invoiceId}/accept`,
        method: 'PUT',
      }),
      invalidatesTags: ['INVOICES'],
    }),
    removeInvoice: builder.mutation({
      query: ({ domainId, eventId, userId, invoiceId }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/invoices/${invoiceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['INVOICES'],
    }),
    getInvoices: builder.query({
      query: ({ domainId, eventId, userId, isImport, current, pageSize, isCredit }) => ({
        url: userId
          ? `/domains/${domainId}/events/${eventId}/users/${userId}/invoices`
          : `/domains/${domainId}/events/${eventId}/invoices?${queryString.stringify({ isImport, current, pageSize, isCredit })}`,
        method: 'GET',
      }),
      transformResponse: (response, _, { userId }) => (userId ? response.data.invoices : response.data),
      providesTags: ['INVOICES'],
    }),
    downloadInvoices: builder.query({
      query: ({ domainId, eventId, isImport, isCredit, isGayakoa }) => ({
        url: `/domains/${domainId}/events/${eventId}/invoices/download`,
        method: 'POST',
        body: { isImport, isCredit, isGayakoa },
      }),
      transformResponse: (response) => response.data.files,
      providesTags: ['INVOICES'],
    }),
  }),
});

export const {
  useDownloadInvoiceMutation,
  useGetInvoicesQuery,
  useShowInvoiceMutation,
  useCreateInvoiceMutation,
  useAcceptInvoiceMutation,
  useRemoveInvoiceMutation,
  useDownloadInvoicesQuery,
  useLazyDownloadInvoicesQuery,
} = invoicesApi;
