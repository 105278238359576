import { UserOutlined } from '@ant-design/icons';
import { Avatar, Spin } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserQuery } from '../../../api/v2/users';
import { useMyContext } from '../../../hooks/useMyContext';
import UserDetailsRole from './Role';
import UserToken from './Token';
import UserLogs from './Logs';

function Language({ language }) {
  if (language === 'FR') {
    return '🇫🇷';
  }
  if (language === 'EN') {
    return '🇺🇸';
  }
  return '';
}

function Left() {
  const { myContext } = useMyContext();
  const { userId } = useParams();

  const { data: user, isFetching } = useGetUserQuery({ ...myContext, userId });

  return (
    <div style={{ display: 'flex', gap: 8 }}>
      <Avatar shape="square" size={80} icon={<UserOutlined />} src={user.fileUrl} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <div style={{ fontSize: 18, fontWeight: '600' }}>
          {`${user.firstName || ''} ${user.lastName || ''}`}
          {' '}
          <Language language={user.language} />
        </div>
        {user.email && <div>{`${user.email || ''}`}</div>}
        {(user.mobile || user.phone) && (
          <div>
            {`${user.mobile || ''} ${(user.mobile && user.phone) ? ' / ' : ''} ${user.phone || ''}`}
          </div>
        )}
        <UserDetailsRole />
      </div>
    </div>
  );
}

function Center() {
  return (
    <div>
      <UserToken />
    </div>
  );
}

function Right() {
  return (
    <div>
      <UserLogs />
    </div>
  );
}

export default function UserDetails() {
  const { myContext } = useMyContext();
  const { userId } = useParams();

  const { data: user, isFetching } = useGetUserQuery({ ...myContext, userId });

  return (
    <>
      <Spin spinning={isFetching} fullscreen />
      {user && (
        <div style={{ display: 'flex', gap: 16, justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: 16 }}>
            <Left />
            <Center />
          </div>
          <Right />
        </div>
      )}
    </>
  );
}
