import queryString from 'query-string';
import apiV2 from '.';

export const conferencesApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    getConferences: builder.query({
      query: ({ domainId, eventId, userId }) => `/domains/${domainId}/events/${eventId}/conferences?${queryString.stringify({ userId })}`,
      transformResponse: (response) => response.data,
      providesTags: ['CONFERENCES'],
    }),
    updateConferenceSlotUser: builder.mutation({
      query: ({ domainId, eventId, userId, slot }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/conferences`,
        method: 'PUT',
        body: { slot },
      }),
      invalidatesTags: ['CONFERENCES'],
    }),
  }),
});

export const {
  useGetConferencesQuery,
  useUpdateConferenceSlotUserMutation,
} = conferencesApi;
