import apiV2 from '.';

export const domainsApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    domainsMe: builder.query({
      query: () => '/domains/me',
      transformResponse: (response) => response.data.domains,
      providesTags: ['ROLES', 'DOMAINS'],
    }),
    domainsUpdate: builder.mutation({
      query: ({ domainId, domain }) => ({
        url: `/domains/${domainId}`,
        method: 'PUT',
        body: domain,
      }),
      invalidatesTags: ['DOMAINS'],
    }),
  }),
});

export const { useDomainsMeQuery, useDomainsUpdateMutation } = domainsApi;
