import { Button } from 'antd';
import React from 'react';
import { useBulkParticipationMutation } from '../../../api/v2/participations';
import { useMyContext } from '../../../hooks/useMyContext';
import useSelectEvent from '../../../hooks/useSelectEvent';
import { useUsers } from '../useUsers';

export default function InviteUsers({ close }) {
  const { myContext } = useMyContext();
  const [SelectEvent, { event }] = useSelectEvent();
  const { usersHeaders, usersFilters } = useUsers();

  const [bulkParticipation, { isLoading }] = useBulkParticipationMutation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <SelectEvent />
      <Button
        loading={isLoading}
        onClick={async () => {
          await bulkParticipation({
            ...myContext,
            toEventId: event?.id,
            filters: usersFilters,
            headers: usersHeaders,
          }).unwrap();
          close();
        }}
        type="primary"
        disabled={!event}
      >
        Inviter ces utilisateurs à cet événement
      </Button>
    </div>
  );
}
