import queryString from 'query-string';
import apiV2 from '.';

export const formsApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    getParents: builder.query({
      query: ({ domainId, eventId }) => `/domains/${domainId}/forms/parents?${queryString.stringify({ eventId })}`,
      transformResponse: (response) => response.data?.parents || [],
    }),
    getParentChilds: builder.query({
      query: ({ domainId, eventId, parentKey }) => `/domains/${domainId}/forms/parents/${parentKey}?${queryString.stringify({ eventId })}`,
      transformResponse: (response) => response.data?.childs || [],
    }),
  }),
});

export const {
  useGetParentsQuery, useGetParentChildsQuery, useLazyGetParentChildsQuery,
} = formsApi;
