import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import frFR from 'antd/lib/locale/fr_FR';
import { setDefaultOptions } from 'date-fns';
import { fr } from 'date-fns/locale';
import AppRouter from './AppRouter';
import { AuthProvider } from './hooks/useAuth';
import store from './redux/store';
import 'antd/dist/reset.css';
import './index.css';

setDefaultOptions({ locale: fr });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider locale={frFR}>
      <Provider store={store}>
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </Provider>
    </ConfigProvider>
  </React.StrictMode>,
);
