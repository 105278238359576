import apiV2 from '.';

export const bulkApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    bulkModerations: builder.mutation({
      query: ({ domainId, eventId, filters, headers }) => ({
        url: eventId ? `/domains/${domainId}/events/${eventId}/bulk/moderations` : `/domains/${domainId}/bulk/moderations`,
        method: 'POST',
        body: { filters, headers },
      }),
      invalidatesTags: ['USERS'],
    }),
  }),
});

export const { useBulkModerationsMutation } = bulkApi;
