import apiV2 from '.';

export const articlesApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    createArticle: builder.mutation({
      query: ({ domainId, eventId, article }) => ({
        url: `/domains/${domainId}/events/${eventId}/articles`,
        method: 'POST',
        body: { article },
      }),
      invalidatesTags: ['ARTICLES'],
    }),
    importArticles: builder.mutation({
      query: ({ domainId, eventId, articles }) => ({
        url: `/domains/${domainId}/events/${eventId}/articles/import`,
        method: 'POST',
        body: { articles },
      }),
      invalidatesTags: ['ARTICLES'],
    }),
    updateArticle: builder.mutation({
      query: ({ domainId, eventId, articleId, article }) => ({
        url: `/domains/${domainId}/events/${eventId}/articles/${articleId}`,
        method: 'PUT',
        body: { article },
      }),
      invalidatesTags: ['ARTICLES'],
    }),
    removeArticle: builder.mutation({
      query: ({ domainId, eventId, articleId }) => ({
        url: `/domains/${domainId}/events/${eventId}/articles/${articleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ARTICLES'],
    }),
    getArticles: builder.query({
      query: ({ domainId, eventId }) => `/domains/${domainId}/events/${eventId}/articles`,
      transformResponse: (response) => response.data.articles,
      providesTags: ['ARTICLES'],
    }),
  }),
});

export const {
  useCreateArticleMutation, useImportArticlesMutation, useUpdateArticleMutation, useRemoveArticleMutation, useGetArticlesQuery,
} = articlesApi;
