/* eslint-disable react/jsx-props-no-spreading */
import { Select } from 'antd';
import React, { useState } from 'react';

const useSelectIsExhibitor = () => {
  const [isExhibitor, setIsExhibitor] = useState();

  function SelectIsExhibitor(props) {
    return (
      <Select
        options={[{ label: 'Visiteur', value: false }, { label: 'Exposant', value: true }]}
        value={isExhibitor}
        onChange={setIsExhibitor}
        allowClear
        {...props}
      />
    );
  }

  return [SelectIsExhibitor, { isExhibitor, setIsExhibitor }];
};

export default useSelectIsExhibitor;
