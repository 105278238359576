import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  Table,
} from 'antd';
import React from 'react';
import { useGetArticleCodesQuery, useRemoveArticleCodeMutation } from '../../../api/v2/articleCodes';
import { useMyContext } from '../../../hooks/useMyContext';
import ArticleCodeEdition from './Edition';

export default function ArticleCodesPage() {
  const { myContext } = useMyContext();

  const [remove] = useRemoveArticleCodeMutation();
  const { data: articleCodes } = useGetArticleCodesQuery({ ...myContext });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <ArticleCodeEdition />
      <Table
        dataSource={articleCodes}
        scroll={{ x: true }}
        columns={[
          { title: 'Code', dataIndex: 'code' },
          { title: 'Article', dataIndex: 'label' },
          { title: 'Remise', dataIndex: 'remise', render: (v) => `${v} %` },
          { title: 'Utilisés', render: (v) => `${v.total}/${v.totalAvailable}` },
          {
            render: (v) => (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                <ArticleCodeEdition values={v} />
                <Button
                  onClick={() => {
                    Modal.confirm({
                      title: 'Voulez-vous vraiment supprimer ce code ?',
                      icon: <ExclamationCircleOutlined />,
                      okText: 'Oui',
                      cancelText: 'Non',
                      async onOk() {
                        return remove({ ...myContext, id: v.id });
                      },
                    });
                  }}
                  disabled={!v.id}
                  danger
                >
                  Supprimer
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
