const host = window.location.hostname;

export default function useEnv() {
  if (host.includes('dev')) {
    return 'dev';
  } if (host === 'localhost') {
    return 'local';
  }
  return 'prod';
}
