import { Input, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useUsers } from '../useUsers';
import FilterEmpty from './Empty';

const filterById = (id, array) => array.reduce((prev, curr) => {
  if (Array.isArray(curr)) {
    return [...prev, filterById(id, curr)];
  }
  if (curr.id !== id) {
    return [...prev, curr];
  }
  return prev;
}, []);

export default function FilterString({ m, setFilters }) {
  const [inputValue, setInputValue] = useState('');

  const { currentSettings } = useUsers();

  const inputId = `string-input-${m.model}.${m.property}${m.extended ? `.${m.extended}` : ''}`;

  useEffect(() => {
    if (currentSettings) {
      const findInput = currentSettings.usersFilters.find((f) => f.id === inputId);
      setInputValue(findInput?.value || '');
    }
  }, [currentSettings]);

  return (
    <>
      <Input
        size="small"
        onChange={(v) => setFilters((prev) => {
          const { value } = v.target;
          setInputValue(value);

          if (!value) {
            return [...filterById(inputId, prev)];
          }

          const filter = {
            id: inputId,
            operator: 'LIKE',
            value,
            property: `${m.model}.${m.property}`,
            extended: m.extended ? `$."${m.extended}"` : null,
          };

          return [...filterById(inputId, prev), filter];
        })}
        value={inputValue}
        allowClear
      />
      <FilterEmpty m={m} setFilters={setFilters} />
    </>
  );
}
