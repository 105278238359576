import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useUsers } from '../useUsers';

const filterById = (id, array) => array.reduce((prev, curr) => {
  if (Array.isArray(curr)) {
    return [...prev, filterById(id, curr)];
  }
  if (curr.id !== id) {
    return [...prev, curr];
  }
  return prev;
}, []);

export default function FilterBoolean({ m, setFilters }) {
  const [selectValue, setSelectValue] = useState(null);

  const { currentSettings } = useUsers();

  const id = `select-boolean-${m.model}.${m.property}`;

  useEffect(() => {
    if (currentSettings) {
      const findSelect = currentSettings.usersFilters.flat().find((f) => f.id === id);
      setSelectValue(findSelect?.v || null);
    }
  }, [currentSettings]);

  return (
    <>
      <Select
        value={selectValue}
        allowClear
        popupMatchSelectWidth={false}
        size="small"
        options={[
          { label: 'Oui', value: 'TRUE' },
          { label: 'Non', value: 'FALSE' },
        ]}
        onChange={(value) => setFilters((prev) => {
          setSelectValue(value);
          let filters = [];

          if (value === 'TRUE') {
            filters = [{
              id,
              operator: 'IS',
              value,
              v: value,
              property: `${m.model}.${m.property}`,
            }];
          }

          if (value === 'FALSE') {
            filters = [{
              id,
              operator: 'IS',
              value,
              v: value,
              property: `${m.model}.${m.property}`,
            }, {
              id,
              operator: 'IS',
              value: 'NULL',
              v: value,
              property: `${m.model}.${m.property}`,
              condition: 'OR',
            }];
          }

          return [...filterById(id, prev), filters];
        })}
      />
      <div style={{ height: 24 }} />
    </>
  );
}
