import React from 'react';
import { Outlet } from 'react-router-dom';
import { useLayout } from '../../hooks/useLayout';
import FlexGrow from '../FlexGrow';

export default function Main() {
  const {
    isMobile, navbarHeight, sidebarWidth, padding,
  } = useLayout();

  return (
    <FlexGrow
      style={{
        transition: 'margin-left 0.3s',
        marginLeft: isMobile ? 0 : sidebarWidth,
        marginTop: navbarHeight,
        padding,
      }}
    >
      <Outlet />
    </FlexGrow>
  );
}
