import { Button, Table } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetEmployeesQuery } from '../../../api/v2/employees';
import { useMyContext } from '../../../hooks/useMyContext';
import useNav from '../../../hooks/useNav';

export default function UserEmployees() {
  const { myContext } = useMyContext();
  const { userId } = useParams();
  const nav = useNav();

  const { data } = useGetEmployeesQuery({ ...myContext, userId });

  return (
    <div>
      <Table
        pagination={false}
        dataSource={data}
        scroll={{ x: true }}
        columns={[
          { title: 'Nom', dataIndex: 'name' },
          { title: 'Ville', dataIndex: 'city' },
          { title: 'Code postal', dataIndex: 'postalCode' },
          { title: 'Adresse', dataIndex: 'address1' },
          {
            render: (c) => (
              <div style={{ display: 'flex', gap: 8 }}>
                <Button type="primary" onClick={() => nav(`/companies/${c.id}`, { newTab: true })}>Voir</Button>
                <Button type="primary" danger>Retirer</Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
