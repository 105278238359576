import { Button, Form, InputNumber, Modal, Select, Table } from 'antd';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetArticlesQuery } from '../../../../api/v2/articles';
import {
  useCreateFreeArticlesMutation,
  useGetFreeArticlesQuery,
  useRemoveFreeArticlesMutation,
  useUpdateFreeArticlesMutation,
} from '../../../../api/v2/freeArticles';
import BooleanIcon from '../../../../components/BooleanIcon';
import ButtonConfirm from '../../../../components/ButtonConfirm';
import { useMyContext } from '../../../../hooks/useMyContext';

export default function UserFreeArticles({ values }) {
  const [open, setOpen] = useState(false);

  const { userId } = useParams();
  const { myContext } = useMyContext();
  const [form] = Form.useForm();

  const { data: freeArticles, isFetching } = useGetFreeArticlesQuery({ ...myContext, userId });
  const { data: articles, isFetching: isFetchingArticles } = useGetArticlesQuery({ ...myContext });
  const [create, { isLoading: isCreating }] = useCreateFreeArticlesMutation();
  const [update, { isLoading: isUpdating }] = useUpdateFreeArticlesMutation();
  const [remove] = useRemoveFreeArticlesMutation();

  const isEdition = useMemo(() => !!values?.id, [values]);

  const close = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Button type="primary" onClick={() => setOpen(true)}>Ajouter des articles gratuits</Button>
      <Modal title="Ajouter des articles gratuits" open={open} footer={null} onCancel={close}>
        <Form
          form={form}
          onFinish={(body) => {
            if (isEdition) {
              update({ ...myContext, userId, id: values.id, body });
            } else {
              create({ ...myContext, userId, body });
            }
            close();
          }}
        >
          <Form.Item label="Choisir un article" name="articleId" rules={[{ required: true }]}>
            <Select
              loading={isFetchingArticles}
              options={articles?.map((m) => ({ label: `${m.label} - ${m.priceTTC} €`, value: m.id }))}
              popupMatchSelectWidth={false}
              filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
              showSearch
            />
          </Form.Item>
          <Form.Item label="Quantité" name="quantity" rules={[{ required: true }]}>
            <InputNumber min={1} />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => close()} loading={isCreating || isUpdating}>Annuler</Button>
            <Button type="primary" htmlType="submit" loading={isCreating || isUpdating}>Enregistrer</Button>
          </div>
        </Form>
      </Modal>

      <Table
        scroll={{ x: true }}
        loading={isFetching}
        dataSource={freeArticles}
        columns={[
          {
            title: "Nom de l'article",
            dataIndex: 'label',
          },
          {
            title: 'Quantité',
            dataIndex: 'quantity',
          },
          {
            title: 'Utilisé',
            dataIndex: 'isUsed',
            render: (v) => <BooleanIcon value={v} />,
          },
          {
            render: (v) => (
              <div style={{ display: 'flex', gap: 8 }}>
                <Button type="primary">Modifier</Button>
                <ButtonConfirm
                  title="Voulez-vous vraiment supprimer ces articles gratuits ?"
                  onOk={() => remove({ ...myContext, userId, id: v.id })}
                >
                  <Button type="primary" danger>Supprimer</Button>
                </ButtonConfirm>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
