import { Button } from 'antd';
import React from 'react';
import { useCreateUsersExportQuery } from '../../../api/v2/users';
import { useMyContext } from '../../../hooks/useMyContext';
import { useUsers } from '../useUsers';

export default function ExportUsers({ close, total }) {
  const { myContext } = useMyContext();
  const { usersHeaders, usersFilters } = useUsers();

  const { data, isFetching } = useCreateUsersExportQuery({ ...myContext, filters: usersFilters, headers: usersHeaders });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <Button
        loading={isFetching}
        href={data}
        onClick={() => close()}
        type="primary"
      >
        {isFetching ? 'Préparation du fichier ...' : 'Télécharger le fichier'}
      </Button>
    </div>
  );
}
