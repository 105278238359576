import React from 'react';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import UserInvoicesTable from './Table';
import UserInvoicesCreate from './Create';
import UserInvoicesCreateCredit from './CreateCredit';
import { useSendEmailMutation } from '../../../../api/v2/emails';
import { useMyContext } from '../../../../hooks/useMyContext';
import InvoicesSendEmail from './SendEmail';

export default function UserInvoices({ isCredit, onTabs }) {
  const { myContext, settings } = useMyContext();
  const { userId } = useParams();

  const [send, { isLoading }] = useSendEmailMutation();

  return (
    <div>
      <div style={{ display: 'flex', gap: 8, justifyContent: 'space-between', marginBottom: 8 }}>
        {isCredit ? <UserInvoicesCreateCredit label="Créer un avoir" /> : <UserInvoicesCreate />}
        <InvoicesSendEmail />
      </div>
      <UserInvoicesTable isCredit={isCredit} onTabs={onTabs} />
    </div>
  );
}
