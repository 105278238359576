import React from 'react';
import UserInvoicesGayakoaTable from './Table';

export default function UserInvoicesGayakoa() {
  return (
    <div>
      <UserInvoicesGayakoaTable />
    </div>
  );
}
