import queryString from 'query-string';
import apiV2 from '.';

export const settingsApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    createSettings: builder.mutation({
      query: ({ domainId, settings }) => ({
        url: `/domains/${domainId}/settings`,
        method: 'POST',
        body: { settings },
      }),
      transformResponse: (response) => response.data.settings,
      invalidatesTags: ['SETTINGS'],
    }),
    removeSettings: builder.mutation({
      query: ({ domainId, settingsId }) => ({
        url: `/domains/${domainId}/settings/${settingsId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SETTINGS'],
    }),
    updateSettings: builder.mutation({
      query: ({ domainId, settings, settingsId }) => ({
        url: `/domains/${domainId}/settings/${settingsId}`,
        method: 'PUT',
        body: { settings },
      }),
      transformResponse: (response) => response.data.settings,
      invalidatesTags: ['SETTINGS'],
    }),
    getSettings: builder.query({
      query: ({ domainId, eventId }) => ({
        url: `/domains/${domainId}/settings?${queryString.stringify({ eventId })}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.settings,
      providesTags: ['SETTINGS'],
    }),
  }),
});

export const {
  useCreateSettingsMutation,
  useRemoveSettingsMutation,
  useGetSettingsQuery,
  useUpdateSettingsMutation,
} = settingsApi;
