import { Alert, Button, Form, Input, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useZxing } from 'react-zxing';
import SearchUsers from './SearchUsers';

function Zxing({ code, setCode }) {
  const [error, setError] = useState();

  const { ref } = useZxing({
    timeBetweenDecodingAttempts: 100,
    onError(err) {
      setError(true);
      console.log(err);
    },
    onDecodeResult(result) {
      setError(false);
      let newCode = result.getText();

      if (!newCode) return;

      const hasUrl = newCode.replace('-', '=').includes('=');
      if (hasUrl) {
        [, newCode] = newCode.replace('-', '=').split('=');
      }

      if (newCode !== code) {
        setCode(newCode);
      }
    },
  });

  return (
    <>
      {error && <Alert message="Vous devez activer la caméra" type="error" showIcon />}
      <div>
        <Spin spinning={!!code} tip="Chargement" size="large">
          <video style={{ width: '100%' }} ref={ref} muted />
        </Spin>
      </div>
    </>
  );
}

export default function QReader({ onChange, loading }) {
  const [visible, setVisible] = useState(false);
  const [code, setCode] = useState();
  const [mode, setMode] = useState(localStorage.getItem('QReader_mode') || 'camera');

  const [form] = Form.useForm();
  const inputRef = useRef();

  useEffect(() => {
    if (code) {
      const hasUrl = code.replace('-', '=').includes('=');
      if (hasUrl) {
        const onlyCode = code.replace('-', '=').split('=')[1];
        onChange(onlyCode);
      } else {
        onChange(code);
      }

      setTimeout(() => setCode(null), 3000);
    }
  }, [code]);

  useEffect(() => {
    setTimeout(() => setVisible(true), 1000);
  }, []);

  useEffect(() => {
    let interval;
    if (mode === 'input' && visible && inputRef.current) {
      inputRef.current.focus();
      interval = setInterval(() => inputRef.current.focus(), 500);
    }

    return () => {
      clearInterval(interval);
    };
  }, [inputRef.current, mode, visible]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
        <Button
          type="primary"
          ghost={mode !== 'camera'}
          onClick={() => {
            setMode('camera');
            localStorage.setItem('QReader_mode', 'camera');
          }}
        >
          Caméra
        </Button>
        <Button
          type="primary"
          ghost={mode !== 'input'}
          onClick={() => {
            setMode('input');
            localStorage.setItem('QReader_mode', 'input');
          }}
        >
          Lecteur
        </Button>
        <Button
          type="primary"
          ghost={mode !== 'search'}
          onClick={() => {
            setMode('search');
            localStorage.setItem('QReader_mode', 'search');
          }}
        >
          Recherche
        </Button>
        <Button
          type="primary"
          ghost={mode !== 'lot'}
          onClick={() => {
            setMode('lot');
            localStorage.setItem('QReader_mode', 'lot');
          }}
        >
          Multiple
        </Button>
      </div>

      {(mode === 'camera' || mode === 'input' || mode === 'search') && !visible && <Spin />}

      {mode === 'camera' && visible && <Zxing code={code} setCode={setCode} />}

      {mode === 'input' && visible && (
        <Form
          form={form}
          style={{ display: 'flex', gap: 8 }}
          onFinish={(v) => {
            setCode(v.code);
            form.resetFields();
          }}
        >
          <Form.Item name="code" style={{ flexGrow: 1 }}>
            <Input ref={inputRef} autoFocus />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>Valider</Button>
        </Form>
      )}

      {mode === 'search' && visible && <SearchUsers onSelect={(_, user) => setCode(user.code || 'empty')} />}
    </div>
  );
}
