import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Radio, Tree } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useCreateSettingsMutation, useUpdateSettingsMutation } from '../../../api/v2/settings';
import useMe from '../../../hooks/useMe';
import { useMyContext } from '../../../hooks/useMyContext';
import { useUsers } from '../useUsers';

export default function UserSettingsEdition({ data, values }) {
  const [open, setOpen] = useState(false);

  const [form] = Form.useForm();
  const { event, myContext } = useMyContext();
  const me = useMe();
  const { usersHeaders, setUsersHeaders, usersFilters, setNewId } = useUsers();

  const [update, { isLoading: isUpdating }] = useUpdateSettingsMutation();
  const [create, { isLoading: isCreating }] = useCreateSettingsMutation();

  useEffect(() => {
    if (values) {
      form.setFieldsValue({ ...values });
    }
  }, [form, values]);

  const isEdition = useMemo(() => !!values, [values]);

  const onFinish = async (v) => {
    try {
      if (isEdition) {
        await update({
          ...myContext,
          settingsId: values.id,
          settings: {
            usersHeaders,
            usersFilters,
            label: v.label,
          },
        }).unwrap();
      } else {
        const eventId = v.context === 'event' && event ? event.id : null;
        const userId = v.forMe === 'true' && me ? me.id : null;
        const newSettings = await create({
          ...myContext,
          settings: {
            eventId,
            userId,
            usersHeaders,
            usersFilters,
            label: v.label,
          },
        }).unwrap();
        setNewId(newSettings.id);
      }

      setOpen(false);
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Button type="primary" onClick={() => setOpen(true)} icon={isEdition ? <EditOutlined /> : <PlusOutlined />} />
      <Modal
        title={isEdition ? 'Modifier une configuration' : 'Créer une configuration'}
        footer={null}
        open={open}
        onCancel={() => setOpen(false)}
      >
        <Form form={form} onFinish={onFinish}>
          {!isEdition && event && (
            <Form.Item label="Context" name="context" rules={[{ required: true }]}>
              <Radio.Group
                options={[
                  { label: 'Domaine', value: 'domain' },
                  { label: 'Événementiel', value: 'event' },
                ]}
              />
            </Form.Item>
          )}
          {!isEdition && (
            <Form.Item label="Accès" name="forMe" rules={[{ required: true }]}>
              <Radio.Group
                options={[
                  { label: 'Pour tout le monde', value: 'false' },
                  { label: 'Pour moi', value: 'true' },
                ]}
              />
            </Form.Item>
          )}
          <Form.Item label="Nom" name="label" style={{ marginBottom: 8 }} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Tree
              checkable
              defaultExpandedKeys={['PAGES', 'FEATURES']}
              checkedKeys={usersHeaders}
              onCheck={setUsersHeaders}
              treeData={data?.labelsAll?.map((m) => ({
                title: m.label,
                key: m.value,
                children: m.options.map((mm) => ({ title: mm.label, key: mm.value })),
              }))}
            />
          </Form.Item>

          <div style={{ display: 'flex', gap: 8, justifyContent: 'space-between' }}>
            <Button onClick={() => setOpen(false)}>Annuler</Button>
            <Button type="primary" htmlType="submit" loading={isUpdating || isCreating}>Enregistrer</Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
