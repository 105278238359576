import { format } from 'date-fns';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetLogsQuery } from '../../../api/v2/logs';
import { useMyContext } from '../../../hooks/useMyContext';

export default function UserLogs() {
  const { myContext } = useMyContext();
  const { userId } = useParams();

  const { data } = useGetLogsQuery({ ...myContext, userId });

  return (
    <div className="hide-1000" style={{ display: 'flex', flexDirection: 'column', gap: 4, textAlign: 'right', fontSize: 13 }}>
      {data?.logs.map((log) => <div>{`${log.label} : le ${format(log.createdAt, 'dd/MM à hh:mm')}`}</div>)}
    </div>
  );
}
