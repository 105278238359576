import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Spin } from 'antd';
import { useLoginMutation } from '../api/v1/authentications';
import { useLazyAuthMeQuery } from '../api/v2/authentications';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isAuth, setIsAuth] = useState(!!token);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [domainId, setDomainId] = useState();
  const [eventId, setEventId] = useState();

  const [login, { isLoading: isLoging }] = useLoginMutation();
  const [myAuth, { data: auth }] = useLazyAuthMeQuery();

  const fetchLogin = async (values) => {
    try {
      setError(null);
      const data = await login(values).unwrap();
      setToken(data.token);
      localStorage.setItem('token', data.token);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchMe = useCallback(async () => {
    if (token) {
      setLoading(true);
      const data = await myAuth().unwrap();
      setIsAuth(data.isAuth);
      setDomainId(data.domainId);
      setEventId(data.eventId);
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchMe();
  }, [fetchMe]);

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuth(false);
    window.location.reload();
  };

  const state = useMemo(() => ({
    auth,
    isAuth,
    login: fetchLogin,
    isLoging,
    error,
    defaultDomainId: domainId,
    defaultEventId: eventId,
    logout,
  }), [isAuth, fetchLogin, isLoging, error, domainId, eventId, auth]);

  return (
    <AuthContext.Provider value={state}>
      {children}
      <Spin spinning={loading} fullscreen />
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
