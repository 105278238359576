import { useGetMeQuery } from '../api/v2/users';
import { useMyContext } from './useMyContext';

const useMe = () => {
  const { myContext } = useMyContext();
  const { data: me } = useGetMeQuery(myContext);
  return me || {};
};

export default useMe;
