import React from 'react';
import { useLayout } from '../../hooks/useLayout';
import GMenu from './GMenu';
import useSettingsBo from '../../hooks/useSettingsBO';

export default function SidebarDesktop() {
  const { isMobile, navbarHeight, sidebarWidth } = useLayout();
  const settings = useSettingsBo();

  return (
    <div style={{
      position: 'fixed',
      backgroundColor: settings?.SIDEBAR_BACKGROUND_COLOR_V2 || '#001529',
      bottom: 0,
      left: !isMobile ? 0 : -sidebarWidth,
      top: navbarHeight,
      transition: 'left 0.3s',
      width: sidebarWidth,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      boxShadow: '1px 0 8px rgba(0, 0, 0, 0.6)',
    }}
    >
      <GMenu />
    </div>
  );
}
